import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiConfirmEmail } from 'src/api/auth'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'

const useQuery = () => {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

const ConfirmEmailView = () => {
    const { userState, loadUser } = useUser()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useLanguage()
    const navigate = useNavigate()

    const query = useQuery()
    const email = query.get('email')
    const token = query.get('token')

    useEffect(() => {
        const confirmEmail = async () => {
            let response = await apiConfirmEmail(email, token)
            if (response.status === 200) {
                enqueueSnackbar(t('nftEmailConfirmed'))
                if (userState.isLoggedIn === true) {
                    await loadUser()
                    navigate('/app/tasks/today')
                }
            } else {
                enqueueSnackbar(t('nftWrongEmailOrToken'))
            }
        }
        confirmEmail()
    }, [])

    return null
}

export default ConfirmEmailView
