import { Checkbox, Grid, Menu, MenuItem } from '@material-ui/core'
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined'
import EditIcon from '@material-ui/icons/Edit'
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined'
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined'
import { memo, useState } from 'react'
import TaskDateInfo from 'src/components/TaskDateInfo'
import useStableNavigate from 'src/hooks/useStableNavigate'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import useTasksActions from 'src/stores/useTasksActions'
import TaskPriority from '../TaskCardPriority'
import TaskCardRepeatInfo from '../TaskCardRepeatInfo'
import TaskCardTextInfo from '../TaskCardTextInfo'
import TaskProjectInfo from '../TaskProjectInfo'
import TaskTimer from '../TaskTimer'
import './style.css'

const TaskCard = (props) => {
    const { task, isRunning } = props
    const { openTaskRepeatModal, openTaskTextModal } = useModals()
    const { updateTask, deleteTask } = useTasksActions()
    const { t } = useLanguage()

    const navigate = useStableNavigate()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleChangeStatus = async (id, newStatus) => {
        await updateTask(id, { status: newStatus }, isRunning)
    }

    const handleDelete = async (id) => {
        handleMenuClose()
        if (window.confirm(t('alertConfirmDeleteTask'))) {
            await deleteTask(id)
        }
    }

    const handleChangeProject = async (id, project) => {
        await updateTask(id, { project_id: project._id })
    }

    return (
        <div className='task-card' data-test='task-card'>
            <Grid container justifyContent='space-between' wrap='nowrap'>
                <Grid
                    item
                    container
                    wrap='nowrap'
                    alignContent='center'
                    style={{ flexGrow: 1 }}
                >
                    <Grid item>
                        <Checkbox
                            checked={task.status !== 'active'}
                            color='primary'
                            onChange={() => {
                                handleChangeStatus(
                                    task._id,
                                    task.status === 'active' ? 'done' : 'active'
                                )
                            }}
                            style={{
                                marginLeft: '-11px',

                                verticalAlign: 'top',
                            }}
                            data-test='task-checkbox'
                        />
                    </Grid>
                    <Grid
                        item
                        style={{
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingRight: '9px',
                        }}
                    >
                        <TaskPriority task={task} updateTask={updateTask} />
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                        <TaskCardTextInfo
                            task={task}
                            handleSave={(text) =>
                                updateTask(task._id, { text })
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <EditIcon
                        color='disabled'
                        fontSize='small'
                        style={{
                            marginTop: '12px',
                            marginRight: '-4px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            openTaskTextModal(task)
                        }}
                        data-test='edit-text-btn'
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={0}
                justifyContent='space-between'
                wrap='nowrap'
                alignItems='center'
            >
                <Grid item container>
                    <Grid item style={{ marginRight: '12px' }}>
                        <TaskTimer task={task} isRunning={isRunning} />
                    </Grid>

                    <Grid item style={{ marginTop: 4, marginRight: '12px' }}>
                        <TaskDateInfo task={task} />
                    </Grid>
                    <Grid item style={{ marginTop: 4 }}>
                        <TaskCardRepeatInfo task={task} />
                    </Grid>
                </Grid>
                <Grid item>
                    {[null, ''].includes(task.notes) ? (
                        <ModeCommentOutlinedIcon
                            color='disabled'
                            data-test='task-page-btn'
                            fontSize='small'
                            style={{
                                marginTop: '8px',
                                marginRight: '-4px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                navigate('/app/tasks/' + task._id + '/notes')
                            }}
                        />
                    ) : (
                        <CommentOutlinedIcon
                            color='disabled'
                            fontSize='small'
                            data-test='task-page-btn'
                            style={{
                                marginTop: '8px',
                                marginRight: '-4px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                navigate('/app/tasks/' + task._id + '/notes')
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent='space-between'
                style={{ paddingTop: '6px' }}
                alignItems='flex-end'
                wrap='nowrap'
            >
                <Grid item>
                    <TaskProjectInfo
                        task={task}
                        handleChangeProject={handleChangeProject}
                    />
                </Grid>
                <Grid item>
                    <MoreVertOutlinedIcon
                        color='disabled'
                        fontSize='small'
                        style={{
                            marginBottom: '-2px',
                            marginRight: '-6px',
                            cursor: 'pointer',
                        }}
                        onClick={handleMenuClick}
                        data-test='task-menu-trigger'
                    />
                    {Boolean(anchorEl) ? (
                        <Menu
                            id='simple-menu'
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            variant='menu'
                            autoFocus={false}
                            data-test='task-menu'
                        >
                            <MenuItem
                                onClick={() => {
                                    handleMenuClose()
                                    openTaskRepeatModal(task)
                                }}
                                dense
                                data-test='repeat-settings'
                            >
                                {t('repeat')}
                            </MenuItem>
                            <MenuItem
                                color='secondary'
                                dense
                                onClick={() => handleDelete(task._id)}
                                style={{
                                    color: '#f50057',
                                }}
                                data-test='task-menu-delete'
                            >
                                {t('delete')}
                            </MenuItem>
                        </Menu>
                    ) : null}
                </Grid>
            </Grid>
        </div>
    )
}

// Older version of check on changes
// const isTaskUnchanged = (a, b) => {
//     console.log({ a, b })
//     if (JSON.stringify(a) === JSON.stringify(b)) return true
//     return false
// }

// Relies on backend updated_at change
const isEqual = (a, b) => a.task.updated_at === b.task.updated_at

export default memo(TaskCard, isEqual)
