import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core'
import { format } from 'date-fns'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { apiUpdateUser } from 'src/api/users'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'

const UserSettingsView = () => {
    const [serverError, setServerError] = useState(null)
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useLanguage()

    const { userState, updateUser } = useUser()
    // const { globalState, globalDispatch } = useContext(GlobalContext)

    const changePassword = async (data) => {
        console.log('Sending data to server...')
        const response = await apiUpdateUser(data)
        if (response.status === 200) {
            setServerError(null)
            enqueueSnackbar(t('ntfPasswordChanged'))
            return true
        }
        if (response.status === 401) {
            setServerError(t('errIncorrectCurrentPassword'))
        }
        if (response.status === 422) {
            setServerError(t('errNewPasswordRequirementsViolation'))
        }
        return false
    }

    const handleChangeLanguage = async (e) => {
        const language = e.target.value
        const response = await apiUpdateUser({ language })
        if (response.status === 200) {
            updateUser({ language: response.data.data.language })
            enqueueSnackbar(t('nftLanguageChanged', {}, language))
        }
    }

    const handleFirstWeekDayChange = async (e) => {
        const isMonday = e.target.value
        const response = await apiUpdateUser({
            start_week_with_monday: isMonday,
        })
        if (response.status === 200) {
            updateUser({
                isMondayFirstDayOfWeek:
                    response.data.data.start_week_with_monday,
            })
            enqueueSnackbar(t('nftSettingsSaved'))
        }
    }

    const handleDateFormatChange = async (e) => {
        const shortDateFormat = e.target.value
        const response = await apiUpdateUser({
            short_date_format: shortDateFormat,
        })
        if (response.status === 200) {
            updateUser({
                shortDateFormat: response.data.data.short_date_format,
            })
            enqueueSnackbar(t('nftSettingsSaved'))
        }
    }

    return (
        <Grid container spacing={2} xs={12} sm={10} md={8} lg={6} xl={4}>
            <Grid item xs={12}>
                <Typography variant='h4'>{t('settings')}</Typography>
            </Grid>
            {/* <Grid item xs={12}>
                <Typography variant='h5'>{t('interfaceLanguage')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <RadioGroup
                        name='language'
                        value={userState.language}
                        onChange={handleChangeLanguage}
                    >
                        <FormControlLabel
                            value='en'
                            control={<Radio color='primary' />}
                            label={t('english')}
                            data-test='settings-english'
                        />
                        <FormControlLabel
                            value='ru'
                            control={<Radio color='primary' />}
                            label={t('russian')}
                            data-test='settings-russian'
                        />
                    </RadioGroup>
                </FormControl>
            </Grid> */}
            <Grid item xs={12}>
                <Typography variant='h5'>{t('dateSettings')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>{t('dateFormat')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <RadioGroup
                        name='shortDateFormat'
                        value={userState.shortDateFormat}
                        onChange={handleDateFormatChange}
                    >
                        <FormControlLabel
                            value='yyyy-MM-dd'
                            control={<Radio color='primary' />}
                            label={t('yyyy-MM-dd_today', {
                                today: format(new Date(), 'yyyy-MM-dd'),
                            })}
                        />
                        <FormControlLabel
                            value='dd.MM.yyyy'
                            control={<Radio color='primary' />}
                            label={t('dd.MM.yyyy_today', {
                                today: format(new Date(), 'dd.MM.yyyy'),
                            })}
                        />
                        <FormControlLabel
                            value='MM/dd/yyyy'
                            control={<Radio color='primary' />}
                            label={t('MM/dd/yyyy_today', {
                                today: format(new Date(), 'MM/dd/yyyy'),
                            })}
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>{t('firstDayOfWeek')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <RadioGroup
                        name='startWeekWithMonday'
                        value={userState.isMondayFirstDayOfWeek}
                        onChange={handleFirstWeekDayChange}
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio color='primary' />}
                            label={t('monday')}
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio color='primary' />}
                            label={t('sunday')}
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5'>{t('changePassword')}</Typography>
            </Grid>
            <Formik
                initialValues={{
                    password: '',
                    newPassword: '',
                    newPasswordConf: '',
                }}
                validateOnBlur
                validate={(values) => {
                    const errors = {}
                    if (!values.password) {
                        errors.password = t('errPasswordCannotBeEmpty')
                    }
                    if (values.newPassword !== values.newPasswordConf) {
                        errors.newPasswordConf = t('errPasswordsDoNotMatch')
                    }
                    if (values.newPassword.length < 8) {
                        errors.newPassword = t('errNewPasswordTooShort')
                    }
                    return errors
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const isSuccessful = await changePassword({
                        current_password: values.password,
                        new_password: values.newPassword,
                        new_password_confirm: values.newPasswordConf,
                    })
                    setSubmitting(false)
                    if (isSuccessful) resetForm()
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                }) => (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                label={t('newPassword')}
                                variant='outlined'
                                name='newPassword'
                                type='password'
                                fullWidth
                                error={
                                    touched.newPassword && errors.newPassword
                                }
                                onChange={handleChange}
                                value={values.newPassword}
                                onBlur={handleBlur}
                                helperText={t('passwordMinLength')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('newPasswordRepeat')}
                                name='newPasswordConf'
                                variant='outlined'
                                type='password'
                                error={
                                    touched.newPasswordConf &&
                                    errors.newPasswordConf
                                }
                                onChange={handleChange}
                                value={values.newPasswordConf}
                                onBlur={handleBlur}
                                helperText={errors.newPasswordConf}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('currentPassword')}
                                error={touched.password && errors.password}
                                variant='outlined'
                                name='password'
                                type='password'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                helperText={errors.password}
                            />
                        </Grid>
                        {serverError ? (
                            <Grid item xs={12}>
                                <Typography color='secondary'>
                                    {serverError}
                                </Typography>
                            </Grid>
                        ) : null}

                        <Grid item xs={12}>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            >
                                {t('save')}
                            </Button>
                        </Grid>
                    </>
                )}
            </Formik>
        </Grid>
    )
}

export default UserSettingsView
