import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SnackbarContent, useSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import useTimer from 'src/stores/useTimer'
import {
    changeFavicon,
    formatDurationFromSeconds,
    getRunningTimer,
} from 'src/utils'
import RunningTimer from '../TaskTimer/RunningTimer'

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '344px !important',
        },
    },
}))

// https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-using-html-canvas
/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    if (typeof stroke === 'undefined') {
        stroke = true
    }
    if (typeof radius === 'undefined') {
        radius = 5
    }
    if (typeof radius === 'number') {
        radius = { tl: radius, tr: radius, br: radius, bl: radius }
    } else {
        var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 }
        for (var side in defaultRadius) {
            radius[side] = radius[side] || defaultRadius[side]
        }
    }
    ctx.beginPath()
    ctx.moveTo(x + radius.tl, y)
    ctx.lineTo(x + width - radius.tr, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr)
    ctx.lineTo(x + width, y + height - radius.br)
    ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius.br,
        y + height
    )
    ctx.lineTo(x + radius.bl, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl)
    ctx.lineTo(x, y + radius.tl)
    ctx.quadraticCurveTo(x, y, x + radius.tl, y)
    ctx.closePath()
    if (fill) {
        ctx.fill()
    }
    if (stroke) {
        ctx.stroke()
    }
}

function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str
}

const generateFavicon = (faviconString) => {
    var canvas = document.createElement('canvas')
    const width = 512
    canvas.width = width
    canvas.height = width
    var ctx = canvas.getContext('2d')
    ctx.fillStyle = '#3f51b5'
    ctx.strokeStyle = '#3f51b5'
    roundRect(ctx, 0, 0, width, width, width / 3, true, false)
    ctx.fillStyle = '#FFFFFF'
    ctx.textAlign = 'center'
    if (faviconString.startsWith('00:')) {
        ctx.font = `bold ${width * 0.75}px roboto`
        ctx.fillText(faviconString.slice(3), width / 2, (width * 6.2) / 8)
    } else {
        let parts = faviconString.split(':')
        ctx.font = `bold ${width * 0.55}px roboto`
        ctx.fillText(parts[0], width / 2, width * 0.48)
        ctx.fillText(parts[1], width / 2, width * 0.96)
    }
    return canvas
}

const GlobalTimer = () => {
    const navigate = useNavigate()
    const { runningTask, stopTimer } = useTimer()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [runningTime, setRunningTime] = useState(0)

    var faviconString = formatDurationFromSeconds(runningTime, false)
    const favicon = useMemo(
        () => generateFavicon(faviconString),
        [faviconString]
    )
    // console.log({faviconString, runningTime, runningTask})

    const GlobalTimerSnackbar = React.forwardRef((props, ref) => {
        const classes = useStyles()

        return (
            <SnackbarContent ref={ref} className={classes.root}>
                <Card variant='outlined' style={{ width: '100%' }}>
                    <CardContent
                        style={{
                            padding: '12px',
                            paddingBottom: '10px',
                        }}
                    >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2}
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Grid
                                item
                                style={{
                                    // paddingTop: '6px',
                                    maxWidth: '195px',
                                }}
                            >
                                <Typography
                                    // noWrap
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        // maxHeight: '40px',
                                    }}
                                    onClick={() =>
                                        navigate(
                                            '/app/tasks/' +
                                                runningTask._id +
                                                '/notes'
                                        )
                                    }
                                >
                                    {truncate(runningTask.text, 55)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RunningTimer
                                    handleStop={handleStop}
                                    duration={totalRecordedTime}
                                    timer={getRunningTimer(runningTask)}
                                    handleTimeChange={(value) =>
                                        setRunningTime(value)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </SnackbarContent>
        )
    })

    useEffect(() => {
        // console.log("redrawing favicon", runningTask)
        if (faviconString === '00:00' || runningTask === null) {
            changeFavicon('/favicon.ico')
        } else {
            changeFavicon(favicon.toDataURL('image/x-icon'))
        }
        if (runningTask === null) {
            setRunningTime(0)
        }
    }, [runningTime, runningTask, runningTask?._id])

    const [currentSnackbar, setCurrentSnackbar] = useState(null)

    useEffect(() => {
        if (runningTask !== null && currentSnackbar !== runningTask._id) {
            // console.log({runningTask, currentSnackbar})
            closeSnackbar(currentSnackbar)
            setCurrentSnackbar(runningTask._id)
            enqueueSnackbar('placeholder', {
                persist: true,
                key: runningTask._id,
                // anchorOrigin: { vertical: 'top', horizontal: 'right' },
                content: <GlobalTimerSnackbar />,
            })
        }
        if (runningTask === null) {
            closeSnackbar(currentSnackbar)
            setCurrentSnackbar(null)
        }
    }, [runningTask, runningTask?._id])

    if (runningTask === null) {
        return (
            <Helmet defer={false}>
                <title>TodoX</title>
            </Helmet>
        )
    }

    const totalRecordedTime = runningTask.time_records.reduce(
        (total, timeRecord) => total + timeRecord.duration,
        0
    )

    const handleStop = async () => {
        await stopTimer(runningTask?._id)
    }

    return (
        <Helmet defer={false}>
            <title>
                {runningTask.text +
                    (runningTime > 0
                        ? ': ' + formatDurationFromSeconds(runningTime)
                        : null)}
            </title>
        </Helmet>
    )
}

export default GlobalTimer
