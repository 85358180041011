import { Skeleton } from '@material-ui/lab'
import {
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Filler,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip,
} from 'chart.js'
import {
    addDays,
    addMonths,
    differenceInCalendarDays,
    differenceInCalendarMonths,
    format,
    parseISO,
} from 'date-fns'
import { memo } from 'react'
import { Line } from 'react-chartjs-2'
import { useLanguage } from 'src/stores/useLanguage'
import { TaskInStore } from 'src/typing'
import { formatDurationFromSeconds } from 'src/utils'
import './styles.css'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler
)

interface RecordedTimeChartProps {
    timeFrom: Date
    timeTo: Date
    tasks: TaskInStore[]
    isLoading?: boolean
}

const RecordedTimeChart = (props: RecordedTimeChartProps) => {
    const { timeFrom, timeTo, tasks, isLoading } = props
    const { t, getDateLocale } = useLanguage()

    let diffFunc = differenceInCalendarDays
    let stepFunc = addDays
    let xLabelFormat = 'd LLL'

    if (differenceInCalendarMonths(timeTo, timeFrom) > 1) {
        diffFunc = differenceInCalendarMonths
        stepFunc = addMonths
        xLabelFormat = 'LLL'
    }

    const bucketsCount = diffFunc(timeTo, timeFrom) + 1

    if (isLoading) {
        return (
            <>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </>
        )
    }

    let buckets: number[] = Array(bucketsCount).fill(0)

    for (const task of tasks) {
        for (const record of task.time_records) {
            let startDT = parseISO(record.start_dt)
            if (timeFrom <= startDT && startDT <= timeTo) {
                buckets[diffFunc(startDT, timeFrom)] += record.duration
            }
        }
    }

    // Convert to hours
    buckets = buckets.map((seconds) => seconds / 3600)

    const labels = []

    for (let i = 0; i < bucketsCount; i++) {
        labels[i] = format(stepFunc(timeFrom, i), xLabelFormat, {
            locale: getDateLocale(),
        })
    }

    const data = {
        labels,
        datasets: [
            {
                label: t('hours'),
                data: buckets,
                borderColor: '#3f51b5',
                backgroundColor: 'rgba(63, 81, 181, 0.5)',
                tension: 0.2,
                fill: true,
            },
        ],
    }

    const options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || ''

                        if (label) {
                            label += ': '
                        }
                        return (
                            label +
                            formatDurationFromSeconds(
                                context.parsed.y * 3600,
                                false
                            )
                        )
                    },
                },
            },
        },
        scales: {
            x: {
                // Add offset to center if only one day on the graph
                offset: differenceInCalendarDays(timeTo, timeFrom) === 0,
            },
            y: {
                suggestedMin: 0,
                suggestedMax: 1,
                ticks: {
                    stepSize: 1,
                },
            },
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
    }

    return <Line options={options} data={data} height={360} />
}

export default memo(RecordedTimeChart)
