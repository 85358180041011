import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { StyledTableCell } from 'src/components/Stats/TotalTasksStatsCard'
import { calculateTaskTime } from 'src/helpers'
import { useLanguage } from 'src/stores/useLanguage'
import useProjects from 'src/stores/useProjects'
import { Task } from 'src/typing'
import { formatDurationFromSeconds } from 'src/utils'

interface TimeStatsByTaskProps {
    tasks: Task[]
    dateFrom: Date
    dateTo: Date
    isLoading: boolean | undefined
}

interface ProcessedTask {
    task: Task
    totalTime: number
    totalLocalTime: number
    projectsLine: string
}

const TimeStatsByTask = (props: TimeStatsByTaskProps) => {
    const { tasks, dateFrom, dateTo, isLoading } = props

    const { t } = useLanguage()

    const { getProjectsLine } = useProjects()

    if (isLoading) {
        return (
            <>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </>
        )
    }

    var tasksProcessed: ProcessedTask[] = []

    for (let task of tasks) {
        let [totalTime, totalLocalTime] = calculateTaskTime(
            task,
            dateFrom,
            dateTo
        )
        if (totalLocalTime > 0) {
            let projectsLine = getProjectsLine(task.project_id)
                .map((project) => project.name)
                .join(' / ')
            tasksProcessed.push({
                task,
                totalTime,
                totalLocalTime,
                projectsLine,
            })
        }
    }

    tasksProcessed.sort((a, b) => b.totalLocalTime - a.totalLocalTime)

    tasksProcessed = tasksProcessed.slice(0, 20)

    if (tasksProcessed.length === 0) {
        return (
            <Typography variant='body2' color='textSecondary'>
                {t('noTimeRecorded')}
            </Typography>
        )
    }

    const maxDuration = tasksProcessed[0]['totalLocalTime']

    return (
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{ width: '100%' }}>
                            <Typography>
                                <b>{t('task')}</b>
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography>
                                <b>{t('time')}</b>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tasksProcessed.map((task, index) => (
                        <TableRow hover key={index}>
                            <StyledTableCell
                                bgSize={
                                    (task.totalLocalTime / maxDuration) * 100
                                }
                                overflowWrap
                            >
                                <Typography>{task.task.text}</Typography>
                                <Typography
                                    variant='body2'
                                    color='textSecondary'
                                >
                                    {task.projectsLine}
                                </Typography>
                            </StyledTableCell>
                            <TableCell align='right'>
                                <Typography>
                                    {formatDurationFromSeconds(
                                        task.totalLocalTime,
                                        false
                                    )}
                                </Typography>
                                {task.totalLocalTime !== task.totalTime ? (
                                    // <>
                                    //{/* <br /> */}
                                    <Typography noWrap color='textSecondary'>
                                        {t('total') +
                                            ' ' +
                                            formatDurationFromSeconds(
                                                task.totalTime,
                                                false
                                            )}
                                    </Typography>
                                ) : //</TableCell>{/* <span
                                //     style={{
                                //         color: 'gray',
                                //         whiteSpace: 'nowrap',
                                //     }}
                                // >

                                // </span> */}
                                // </>
                                null}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TimeStatsByTask
