import { createContext, FC, useCallback, useRef } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

// Needed to prevent re-rendering of all components using useNavigate
// on change of path.  Can affect relative navigation over history (like -1)

const StableRouterContext = createContext<NavigateFunction | null>(null)

const StableRouterContextProvider: FC = ({ children }) => {
    const navigate = useNavigate()

    const navigateRef = useRef(navigate)

    const contextValue = useCallback(
        (to) => {
            navigateRef.current(to)
        },
        [navigateRef]
    )
    return (
        <StableRouterContext.Provider value={contextValue}>
            {children}
        </StableRouterContext.Provider>
    )
}

export { StableRouterContextProvider, StableRouterContext }
