import { useContext } from 'react'
import {
    apiCreateProject,
    apiDeleteProject,
    apiFetchProjects,
    apiUpdateProject,
} from 'src/api/projects'
import { Project, ProjectInUpdate } from 'src/typing'
import {
    ProjectInStore,
    ProjectsContext,
    ProjectsStoreActionType,
} from './projectsStore'
import { TasksDispatchContext, TasksStoreActionType } from './tasksStore'

const useProjects = () => {
    const { state, dispatch } = useContext(ProjectsContext)
    const tasksDispatch = useContext(TasksDispatchContext)
    const { projects } = state

    const fetchProjects = async () => {
        const response = await apiFetchProjects()
        if (response.status === 200) {
            dispatch({
                type: ProjectsStoreActionType.UPDATE_STORE,
                payload: response.data.data,
            })
        }
    }

    const updateProject = async (
        projectId: string,
        changes: ProjectInUpdate
    ) => {
        const response = await apiUpdateProject(projectId, changes)
        if (response.status === 200) {
            dispatch({
                type: ProjectsStoreActionType.UPDATE_STORE,
                payload: [response.data.data],
            })
        }
    }

    const createProject = async (project: Project) => {
        const response = await apiCreateProject(project)
        if (response.status === 201) {
            dispatch({
                type: ProjectsStoreActionType.UPDATE_STORE,
                payload: [response.data.data],
            })
            return response.data.data
        }
        return null
    }

    const deleteProject = async (projectId: string) => {
        const response = await apiDeleteProject(projectId)
        if (response.status === 200) {
            dispatch({
                type: ProjectsStoreActionType.DELETE,
                payload: {
                    // updatedTasks: response.data.updated_tasks,
                    updatedProjects: response.data.updated_projects,
                    deletedProjectId: projectId,
                },
            })
            tasksDispatch({
                type: TasksStoreActionType.UPDATE_STORE,
                payload: response.data.updated_tasks,
            })
        }
    }

    const getProjectById = (
        projectId: string | null
    ): ProjectInStore | null => {
        let result = null
        projects.forEach((project) => {
            if (project._id === projectId) result = project
        })

        return result
    }

    const getProjectsLine = (projectId: string | null): ProjectInStore[] => {
        let projectsLine: ProjectInStore[] = []
        let project = getProjectById(projectId)
        if (project !== null) {
            projectsLine.unshift(project)

            while (project?.parent_id !== null) {
                project = getProjectById(project?.parent_id || null)
                if (project !== null) projectsLine.unshift(project)
            }
        }
        return projectsLine
    }

    return {
        projects,
        projectsTree: state.projectsTree,
        fetchProjects,
        updateProject,
        deleteProject,
        createProject,
        getProjectById,
        getProjectsLine,
    }
}

export default useProjects
