import { useContext } from 'react'
import { ProjectsContext, ProjectsStoreActionType } from './projectsStore'
import { TasksDispatchContext, TasksStoreActionType } from './tasksStore'
import { UserContext, UserStoreActionType } from './userStore'
import { WidgetsContext, WidgetsStoreActionType } from './widgetsStore'

const useLogOut = () => {
    const tasksDispatch = useContext(TasksDispatchContext)
    const { dispatch: userDispatch } = useContext(UserContext)
    const { dispatch: widgetsDispatch } = useContext(WidgetsContext)
    const { dispatch: projectsDispatch } = useContext(ProjectsContext)

    const logOut = () => {
        tasksDispatch({ type: TasksStoreActionType.CLEAR_STORE })
        userDispatch({ type: UserStoreActionType.LOG_OUT })
        widgetsDispatch({ type: WidgetsStoreActionType.CLEAR_STORE })
        projectsDispatch({ type: ProjectsStoreActionType.CLEAR_STORE })
    }

    return { logOut }
}

export default useLogOut
