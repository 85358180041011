import { Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import { tokenToDate } from 'src/utils'

const ActiveText = (props) => {
    let { text, style, icon, ...rest } = props

    if (style === undefined) style = {}

    return (
        <Typography
            noWrap
            style={{
                borderBottom: '1px dotted gray',
                textDecoration: 'none',
                cursor: 'pointer',
                color: 'gray',
                fontSize: '14px',
                ...style,
            }}
            {...rest}
        >
            {icon} {text}
        </Typography>
    )
}

const TaskDateInfo = (props) => {
    const { openTaskDateModal } = useModals()

    const { t, shortDateFormat } = useLanguage()

    const { task } = props
    const { date_token } = task
    let date = null
    if (date_token) {
        date = tokenToDate(date_token)
    }

    const text = date === null ? t('noDate') : format(date, shortDateFormat)

    return (
        <ActiveText
            data-test='task-date'
            onClick={() => {
                openTaskDateModal(task)
            }}
            text={text}
        />
    )
}

export default TaskDateInfo
export { ActiveText }
