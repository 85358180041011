import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { parseISO } from 'date-fns'
import { useLanguage } from 'src/stores/useLanguage'
import useProjects from 'src/stores/useProjects'
import { Task } from 'src/typing'
import { formatDurationFromSeconds } from 'src/utils'
import ProjectStatsRow from 'src/views/StatsViews/ProjectsTimeStatsView/ProjectStatsRow'

interface TimeStatsByProjectProps {
    dateFrom: Date
    dateTo: Date
    tasks: Task[]
    isLoading: boolean | undefined
}

export const getProjectsStats = (
    projectsTree,
    tasks,
    timeFrom,
    timeTo,
    noProjectName
) => {
    const projectsStats = {}
    tasks.forEach((task) => {
        const taskTime = task.time_records.reduce((sum, record) => {
            if (
                timeFrom <= parseISO(record.start_dt) &&
                parseISO(record.start_dt) <= timeTo
            ) {
                return (sum += record.duration)
            }
            return sum
        }, 0)
        if (projectsStats[task.project_id] === undefined) {
            projectsStats[task.project_id] = { totalTime: 0, tasks: [] }
        }
        projectsStats[task.project_id].totalTime += taskTime
        projectsStats[task.project_id].tasks.push(task)
    })

    const recordProjectTime = (project) => {
        return {
            ...project,
            time: projectsStats[project._id]?.totalTime ?? 0,
            tasks: projectsStats[project._id]?.tasks ?? [],
            subProjects: project.subProjects.map((project) =>
                recordProjectTime(project)
            ),
        }
    }

    var projectsTreeWithTime = projectsTree.map((project) => {
        return recordProjectTime(project)
    })

    projectsTreeWithTime.push({
        name: noProjectName,
        _id: null,
        tasks: projectsStats['null']?.tasks ?? [],
        time: projectsStats['null']?.totalTime ?? 0,
        subProjects: [],
        depth: 0,
    })

    const backPropagateTime = (project) => {
        let subProjects = project.subProjects.map((subProject) =>
            backPropagateTime(subProject)
        )
        subProjects = subProjects.filter(
            (subProject) => subProject.time + subProject.subProjectsTime > 0
        )
        return {
            ...project,
            subProjects,
            subProjectsTime: subProjects.reduce(
                (sum, subProject) =>
                    (sum += subProject.time + subProject.subProjectsTime),
                0
            ),
        }
    }

    projectsTreeWithTime = projectsTreeWithTime.map((project) => {
        return backPropagateTime(project)
    })

    // Remove projects with no time
    projectsTreeWithTime = projectsTreeWithTime.filter(
        (project) => project.time + project.subProjectsTime > 0
    )

    return [projectsStats, projectsTreeWithTime]
}

const TimeStatsByProject = (props: TimeStatsByProjectProps) => {
    const { dateFrom, dateTo, tasks, isLoading } = props
    const { t } = useLanguage()
    const { projectsTree } = useProjects()

    // const [isLoading, setIsLoading] = useState(false)

    // const timeFrom = startOfDay(dateFrom)
    // const timeTo = endOfDay(dateTo)

    // useEffect(() => {
    //     const fetchTasksRequest = async () => {
    //         setIsLoading(true)
    //         const query = {
    //             time_from: timeFrom.toISOString(),
    //             time_to: timeTo.toISOString(),
    //         }
    //         const response = await fetchTasks(query)
    //         if (response.status === 200) {
    //             setTasks(response.data.data)
    //             setIsLoading(false)
    //         }
    //     }
    //     fetchTasksRequest()
    // }, [dateFrom, dateTo])

    const [projectsStats, projectsTreeWithTime] = getProjectsStats(
        projectsTree,
        tasks,
        dateFrom,
        dateTo,
        t('noProject')
    )

    projectsTreeWithTime.sort(
        (a, b) => b.time + b.subProjectsTime - (a.time + a.subProjectsTime)
    )

    const projectsList = []

    const unpackProject = (project) => {
        if (project.time > 0 || project.subProjectsTime > 0) {
            projectsList.push(project)
        }
        if (project.subProjectsTime > 0) {
            project.subProjects.sort(
                (a, b) =>
                    b.time + b.subProjectsTime - (a.time + a.subProjectsTime)
            )
            project.subProjects.forEach((project) => {
                unpackProject(project)
            })
        }
    }

    projectsTreeWithTime.forEach((project) => {
        unpackProject(project)
    })

    const totalTime = projectsTreeWithTime.reduce(
        (sum, project) => (sum += project.time + project.subProjectsTime),
        0
    )

    if (isLoading) {
        return (
            <>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </>
        )
    }

    if (projectsTreeWithTime.length === 0) {
        return (
            <Typography
                variant='body2'
                color='textSecondary'
                style={{ marginTop: '16px' }}
            >
                {t('noTimeRecorded')}
            </Typography>
        )
    }

    const maxTime: number =
        projectsTreeWithTime[0].time + projectsTreeWithTime[0].subProjectsTime

    return (
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        {/* <TableCell></TableCell> */}
                        <TableCell align='center'>
                            <Typography>
                                <b>{t('name')}</b>
                            </Typography>
                        </TableCell>
                        <TableCell align='center'>
                            <Typography>
                                <b>{t('time')}</b>
                            </Typography>
                        </TableCell>

                        <TableCell align='center'>
                            <Typography>
                                <b>%</b>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projectsTreeWithTime.map((project, index) => (
                        <ProjectStatsRow
                            project={project}
                            totalTime={totalTime}
                            key={index}
                            open={false}
                            maxTime={maxTime}
                        />
                    ))}
                    <TableRow>
                        <TableCell
                            align='right'
                            // colSpan={3}
                            style={{ border: 'none' }}
                        >
                            <Typography>
                                <b>{t('totalTimeRecorded')}:</b>
                            </Typography>
                        </TableCell>
                        <TableCell style={{ border: 'none' }}>
                            <Typography>
                                <b>
                                    {formatDurationFromSeconds(
                                        totalTime,
                                        false
                                    )}
                                </b>
                            </Typography>
                        </TableCell>
                        <TableCell style={{ border: 'none' }}></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TimeStatsByProject
