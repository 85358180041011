import { format } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    apiCreateTask,
    apiDeleteTask,
    apiFetchTask,
    apiFetchTasks,
    apiUpdateTask,
} from 'src/api/tasks'
import {
    apiCreateTaskTimeRecord,
    apiDeleteTaskTimeRecord,
} from 'src/api/timeRecords'
import { useLanguage } from 'src/stores/useLanguage'
import useTimer from 'src/stores/useTimer'
import { formatDurationFromSeconds, tokenToDate } from 'src/utils'
import { TasksContext, TasksStoreActionType } from './tasksStore'
import useUser from './useUser'
// import { apiFetchTaskStats } from 'src/api/stats'

const maxTaskTextLength = 25

const shortenText = (str: string): string => {
    return str.length > maxTaskTextLength
        ? str.substr(0, maxTaskTextLength - 1) + '...'
        : str
}

const useTasks = () => {
    const { userState } = useUser()
    const { state: tasksState, dispatch: tasksDispatch } =
        useContext(TasksContext)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const snackbarDefaultStyle = { cursor: 'pointer' }

    const { stopTimer } = useTimer()

    const { t, tArray } = useLanguage()

    const createTask = async (task: any) => {
        const response = await apiCreateTask(task)
        if (response.status === 201) {
            tasksDispatch({
                type: TasksStoreActionType.UPDATE_STORE,
                payload: [response.data.data],
            })
            enqueueSnackbar(
                t('nftTaskCreated', { text: shortenText(task.text) }),
                {
                    onClick: () =>
                        navigate(
                            '/app/tasks/' + response.data.data?._id + '/notes'
                        ),
                    style: snackbarDefaultStyle,
                }
            )
            return response.data.data
        }
        return null
    }

    const fetchTasks = async (query = {}) => {
        const response = await apiFetchTasks(query)
        if (response.status === 200) {
            tasksDispatch({
                type: TasksStoreActionType.UPDATE_STORE,
                payload: response.data.data,
            })
        }
        return response
    }

    const deleteTask = async (id: string) => {
        const response = await apiDeleteTask(id)
        if (response.status === 200 && response.data.deleted_count === 1) {
            tasksDispatch({ type: TasksStoreActionType.DELETE, payload: id })
            enqueueSnackbar(t('nftTaskDeleted'))
        }
    }

    const createTaskTimeRecord = async (taskId: string, record: any) => {
        const response = await apiCreateTaskTimeRecord(taskId, record)
        if (response.status === 201) {
            tasksDispatch({
                type: TasksStoreActionType.UPDATE_STORE,
                payload: [response.data.data],
            })
            enqueueSnackbar(
                t('nftTimeRecorded', {
                    time: formatDurationFromSeconds(record.duration, false),
                })
            )
        }
    }

    const updateTask = async (id: string, changes: any) => {
        const response = await apiUpdateTask(id, changes)
        // console.log({ changes, response })
        if (response.status === 200) {
            // Stop timer if task is completed
            if (
                changes.status === 'done' &&
                tasksState.runningTask?._id === id
            ) {
                await stopTimer(id)
            }
            tasksDispatch({
                type: TasksStoreActionType.UPDATE_STORE,
                payload: response.data.data,
            })
            const messages = []
            if (changes.date_token !== undefined) {
                if (changes.date_token !== null) {
                    messages.push(
                        t('nftNewTaskDate', {
                            date: format(
                                tokenToDate(changes.date_token),
                                userState.shortDateFormat
                            ),
                        })
                    )
                } else {
                    messages.push(t('nftTaskDateRemoved'))
                }
            }
            if (changes.text !== undefined) {
                messages.push(
                    t('nftNewTaskText', { text: shortenText(changes.text) })
                )
            }
            if (changes.notes !== undefined) {
                messages.push(t('nftTaskNotesSaved'))
            }
            if (changes.project_id !== undefined) {
                messages.push(t('nftTaskProjectChanged'))
            }
            if (changes.repeat !== undefined) {
                let repeatText = t('noRepeat')
                const daysOfWeek = tArray('daysOfWeek')
                const { repeat } = changes
                if (repeat !== undefined && repeat !== null) {
                    if (repeat.repeat_type === 'daily') {
                        repeatText = t('nftEveryXDays', { days: repeat.daily })
                    }

                    if (repeat.repeat_type === 'monthly') {
                        repeatText = t('nftEveryXDaysOfMonth', {
                            days: repeat.monthly.join(', '),
                        })
                    }

                    if (repeat.repeat_type === 'weekly') {
                        repeatText = t('nftEveryXDaysOfWeek', {
                            days: repeat.weekly
                                .sort((a: any, b: any) => a - b)
                                .map((dayNum: any) => daysOfWeek[dayNum])
                                .join(', '),
                        })
                    }
                }
                messages.push(
                    t('nftNewRepeatSettings', { settings: repeatText })
                )
            }
            if (
                changes.status !== undefined &&
                response.data.data.length === 2
            ) {
                messages.push(
                    t('nftTaskCompletedNextRepeat', {
                        date: format(
                            tokenToDate(response.data.data[1].date_token),
                            userState.shortDateFormat
                        ),
                    })
                )
            }

            if (
                changes.status !== undefined &&
                response.data.data.length === 1
            ) {
                messages.push(t('nftTaskCompleted'))
            }
            if (messages.length === 0) messages[0] = t('nftTaskChanged')
            enqueueSnackbar(messages.join(' '), {
                onClick: () =>
                    navigate(
                        '/app/tasks/' +
                            response.data.data[response.data.data.length - 1]
                                ?._id +
                            '/notes'
                    ),
                style: snackbarDefaultStyle,
            })
        }
        return response
    }

    const deleteTaskTimeRecord = async (taskId: string, recordId: string) => {
        const response = await apiDeleteTaskTimeRecord(taskId, recordId)
        if (response.status === 200) {
            tasksDispatch({
                type: TasksStoreActionType.UPDATE_STORE,
                payload: [response.data.data],
            })
            enqueueSnackbar(t('nftTimeEntryDeleted'))
        }
    }

    const fetchTask = async (id: string) => {
        const response = await apiFetchTask(id)

        if (response.status === 200) {
            tasksDispatch({
                type: TasksStoreActionType.UPDATE_STORE,
                payload: [response.data.data],
            })
        }
        return response.data
    }

    const getOrFetchTaskById = async (id: string) => {
        let task = null
        tasksState.tasks.forEach((item) => item._id === id && (task = item))
        if (task === null) {
            const responseData = await fetchTask(id)
            if (responseData.data?._id === id) {
                task = responseData.data
            }
        }
        return task
    }

    const getTaskById = (id: string) => {
        let task = null
        tasksState.tasks.forEach((item) => item?._id === id && (task = item))
        return task
    }

    return {
        globalDispatch: tasksDispatch,
        tasksStore: tasksState.tasks,
        runningTask: tasksState.runningTask,
        todayToken: tasksState.todayToken,
        fetchTasks,
        deleteTask,
        updateTask,
        createTask,
        deleteTaskTimeRecord,
        getTaskById,
        createTaskTimeRecord,
        fetchTask,
        getOrFetchTaskById,
        // fetchTasksStats
    }
}

export default useTasks
