import { Typography, TypographyProps } from '@material-ui/core'

interface ActiveTextProps extends TypographyProps {
    text: string
    style?: { [key: string]: string | number } | undefined
    icon?: any | undefined
    [key: string]: any
}

const ActiveText = (props: ActiveTextProps) => {
    let { text, style, icon, ...rest } = props

    if (style === undefined) style = {}

    return (
        <Typography
            noWrap
            style={{
                borderBottom: '1px dotted gray',
                textDecoration: 'none',
                cursor: 'pointer',
                color: 'gray',
                display: 'inline-block',
                fontSize: '14px',
                ...style,
            }}
            {...rest}
        >
            {icon} {text}
        </Typography>
    )
}

export default ActiveText
