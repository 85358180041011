import { createContext, Dispatch, FC, useReducer } from 'react'
import { Widget } from 'src/typing'
import { updateArray } from './storeTools'

interface WidgetsStore {
    widgets: Widget[]
}

enum WidgetsStoreActionType {
    UPDATE_STORE,
    DELETE,
    CLEAR_STORE,
}

interface UpdateProjectsStoreAction {
    type: WidgetsStoreActionType.UPDATE_STORE
    payload: Widget[]
}

interface DeleteWidgetAction {
    type: WidgetsStoreActionType.DELETE
    payload: string
}

interface ClearWidgetsStoreAction {
    type: WidgetsStoreActionType.CLEAR_STORE
    payload?: undefined
}

type WidgetsStoreAction =
    | UpdateProjectsStoreAction
    | DeleteWidgetAction
    | ClearWidgetsStoreAction

const initWidgetsState = (): WidgetsStore => {
    return {
        widgets: [],
    }
}

const widgetsStateReducer = (
    state: WidgetsStore,
    action: WidgetsStoreAction
): WidgetsStore => {
    const { type, payload } = action
    switch (type) {
        case WidgetsStoreActionType.UPDATE_STORE:
            return { ...state, widgets: updateArray(state.widgets, payload) }
        case WidgetsStoreActionType.DELETE:
            return {
                ...state,
                widgets: state.widgets.filter(
                    (widget) => widget._id !== payload
                ),
            }
        case WidgetsStoreActionType.CLEAR_STORE:
            return initWidgetsState()
    }
}

const WidgetsContext = createContext<{
    state: WidgetsStore
    dispatch: Dispatch<WidgetsStoreAction>
}>({
    state: initWidgetsState(),
    dispatch: () => {},
})

const WidgetsContextProvider: FC = (props) => {
    const [state, dispatch] = useReducer(
        widgetsStateReducer,
        undefined,
        initWidgetsState
    )

    return (
        <WidgetsContext.Provider value={{ state, dispatch }}>
            {props.children}
        </WidgetsContext.Provider>
    )
}

export { WidgetsContext, WidgetsContextProvider, WidgetsStoreActionType }
