import DateFnsUtils from '@date-io/date-fns'
import { Button, Grid, Hidden, IconButton, Typography } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { addDays, subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TasksList from 'src/components/TasksList'
import { useLanguage } from 'src/stores/useLanguage'
import useTasks from 'src/stores/useTasks'
import { dateToToken, tokenToDate } from 'src/utils'
import WidgetsPanel from 'src/views/WidgetViews/WidgetsPanel'

const TodayTasksView = () => {
    const { todayToken } = useTasks()
    const { t, shortDateFormat, getDateLocale } = useLanguage()

    const [date, setDate] = useState<Date>(new Date())
    const navigate = useNavigate()

    useEffect(() => {
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        if (dateToToken(date) === dateToToken(yesterday)) {
            setDate(tokenToDate(todayToken))
        }
    }, [todayToken])

    const query = { date_token: dateToToken(date) }

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12}>
                <Grid item>
                    <IconButton
                        size='small'
                        onClick={() => setDate((value) => subDays(value, 1))}
                        data-test='prev-day-btn'
                    >
                        <NavigateBeforeIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={getDateLocale()}
                    >
                        <DatePicker
                            autoOk
                            format={shortDateFormat}
                            value={date}
                            onChange={(date) => setDate(date)}
                            TextFieldComponent={(props) => (
                                <Typography
                                    style={{
                                        borderBottom: '1px dotted #000',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                    }}
                                    variant='h5'
                                    onClick={props.onClick}
                                >
                                    {props.value}
                                </Typography>
                            )}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <IconButton
                        size='small'
                        onClick={() => setDate((value) => addDays(value, 1))}
                        data-test='next-day-btn'
                    >
                        <NavigateNextIcon />
                    </IconButton>
                </Grid>
                {todayToken !== dateToToken(date) && (
                    <Grid item>
                        <Button
                            size='small'
                            variant='outlined'
                            onClick={() => setDate(new Date())}
                        >
                            {t('today')}
                        </Button>
                    </Grid>
                )}
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Hidden mdUp>
                    <Grid item>
                        <Button
                            size='small'
                            onClick={() => navigate('/app/widgets/')}
                        >
                            {t('widgets')}
                        </Button>
                    </Grid>
                </Hidden>
            </Grid>
            <Grid item xs={12} md={6}>
                <TasksList query={query} tasksPerPage={50} listName='today' />
            </Grid>
            <Hidden smDown>
                <Grid item md={6}>
                    <WidgetsPanel group='today_widgets' />
                </Grid>
            </Hidden>
        </Grid>
    )
}

export default TodayTasksView
