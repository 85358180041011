import { createContext, Dispatch, FC, useReducer } from 'react'

type LanguageCodes = 'en' | 'ru'

type ShortDateFormat = 'yyyy-MM-dd' | 'dd.MM.yyyy' | 'MM/dd/yyyy'

interface UserStore {
    language: LanguageCodes
    email: string | null
    isLoggedIn: undefined | boolean
    emailConfirmedAt: string | null
    confirmationEmailSentAt: string | null
    shortDateFormat: ShortDateFormat
    isMondayFirstDayOfWeek: boolean
}

enum UserStoreActionType {
    LOG_IN,
    LOG_OUT,
    UPDATE_USER,
}

interface UpdateUserStoreActionPayload {
    language?: LanguageCodes
    isLoggedIn?: undefined | boolean
    shortDateFormat?: ShortDateFormat
    confirmationEmailSentAt?: string
    emailConfirmedAt?: string
}

interface UpdateUserStoreAction {
    type: UserStoreActionType.UPDATE_USER
    payload: UpdateUserStoreActionPayload
}

interface LogOutUserStoreAction {
    type: UserStoreActionType.LOG_OUT
    payload?: undefined
}

interface LogInUserStoreActionPayload {
    language: LanguageCodes
    email: string
    email_confirmed_at: null | string
    confirmation_email_sent_at: null | string
    start_week_with_monday: boolean
    short_date_format: ShortDateFormat
}

interface LogInUserStoreAction {
    type: UserStoreActionType.LOG_IN
    payload: LogInUserStoreActionPayload
}

type UserStoreAction =
    | UpdateUserStoreAction
    | LogOutUserStoreAction
    | LogInUserStoreAction

const userStateReducer = (
    state: UserStore,
    action: UserStoreAction
): UserStore => {
    const { type, payload } = action

    switch (type) {
        case UserStoreActionType.LOG_IN:
            return {
                isLoggedIn: true,
                language: ['en', 'ru'].includes(payload.language)
                    ? payload.language
                    : 'en',
                email: payload.email,
                emailConfirmedAt: payload.email_confirmed_at,
                confirmationEmailSentAt: payload.confirmation_email_sent_at,
                isMondayFirstDayOfWeek: payload.start_week_with_monday,
                shortDateFormat: payload.short_date_format,
            }
        case UserStoreActionType.LOG_OUT:
            return initUserState({
                language: state.language,
                isLoggedIn: false,
                // updatedAt: state.updatedAt,
            })
        // case TRIGGER_RELOAD:
        //     return { ...state, updatedAt: new Date().toISOString() }
        case UserStoreActionType.UPDATE_USER:
            return {
                ...state,
                ...payload,
            }
    }
}

const initUserState = (initValues = {}): UserStore => {
    return {
        language: 'en',
        email: null,
        isLoggedIn: undefined,
        emailConfirmedAt: null,
        confirmationEmailSentAt: null,
        shortDateFormat: 'yyyy-MM-dd',
        isMondayFirstDayOfWeek: true,
        ...initValues,
    }
}

const UserContext = createContext<{
    state: UserStore
    dispatch: Dispatch<UserStoreAction>
}>({
    state: initUserState(),
    dispatch: () => {},
})

const UserContextProvider: FC = (props) => {
    const [state, dispatch] = useReducer(
        userStateReducer,
        undefined,
        initUserState
    )

    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContextProvider, UserContext, UserStoreActionType }
export type { LogInUserStoreActionPayload, UpdateUserStoreActionPayload }
