import { parseISO } from 'date-fns'
import { Task } from './typing'

const calculateTaskTime = (
    task: Task,
    timeFrom: Date | undefined = undefined,
    timeTo: Date | undefined = undefined
) => {
    var totalTime = 0,
        localTotalTime = 0

    if (timeFrom === undefined || timeTo === undefined) {
        totalTime = localTotalTime = task.time_records.reduce(
            (total, record) => (total += record.duration),
            0
        )
    } else {
        task.time_records.forEach((record) => {
            let startDT = parseISO(record.start_dt)
            totalTime += record.duration
            if (timeFrom <= startDT && startDT <= timeTo) {
                localTotalTime += record.duration
            }
        })
    }

    return [totalTime, localTotalTime]
}

export { calculateTaskTime }
