import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import { subDays } from 'date-fns'
import TasksList from 'src/components/TasksList'
import { useLanguage } from 'src/stores/useLanguage'
import useTasks from 'src/stores/useTasks'
import { dateToToken, tokenToDate } from 'src/utils'

const OverdueTasks = () => {
    const { todayToken } = useTasks()
    // console.log({ todayInOverdue: todayToken })
    const yesterdayToken = dateToToken(subDays(tokenToDate(todayToken), 1))

    const { t } = useLanguage()

    const query = { date_to: yesterdayToken, status: 'active', limit: 100 }

    return (
        <TasksList
            query={query}
            tasksPerPage={100}
            title={t('overdue')}
            listName='overdue'
            icon={
                <AssignmentLateIcon
                    fontSize='large'
                    style={{ marginBottom: -9 }}
                />
            }
        />
    )
}

export default OverdueTasks
