import { useContext, useEffect, useRef, useState } from 'react'
import { apiRefresh } from 'src/api/auth'
import { DEVICE_ID } from 'src/api/base'
import {
    ProjectsContext,
    ProjectsStoreActionType,
} from 'src/stores/projectsStore'
import type { Task } from 'src/typing'
import { TasksDispatchContext, TasksStoreActionType } from './tasksStore'
import useUser from './useUser'

interface UpdaterStore {
    finalRunningTaskState: Task | undefined | null
    finalRunningTaskUpdate: Task[] | undefined
    tasksToUpdate: { [key: string]: Task | null }
}

const EventListener = () => {
    const { userState } = useUser()
    const tasksDispatch = useContext(TasksDispatchContext)
    const [lastUpdateAt, setLastUpdateAt] = useState(new Date())
    const updaterStoreRef = useRef<UpdaterStore>({
        finalRunningTaskState: undefined,
        finalRunningTaskUpdate: undefined,
        tasksToUpdate: {},
    })
    const { dispatch: projectsDispatch } = useContext(ProjectsContext)

    const addTasksToQueue = (tasks: Task[]) => {
        for (const task of tasks) {
            updaterStoreRef.current.tasksToUpdate[task._id] = task
        }
        setLastUpdateAt(new Date())
    }

    const applyChanges = () => {
        if (
            updaterStoreRef.current.finalRunningTaskState === null &&
            updaterStoreRef.current.finalRunningTaskUpdate !== undefined
        ) {
            tasksDispatch({
                type: TasksStoreActionType.CLEAR_RUNNING_TASK,
                payload: updaterStoreRef.current.finalRunningTaskUpdate[0],
            })
        }
        if (
            updaterStoreRef.current.finalRunningTaskState !== null &&
            updaterStoreRef.current.finalRunningTaskState !== undefined
        ) {
            tasksDispatch({
                type: TasksStoreActionType.SET_RUNNING_TASK,
                payload: [updaterStoreRef.current.finalRunningTaskState],
            })
            updaterStoreRef.current.finalRunningTaskState = undefined
            updaterStoreRef.current.finalRunningTaskUpdate = undefined
        }
        let tasksToDelete = []
        let tasksToUpdate: Task[] = []
        for (let taskId in updaterStoreRef.current.tasksToUpdate) {
            if (updaterStoreRef.current.tasksToUpdate[taskId] === null) {
                tasksToDelete.push(taskId)
            } else {
                tasksToUpdate.push(
                    //@ts-ignore
                    updaterStoreRef.current.tasksToUpdate[taskId]
                )
            }
            delete updaterStoreRef.current.tasksToUpdate[taskId]
        }
        // console.log({ tasksToDelete, tasksToUpdate })
        tasksDispatch({
            type: TasksStoreActionType.UPDATE_STORE,
            payload: tasksToUpdate,
        })
        for (let taskId of tasksToDelete) {
            tasksDispatch({
                type: TasksStoreActionType.DELETE,
                payload: taskId,
            })
        }
    }

    useEffect(() => {
        const timeout = setTimeout(applyChanges, 1000)

        return () => clearTimeout(timeout)
    }, [lastUpdateAt])

    useEffect(() => {
        if (!userState.isLoggedIn) return

        var evtSource: EventSource

        var lastEventId: string | null = null

        var timeout = 1

        const initEventSource = () => {
            console.log('Connecting to EventSource')
            let lastEventIdParam = lastEventId
                ? `&last_event=${lastEventId}`
                : ''
            evtSource = new EventSource(
                `/api/v1/events/stream/?device_id=${DEVICE_ID}${lastEventIdParam}`,
                { withCredentials: true }
            )

            evtSource.onerror = async (e) => {
                console.error(
                    'EventSource error:',
                    e,
                    'Connection status:',
                    evtSource.readyState
                )
                // evtSource.close()
                // console.log("Error in EventSource connection:", e)
                console.log('Refreshing tokens')
                await apiRefresh()
                if (evtSource.readyState === 2) {
                    evtSource.close()
                    setTimeout(initEventSource, timeout * 1000)
                    console.log(
                        `EventSource decided not to connect.  Reconnecting with timeout ${timeout} seconds.`
                    )
                    if (timeout < 60) timeout *= 2
                }
            }

            evtSource.onopen = (e) => {
                console.info('Connected to EventSource:', e)
                // lastEventId = null
                timeout = 1
            }

            evtSource.addEventListener('INIT', function (event) {
                if (lastEventId === null) lastEventId = event.lastEventId
            })

            evtSource.addEventListener('START_TIMER', function (event) {
                const tasks = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                // tasksDispatch({
                //     type: TasksStoreActionType.SET_RUNNING_TASK,
                //     payload: tasks,
                // })
                addTasksToQueue(tasks)
                updaterStoreRef.current.finalRunningTaskState = tasks[0]
                updaterStoreRef.current.finalRunningTaskUpdate = tasks
            })

            evtSource.addEventListener('UPDATE_TASK', function (event) {
                const tasks = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                // tasksDispatch({
                //     type: TasksStoreActionType.UPDATE_STORE,
                //     payload: tasks,
                // })
                addTasksToQueue(tasks)
            })

            evtSource.addEventListener('CREATE_TIME_RECORD', function (event) {
                const task = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                // tasksDispatch({
                //     type: TasksStoreActionType.UPDATE_STORE,
                //     payload: [task],
                // })
                addTasksToQueue([task])
            })

            evtSource.addEventListener('DELETE_TIME_RECORD', function (event) {
                const task = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                // tasksDispatch({
                //     type: TasksStoreActionType.UPDATE_STORE,
                //     payload: [task],
                // })
                addTasksToQueue([task])
            })

            evtSource.addEventListener('STOP_TIMER', function (event) {
                const task = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                // tasksDispatch({
                //     type: TasksStoreActionType.CLEAR_RUNNING_TASK,
                //     payload: task,
                // })
                addTasksToQueue([task])
                updaterStoreRef.current.finalRunningTaskState = null
                updaterStoreRef.current.finalRunningTaskUpdate = [task]
            })

            evtSource.addEventListener('DELETE_TASK', function (event) {
                const task_id = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                // tasksDispatch({
                //     type: TasksStoreActionType.DELETE,
                //     payload: task_id,
                // })
                updaterStoreRef.current.tasksToUpdate[task_id] = null
                setLastUpdateAt(new Date())
            })

            evtSource.addEventListener('CREATE_TASK', function (event) {
                const task = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                // tasksDispatch({
                //     type: TasksStoreActionType.UPDATE_STORE,
                //     payload: [task],
                // })
                addTasksToQueue([task])
            })

            evtSource.addEventListener('CREATE_PROJECT', function (event) {
                const project = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                projectsDispatch({
                    type: ProjectsStoreActionType.UPDATE_STORE,
                    payload: [project],
                })
            })

            evtSource.addEventListener('UPDATE_PROJECT', function (event) {
                const project = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                projectsDispatch({
                    type: ProjectsStoreActionType.UPDATE_STORE,
                    payload: [project],
                })
            })

            evtSource.addEventListener('DELETE_PROJECT', function (event) {
                const data = JSON.parse(event.data)
                //@ts-ignore
                if (lastEventId < event.lastEventId)
                    lastEventId = event.lastEventId
                projectsDispatch({
                    type: ProjectsStoreActionType.DELETE,
                    payload: {
                        // updatedTasks: data.updated_tasks,
                        updatedProjects: data.updated_projects,
                        deletedProjectId: data.deleted_project_id,
                    },
                })
                tasksDispatch({
                    type: TasksStoreActionType.UPDATE_STORE,
                    payload: data.updated_tasks,
                })
            })
        }

        initEventSource()
    }, [userState.isLoggedIn])

    return null
}

export default EventListener
