import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Formik } from 'formik'
import { useRef } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'

const parseMonthly = (monthlyString) => {
    let result = []
    if (monthlyString !== null && monthlyString.length > 0) {
        result = monthlyString.split(',')
        result = result.map((day) => parseInt(day.trim()))
        result = result.filter((item) => item > 0 && item <= 31).sort()
    }
    return result
}

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline',
    },
})

const ChangeTaskRepeatModal = (props) => {
    const { task, handleClose, handleSave } = props
    const { userState } = useUser()
    let { repeat } = task
    if (
        repeat === null ||
        repeat === undefined ||
        repeat?.repeat_type === null
    ) {
        repeat = {
            repeat_type: 'none',
            daily: null,
            weekly: [],
            monthly: [],
        }
    }
    const handleKeyDown = (e) => {
        let found = e.key.match(new RegExp(`[\\d\\s,]|Backspace|backspace`))
        if (found === null) {
            e.preventDefault()
        }
    }
    const dailyRef = useRef()
    const monthlyRef = useRef()

    const classes = useStyles()

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const daysOfWeekNums = [0, 1, 2, 3, 4, 5]
    const daysOfWeekNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat']

    if (userState.isMondayFirstDayOfWeek) {
        daysOfWeekNums.push(6)
        daysOfWeekNames.push('sun')
    } else {
        daysOfWeekNums.unshift(6)
        daysOfWeekNames.unshift('sun')
    }

    const { t } = useLanguage()

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth='xs'
            data-test='modal-repeat'
            classes={isSmall ? { scrollPaper: classes.scrollPaper } : {}}
        >
            <DialogTitle>{t('taskRepeatSettings')}</DialogTitle>
            <Formik
                validateOnMount
                validateOnChange
                initialValues={{
                    repeat_type: repeat.repeat_type,
                    daily: repeat.daily,
                    weekly: repeat.weekly,
                    monthlyStr: repeat.monthly.join(', '),
                }}
                validate={(values) => {
                    const errors = {}
                    if (values.repeat_type === 'daily' && values.daily <= 0) {
                        errors.content = 'mustBePositive'
                        errors.source = 'daily'
                    }
                    if (
                        values.repeat_type === 'weekly' &&
                        values.weekly.length === 0
                    ) {
                        errors.content = 'mustChooseAtLeastOne'
                        errors.source = 'weekly'
                    }
                    let monthly = parseMonthly(values.monthlyStr)
                    if (
                        values.repeat_type === 'monthly' &&
                        monthly.length === 0
                    ) {
                        errors.content = 'mustSetAtLeastOne'
                        errors.source = 'monthlyStr'
                    }
                    return errors
                }}
                onSubmit={async (values, { setErrors }) => {
                    let repeat = {
                        repeat_type:
                            values.repeat_type === 'none'
                                ? null
                                : values.repeat_type,
                        daily: values.daily,
                        weekly: values.weekly,
                        monthly: parseMonthly(values.monthlyStr),
                    }
                    let response = await handleSave(repeat)
                    console.log({ repeat, response })
                    if (response.status !== 200) {
                        setErrors({ source: 'server', content: 'serverError' })
                    }
                    handleClose()
                }}
            >
                {({
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    isSubmitting,
                    handleSubmit,
                }) => (
                    <>
                        <DialogContent>
                            <RadioGroup
                                name='repeat_type'
                                value={values.repeat_type}
                                onChange={handleChange}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value='none'
                                            data-test='repeat-none'
                                            control={<Radio color='primary' />}
                                            label={t('noRepeat')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value='daily'
                                            data-test='repeat-daily'
                                            control={<Radio color='primary' />}
                                            label={t('everyXDays')}
                                            onClick={() =>
                                                dailyRef.current.focus()
                                            }
                                        />
                                        <TextField
                                            inputRef={dailyRef}
                                            variant='outlined'
                                            data-test='repeat-daily-input'
                                            value={values.daily}
                                            name='daily'
                                            style={{ width: '80px' }}
                                            size='small'
                                            inputProps={{
                                                style: {
                                                    textAlign: 'center',
                                                },
                                                type: 'number',
                                                inputMode: 'numeric',
                                            }}
                                            onChange={handleChange}
                                            onFocus={() =>
                                                setFieldValue(
                                                    'repeat_type',
                                                    'daily'
                                                )
                                            }
                                        />
                                    </Grid>
                                    {values.repeat_type === 'daily' ? (
                                        <Grid item xs={12}>
                                            <FormHelperText>
                                                {t('mustBePositive')}
                                            </FormHelperText>
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value='weekly'
                                            data-test='repeat-weekly'
                                            control={<Radio color='primary' />}
                                            label={t('certainDaysOfWeek')}
                                        />
                                        <ToggleButtonGroup
                                            size='small'
                                            value={values.weekly}
                                            data-test='days-of-week-btns'
                                            onChange={(e, value) => {
                                                setFieldValue('weekly', value)
                                            }}
                                            onClick={() => {
                                                if (
                                                    values.repeat_type !==
                                                    'weekly'
                                                )
                                                    setFieldValue(
                                                        'repeat_type',
                                                        'weekly'
                                                    )
                                            }}
                                        >
                                            {daysOfWeekNums.map(
                                                (num, index) => (
                                                    <ToggleButton
                                                        disabled={
                                                            values.repeat_type !==
                                                            'weekly'
                                                        }
                                                        value={num}
                                                        data-test={'day-' + num}
                                                    >
                                                        {t(
                                                            daysOfWeekNames[
                                                                index
                                                            ]
                                                        )}
                                                    </ToggleButton>
                                                )
                                            )}
                                        </ToggleButtonGroup>
                                    </Grid>
                                    {values.repeat_type === 'weekly' ? (
                                        <Grid item xs={12}>
                                            <FormHelperText>
                                                {t('pleaseChooseAtLeastOne')}
                                            </FormHelperText>
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value='monthly'
                                            data-test='repeat-monthly'
                                            control={<Radio color='primary' />}
                                            label={t('certainDaysOfMonth')}
                                            onClick={() =>
                                                monthlyRef.current.focus()
                                            }
                                        />
                                        <TextField
                                            inputRef={monthlyRef}
                                            variant='outlined'
                                            data-test='repeat-monthly-input'
                                            size='small'
                                            name='monthlyStr'
                                            onKeyDown={handleKeyDown}
                                            onFocus={() =>
                                                setFieldValue(
                                                    'repeat_type',
                                                    'monthly'
                                                )
                                            }
                                            value={values.monthlyStr}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    {values.repeat_type === 'monthly' ? (
                                        <Grid item xs={12}>
                                            <FormHelperText>
                                                {t(
                                                    'atLeastOneNumberFrom1To31Comma'
                                                )}
                                            </FormHelperText>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </RadioGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleSubmit}
                                color='primary'
                                variant='contained'
                                disabled={isSubmitting || errors.source}
                                data-test='save-btn'
                            >
                                {t('save')}
                            </Button>
                            <Button
                                variant='text'
                                onClick={handleClose}
                                disabled={isSubmitting}
                                data-test='cancel-btn'
                            >
                                {t('cancel')}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    )
}

export default ChangeTaskRepeatModal
