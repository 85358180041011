import {
    Button,
    ButtonGroup,
    IconButton,
    TableCell,
    TableRow,
} from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/Archive'
import BookmarkIcon from '@material-ui/icons/Bookmark'
// import BookmarkIcon from '@material-ui/icons/Star'
// import BookmarkBorderIcon from '@material-ui/icons/StarOutline'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import TocIcon from '@material-ui/icons/Toc'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from 'src/stores/useLanguage'

const ProjectListRow = (props) => {
    const { project, open, onEdit, onDelete, updateProject } = props
    const [expanded, setExpanded] = useState(open)
    const [showArchived, setShowArchived] = useState(false)

    const { t } = useLanguage()

    const navigate = useNavigate()

    const toggleExpanded = (e) => {
        e.stopPropagation()
        // e.preventDefault()
        setExpanded((state) => !state)
    }

    let expandSymbol = null
    if (project.subProjects.length > 0) {
        expandSymbol = expanded ? (
            <IconButton
                onClick={toggleExpanded}
                size='small'
                data-test='toggle-expand-project'
                style={{
                    marginTop: -2,
                    // marginBottom: -6,
                    marginLeft: -34,
                }}
            >
                <ExpandMoreIcon />
            </IconButton>
        ) : (
            <IconButton
                onClick={toggleExpanded}
                size='small'
                data-test='toggle-expand-project'
                style={{
                    marginTop: -2,
                    // marginBottom: -6,
                    marginLeft: -34,
                }}
            >
                <ChevronRightIcon />
            </IconButton>
        )
    }

    // const rowStyle = project.subProjects.length > 0 ? { cursor: 'pointer' } : {}

    const cellStyle = {
        paddingLeft: 40 + project.depth * 20,
        cursor: 'pointer',
        paddingTop: 8,
    }
    if (project.depth === 0) {
        cellStyle.fontWeight = 'bold'
    }

    const toggleProjectStatus = async (project) => {
        await updateProject(project._id, {
            status: project.status === 'active' ? 'archived' : 'active',
        })
    }

    const toggleProjectPinnedStatus = async (project) => {
        await updateProject(project._id, {
            favorite: !project.favorite,
        })
    }

    const activeSubProjects = project.subProjects.filter(
        (project) => project.status === 'active'
    )
    const archivedSubProjects = project.subProjects.filter(
        (project) => project.status === 'archived'
    )

    return (
        <>
            <TableRow
                hover
                // onClick={() => setExpanded((state) => !state)}

                data-test='project-card'
            >
                {/* <TableCell>{expandSymbol}</TableCell> */}
                <TableCell
                    style={cellStyle}
                    width='100%'
                    data-test='project-card-text'
                    onClick={(e) => {
                        e.stopPropagation()
                        navigate('/app/projects/' + project._id + '/tasks')
                    }}
                >
                    {expandSymbol} {project?.name}
                </TableCell>
                <TableCell>
                    <ButtonGroup size='small'>
                        {/* <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                navigate(
                                    '/app/projects/' + project._id + '/tasks'
                                )
                            }}
                            data-test='open-project-btn'
                        >
                            <TocIcon fontSize='small' />
                        </Button> */}
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleProjectPinnedStatus(project)
                            }}
                        >
                            {project.favorite ? (
                                <BookmarkIcon
                                    fontSize='small'
                                    data-test='pin-off-btn'
                                />
                            ) : (
                                <BookmarkBorderIcon
                                    fontSize='small'
                                    data-test='pin-on-btn'
                                />
                            )}
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                onEdit(project)
                            }}
                            data-test='edit-project-btn'
                        >
                            <EditIcon fontSize='small' />
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleProjectStatus(project)
                            }}
                        >
                            {project.status === 'active' ? (
                                <ArchiveIcon fontSize='small' />
                            ) : (
                                <UnarchiveIcon fontSize='small' />
                            )}
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                if (
                                    window.confirm(
                                        t('alertConfirmDeleteProject', {
                                            name: project.name,
                                        })
                                    )
                                ) {
                                    onDelete(project._id)
                                }
                            }}
                            data-test='delete-btn'
                        >
                            <DeleteForeverIcon
                                fontSize='small'
                                color='secondary'
                            />
                        </Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
            {expanded && [
                activeSubProjects.map((project, index) => (
                    <ProjectListRow
                        project={project}
                        key={index}
                        onEdit={onEdit}
                        open={false}
                        updateProject={updateProject}
                        onDelete={onDelete}
                    />
                )),
                archivedSubProjects.length > 0 && (
                    <TableRow hover>
                        <TableCell
                            onClick={() => setShowArchived((state) => !state)}
                            align='center'
                            colSpan='2'
                            style={{ cursor: 'pointer' }}
                        >
                            {showArchived ? t('hideArchive') : t('showArchive')}{' '}
                            ({archivedSubProjects.length})
                        </TableCell>
                    </TableRow>
                ),
                archivedSubProjects.length > 0 &&
                    showArchived &&
                    archivedSubProjects.map((project, index) => (
                        <ProjectListRow
                            project={project}
                            key={index}
                            onEdit={onEdit}
                            open={false}
                            onDelete={onDelete}
                            updateProject={updateProject}
                        />
                    )),
            ]}
        </>
    )
}

export default ProjectListRow
