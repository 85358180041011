import { Menu, MenuItem } from '@material-ui/core'
import { useState } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import type { TimePreset } from 'src/typing'
import ActiveText from '../ActiveText'

const presetOptions: TimePreset[] = [
    'today',
    'last_7_days',
    'current_month_to_date',
    'current_week_to_date',
]

interface TimePresetDropdownProps {
    onChange: (change: TimePreset) => void
    value: TimePreset | undefined
}

const TimePresetDropdown = (props: TimePresetDropdownProps) => {
    const { onChange, value } = props
    const { t } = useLanguage()
    const [presetAnchorEl, setPresetAnchorEl] = useState<null | HTMLElement>(
        null
    )

    return (
        <>
            {/* <Grid container xs={12} justifyContent='flex-end'>
                <Grid item> */}
            <ActiveText
                text={t(value || 'noPreset')}
                onClick={(e) => setPresetAnchorEl(e.currentTarget)}
            />
            <Menu
                open={Boolean(presetAnchorEl)}
                anchorEl={presetAnchorEl}
                onClose={() => {
                    setPresetAnchorEl(null)
                }}
                variant='selectedMenu'
            >
                {presetOptions.map((item) => (
                    <MenuItem
                        dense
                        key={item}
                        selected={item === value}
                        onClick={() => {
                            onChange(item)
                            setPresetAnchorEl(null)
                        }}
                    >
                        {t(item)}
                    </MenuItem>
                ))}
            </Menu>
            {/* </Grid>
            </Grid> */}
        </>
    )
}

export default TimePresetDropdown
