import {
    Button,
    ButtonGroup,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import NavigateNext from '@material-ui/icons/NavigateNext'
import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    differenceInCalendarDays,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    isSameDay,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
} from 'date-fns'
import { useEffect, useState } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import DatePickerText from '../DatePickerText'

// Types

interface PeriodPickerProps {
    dateFrom: Date
    dateTo: Date
    onChangeDateFrom: (a: Date | null) => {}
    onChangeDateTo: (b: Date | null) => {}
}

type Mode = 'today' | 'currentWeek' | 'currentMonth' | 'currentYear' | 'custom'

// Component

const PeriodPicker = (props: PeriodPickerProps) => {
    const { dateFrom, dateTo, onChangeDateFrom, onChangeDateTo } = props

    const [mode, setMode] = useState<Mode>('today')

    const { shortDateFormat, getDateLocale, t } = useLanguage()
    const locale = getDateLocale()

    const today = startOfDay(new Date())

    const localStartOfWeek = (date: Date) => {
        return startOfWeek(date, { locale })
    }

    const localEndOfWeek = (date: Date) => {
        return endOfWeek(date, { locale })
    }

    const currentWeek = {
        start: localStartOfWeek(today),
        end: localEndOfWeek(today),
    }
    const currentMonth = { start: startOfMonth(today), end: endOfMonth(today) }
    const currentYear = { start: startOfYear(today), end: endOfYear(today) }

    useEffect(() => {
        setMode(identifyMode())
    }, [dateFrom, dateTo])

    const identifyMode = () => {
        if (isSameDay(dateFrom, today) && isSameDay(dateTo, today)) {
            return 'today'
        }
        if (
            isSameDay(dateFrom, currentWeek.start) &&
            isSameDay(dateTo, currentWeek.end)
        ) {
            return 'currentWeek'
        }
        if (
            isSameDay(dateFrom, currentMonth.start) &&
            isSameDay(dateTo, currentMonth.end)
        ) {
            return 'currentMonth'
        }
        if (
            isSameDay(dateFrom, currentYear.start) &&
            isSameDay(dateTo, currentYear.end)
        ) {
            return 'currentYear'
        }
        return 'custom'
    }

    const changePeriod = (change: number) => {
        const mode = identifyMode()

        if (
            isSameDay(dateFrom, localStartOfWeek(dateFrom)) &&
            isSameDay(dateTo, localEndOfWeek(dateTo))
        ) {
            onChangeDateFrom(addWeeks(dateFrom, change))
            onChangeDateTo(addWeeks(dateTo, change))
            return
        }

        // Year check must be before month otherwise it will be hijacked by month
        if (
            isSameDay(dateFrom, startOfYear(dateFrom)) &&
            isSameDay(dateTo, endOfYear(dateTo))
        ) {
            onChangeDateFrom(addYears(dateFrom, change))
            onChangeDateTo(addYears(dateTo, change))
            return
        }

        if (
            isSameDay(dateFrom, startOfMonth(dateFrom)) &&
            isSameDay(dateTo, endOfMonth(dateTo))
        ) {
            onChangeDateFrom(startOfMonth(addMonths(dateFrom, change)))
            onChangeDateTo(endOfMonth(addMonths(dateTo, change)))
            return
        }

        const step = differenceInCalendarDays(dateTo, dateFrom) + 1

        onChangeDateFrom(addDays(dateFrom, change * step))
        onChangeDateTo(addDays(dateTo, change * step))
    }

    const differenceInDays = differenceInCalendarDays(dateTo, dateFrom) + 1

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ButtonGroup variant='contained' disableElevation size='small'>
                    <Button
                        onClick={() => {
                            onChangeDateFrom(today)
                            onChangeDateTo(today)
                        }}
                        color={mode === 'today' ? 'primary' : undefined}
                    >
                        {t('today')}
                    </Button>
                    <Button
                        onClick={() => {
                            onChangeDateFrom(currentWeek.start)
                            onChangeDateTo(currentWeek.end)
                        }}
                        color={mode === 'currentWeek' ? 'primary' : undefined}
                    >
                        {t('week')}
                    </Button>
                    <Button
                        onClick={() => {
                            onChangeDateFrom(currentMonth.start)
                            onChangeDateTo(currentMonth.end)
                        }}
                        color={mode === 'currentMonth' ? 'primary' : undefined}
                    >
                        {t('month')}
                    </Button>
                    <Button
                        onClick={() => {
                            onChangeDateFrom(currentYear.start)
                            onChangeDateTo(currentYear.end)
                        }}
                        color={mode === 'currentYear' ? 'primary' : undefined}
                    >
                        {t('year')}
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    alignItems='center'
                    // style={{ marginLeft: '-30px', marginRight: '-20px' }}
                >
                    <Grid item>
                        <IconButton
                            size='small'
                            onClick={() => changePeriod(-1)}
                        >
                            <NavigateBefore />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {/* {t('timeStatsFrom') + ' '} */}
                            <DatePickerText
                                handleChange={onChangeDateFrom}
                                text={format(dateFrom, shortDateFormat)}
                                maxDate={dateTo}
                                value={dateFrom}
                                locale={locale}
                            />
                            {' – '}
                            <DatePickerText
                                handleChange={onChangeDateTo}
                                text={format(dateTo, shortDateFormat)}
                                minDate={dateFrom}
                                value={dateTo}
                                locale={locale}
                            />{' '}
                            (
                            {differenceInDays +
                                ' ' +
                                (differenceInDays === 1
                                    ? t('abbDay')
                                    : t('abbDays'))}
                            )
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            size='small'
                            onClick={() => changePeriod(1)}
                        >
                            <NavigateNext />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PeriodPicker
