import { apiCall } from './base'

const apiStartTimer = async (taskId) => {
    return await apiCall({
        url: '/api/v1/timer/' + taskId + '/',
        method: 'POST',
    })
}

const apiStopTimer = async () => {
    return await apiCall({
        url: '/api/v1/timer/running/',
        method: 'DELETE',
    })
}

const apiFetchRunningTask = async () => {
    return await apiCall({
        url: '/api/v1/timer/running/',
        method: 'GET',
    })
}

export { apiStartTimer, apiStopTimer, apiFetchRunningTask }
