import { PriorityMatchingRules } from "../typing";

const rulesEN: PriorityMatchingRules = {
    '!!': 2,
    p2: 2,
    p1: 1,
    ' !': 1,
    p0: 0,
    'p-1': -1,
    'p-2': -2,
}

export default rulesEN