import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useLanguage } from 'src/stores/useLanguage'

interface DatePickerTextProps {
    value: Date | null
    handleChange: (date: Date | null) => {}
    minDate?: null | Date
    maxDate?: null | Date
    text: string
    locale: any
}

const DatePickerText = (props: DatePickerTextProps) => {
    const { value, handleChange, minDate, maxDate, text, locale } = props
    const { t, shortDateFormat } = useLanguage()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <DatePicker
                clearable
                clearLabel={t('noDate')}
                okLabel=''
                cancelLabel=''
                showTodayButton
                todayLabel={t('today')}
                autoOk
                minDate={minDate}
                maxDate={maxDate}
                format={shortDateFormat}
                value={value}
                onChange={(date) => handleChange(date)}
                TextFieldComponent={(props) => (
                    <span
                        style={{
                            borderBottom: '1px dotted #000',
                            textDecoration: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={props.onClick}
                    >
                        {text}
                    </span>
                )}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DatePickerText
