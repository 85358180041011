import { useState } from 'react'

const usePagination = (pageSize = 5) => {
    const [limit, setLimit] = useState(pageSize)
    const [page, setPage] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    const paginate = (items) => {
        return items.slice(page * limit, (page + 1) * limit)
    }

    return {
        limit,
        setLimit,
        page,
        setPage,
        totalCount,
        setTotalCount,
        paginate,
    }
}

export default usePagination
