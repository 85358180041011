import { useContext } from 'react'
import { StableRouterContext } from 'src/stores/routerStore'

const useStableNavigate = () => {
    const navigate = useContext(StableRouterContext)
    if (navigate === null)
        throw new Error('StableNavigate context is not initialized')

    return navigate
}

export default useStableNavigate
