import { apiCall } from './base'

const apiFetchTaskStats = (dateToken: number) => {
    return apiCall({
        method: 'GET',
        url: '/api/v1/stats/tasks/',
        params: { date_token: dateToken },
    })
}

interface apiFetchPeriodStatsArgs {
    date_from?: number
    date_to?: number
    time_from?: Date
    time_to?: Date
}

const apiFetchPeriodStats = (params: apiFetchPeriodStatsArgs) => {
    return apiCall({
        method: 'GET',
        url: '/api/v1/stats/period/',
        params,
    })
}

export { apiFetchTaskStats, apiFetchPeriodStats }
