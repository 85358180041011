import { useEffect, useState } from 'react'

// Inspired with: https://usehooks-ts.com/react-hook/use-debounce
const useDebounce = <T>(value: T, delay = 500): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay)

        return () => clearTimeout(timer)
    }, [value, delay])

    return debouncedValue
}

export default useDebounce
