import { Button, ButtonGroup } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { formatISO, subSeconds } from 'date-fns'
import { useState } from 'react'
import useModals from 'src/stores/useModals'
import useTasksActions from 'src/stores/useTasksActions'
import useTimerActions from 'src/stores/useTimerActions'
import { formatDurationFromSeconds, getRunningTimer } from 'src/utils'
import AddManualTimeForm from './AddManualTimeForm'
import RunningTimer from './RunningTimer'

const TaskTimer = (props) => {
    const { task, isRunning } = props
    const { startTimer, stopTimer } = useTimerActions()
    const { createTaskTimeRecord } = useTasksActions()
    const { openTaskTimeRecordsModal } = useModals()

    const [manualMode, setManualMode] = useState(false)

    const totalRecordedTime = task.time_records.reduce(
        (total, timeRecord) => total + timeRecord.duration,
        0
    )
    const formattedTime = formatDurationFromSeconds(totalRecordedTime, false)

    const handleStart = async () => {
        await startTimer(task._id)
    }

    const handleStop = async () => {
        await stopTimer(task._id)
    }

    const handleOpenModal = () => {
        openTaskTimeRecordsModal(task)
    }

    const handleSubmitManualTimeRecord = async (duration) => {
        if (duration > 0) {
            const record = {
                start_dt: formatISO(subSeconds(new Date(), duration)),
                duration,
            }
            await createTaskTimeRecord(task._id, record)
        }
    }

    if (!isRunning && manualMode)
        return (
            <AddManualTimeForm
                handleClose={() => setManualMode(false)}
                handleSubmit={handleSubmitManualTimeRecord}
            />
        )

    if (!isRunning && !manualMode)
        return (
            <ButtonGroup size='small' style={{ width: '140px' }}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleStart}
                    data-test='start-timer-btn'
                >
                    <PlayArrowIcon />
                </Button>
                <Button
                    onClick={handleOpenModal}
                    variant='outlined'
                    data-test='task-time'
                >
                    {formattedTime}
                </Button>
                <Button
                    variant='outlined'
                    onClick={() => setManualMode(true)}
                    data-test='add-time-btn'
                >
                    <AddIcon />
                </Button>
            </ButtonGroup>
        )

    if (isRunning)
        return (
            <RunningTimer
                handleStop={handleStop}
                duration={totalRecordedTime}
                timer={getRunningTimer(task)}
            />
        )
}

export default TaskTimer
