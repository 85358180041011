import DateFnsUtils from '@date-io/date-fns'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { addSeconds, format, formatISO, startOfDay } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import { ActiveText } from 'src/components/TaskDateInfo'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import useUser from 'src/stores/useUser'
import { Task, TimeRecord } from 'src/typing'
import { formatDurationFromSeconds } from 'src/utils'

interface TimeRecordModalProps {
    handleClose: () => void
    handleSave: () => void
    task: Task
    timeRecord: TimeRecord
}

const timeStringToSeconds = (timeString: string): number | null => {
    const parts = timeString.split(':')
    const hours = parseInt(parts[0])
    let minutes = parseInt(parts[1])
    if (!isNaN(hours) && !isNaN(minutes)) {
        if (parts[1][1] === '_') minutes *= 10
        return hours * 3600 + minutes * 60
    }
    return null
}

const truncate = (str: string, n: number): string => {
    return str.length > n ? str.substring(0, n - 1) + '...' : str
}

const isValidTimeRecord = (timeFrom: string, timeTo: string): boolean => {
    let secondsFrom = timeStringToSeconds(timeFrom)
    let secondsTo = timeStringToSeconds(timeTo)
    return (
        secondsFrom !== null &&
        secondsTo !== null &&
        secondsFrom <= 86400 &&
        secondsTo <= 86400
    )
}

const getDurationString = (timeFrom: string, timeTo: string): string => {
    let durationString = '__:__'
    let secondsFrom = timeStringToSeconds(timeFrom)
    let secondsTo = timeStringToSeconds(timeTo)
    if (isValidTimeRecord(timeFrom, timeTo)) {
        let seconds = secondsTo - secondsFrom
        if (seconds <= 0) {
            seconds = 86400 + seconds
        }
        durationString = formatDurationFromSeconds(seconds, false)
    }

    return durationString
}

const isNextDay = (timeFrom: string, timeTo: string): boolean => {
    let secondsFrom = timeStringToSeconds(timeFrom)
    let secondsTo = timeStringToSeconds(timeTo)
    if (isValidTimeRecord(timeFrom, timeTo) && secondsFrom >= secondsTo)
        return true
    return false
}

const TimeRecordModal = (props: TimeRecordModalProps) => {
    const [startDate, setStartDate] = useState(new Date())
    const [timeFrom, setTimeFrom] = useState('')
    const [timeTo, setTimeTo] = useState('')
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const timeFromRef = useRef()
    const timeToRef = useRef()
    const { handleClose, task, timeRecord, handleSave } = props
    const { t, getDateLocale } = useLanguage()
    const { openTaskTimeRecordsModal } = useModals()

    const locale = getDateLocale()

    useEffect(() => {
        let secondsFrom = timeStringToSeconds(timeFrom)
        if (
            !timeFrom.includes('_') &&
            secondsFrom !== null &&
            secondsFrom <= 86400 &&
            timeFromRef.current &&
            timeFromRef.current.selectionStart === 5
        )
            timeToRef.current.focus()
    }, [timeFrom])

    const handleSubmit = async () => {
        let secondsFrom = timeStringToSeconds(timeFrom)
        let secondsTo = timeStringToSeconds(timeTo)
        if (isValidTimeRecord(timeFrom, timeTo)) {
            let duration = secondsTo - secondsFrom
            if (duration <= 0) duration = 86400 + duration
            const record = {
                start_dt: formatISO(
                    addSeconds(startOfDay(startDate), secondsFrom)
                ),
                duration: duration,
            }
            await handleSave(record)
            openTaskTimeRecordsModal(task)
            // console.log(record)
        }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.keyCode === 13) handleSubmit()
    }

    const { userState } = useUser()

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            data-test='modal-time-record'
            transitionDuration={0}
            PaperProps={{ style: { maxWidth: '335px' } }}
        >
            <DialogTitle>{t('addTime')}</DialogTitle>
            <DialogContent>
                <Typography
                    style={{
                        marginBottom: '16px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                    }}
                >
                    {truncate(task.text, 50)}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                    <Grid
                        container
                        // xs={12}
                        // spacing={2}
                        justifyContent='flex-end'
                    >
                        <Grid item xs={12} style={{ marginBottom: 12 }}>
                            <Typography noWrap>
                                {t('date') + ': '}
                                <ActiveText
                                    text={format(
                                        startDate,
                                        userState.shortDateFormat
                                    )}
                                    onClick={() => setIsDatePickerOpen(true)}
                                    style={{
                                        display: 'inline',
                                        fontSize: '16px',
                                    }}
                                    data-test='choose-date'
                                />
                            </Typography>
                            <DatePicker
                                TextFieldComponent={() => null}
                                open={isDatePickerOpen}
                                onClose={() => setIsDatePickerOpen(false)}
                                value={startDate}
                                onChange={setStartDate}
                                autoOk
                                data-test='choose-date-popup'
                                cancelLabel={t('cancel')}
                                showTodayButton
                                todayLabel={t('today')}
                                format={userState.shortDateFormat}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 10 }}>
                            <Grid
                                container
                                spacing={1}
                                // justifyContent='space-between'
                                alignItems='center'
                            >
                                <Grid item>
                                    <Typography align='center'>
                                        {t('from')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <InputMask
                                        mask='29:59'
                                        value={timeFrom}
                                        onChange={(e) =>
                                            setTimeFrom(e.target.value)
                                        }
                                        formatChars={{
                                            '9': '[0-9]',
                                            '5': '[0-5]',
                                            '2': '[0-2]',
                                        }}
                                        maskChar='_'
                                    >
                                        {(inputProps) => (
                                            <TextField
                                                {...inputProps}
                                                inputProps={{
                                                    style: {
                                                        paddingTop: '9px',
                                                        paddingBottom: '8px',
                                                        textAlign: 'center',
                                                    },
                                                    inputMode: 'numeric',
                                                }}
                                                inputRef={timeFromRef}
                                                autoFocus
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={
                                                    timeStringToSeconds(
                                                        timeFrom
                                                    ) > 86400
                                                }
                                                size='small'
                                                margin='none'
                                                variant='outlined'
                                                label={t('hh:mm')}
                                                style={{
                                                    width: '70px',
                                                }}
                                                data-test='time-from'
                                                onKeyDown={handleKeyDown}
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item>
                                    <Typography align='center'>
                                        {t('to')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <InputMask
                                        mask='29:59'
                                        value={timeTo}
                                        onChange={(e) =>
                                            setTimeTo(e.target.value)
                                        }
                                        maskChar='_'
                                        formatChars={{
                                            '9': '[0-9]',
                                            '5': '[0-5]',
                                            '2': '[0-2]',
                                        }}
                                        // onKeyDown={handleKeyDown}
                                    >
                                        {(inputProps) => (
                                            <TextField
                                                {...inputProps}
                                                inputRef={timeToRef}
                                                inputProps={{
                                                    style: {
                                                        paddingTop: '9px',
                                                        paddingBottom: '8px',
                                                        textAlign: 'center',
                                                    },
                                                    inputMode: 'numeric',
                                                }}
                                                error={
                                                    timeStringToSeconds(
                                                        timeTo
                                                    ) > 86400
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size='small'
                                                margin='none'
                                                variant='outlined'
                                                label={t('hh:mm')}
                                                style={{
                                                    width: '70px',

                                                    // paddingTop: '1px',
                                                }}
                                                data-test='time-to'
                                                onKeyDown={handleKeyDown}
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        align='center'
                                        color='textSecondary'
                                    >
                                        {isNextDay(timeFrom, timeTo)
                                            ? t('nextDay')
                                            : null}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography data-test='duration'>
                                {t('duration') +
                                    ': ' +
                                    getDurationString(timeFrom, timeTo)}
                            </Typography>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => openTaskTimeRecordsModal(task)}
                    variant='outlined'
                    data-test='back-btn'
                >
                    {t('back')}
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={!isValidTimeRecord(timeFrom, timeTo)}
                    data-test='submit-btn'
                >
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TimeRecordModal
