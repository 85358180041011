import { apiCall } from './base'

const apiFetchUserSelf = async () => {
    return await apiCall({
        method: 'GET',
        url: '/api/v1/users/me/',
    })
}

const apiUpdateUser = async (changes) => {
    return await apiCall({
        url: '/api/v1/users/me/',
        method: 'PUT',
        data: changes,
    })
}

export { apiFetchUserSelf, apiUpdateUser }
