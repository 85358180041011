import {
    AppBar,
    Box,
    Button,
    // ButtonGroup,
    Grid,
    Hidden,
    makeStyles,
    // Menu,
    // MenuItem,
    Toolbar,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        padding: theme.spacing(3),
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
}))

const GuestLayout = () => {
    const classes = useStyles()
    const { updateUser } = useUser()

    // const [anchorEl, setAnchorEl] = useState(null)

    // const handleMenuClick = (e) => {
    //     setAnchorEl(e.currentTarget)
    // }

    // const handleMenuClose = () => {
    //     setAnchorEl(null)
    // }

    // console.log(userState)

    const handleChangeLanguage = (language) => {
        localStorage.setItem('todox-language', language)
        updateUser({ language })
    }

    useEffect(() => {
        let language = 'en' // localStorage.getItem('todox-language')
        if (['en', 'ru'].includes(language)) handleChangeLanguage(language)
    }, [])

    const { t } = useLanguage()

    const navigate = useNavigate()

    return (
        <div className={classes.root}>
            <AppBar position='fixed' className={classes.appBar} elevation={2}>
                <Toolbar style={{ height: '56px', minHeight: '56px' }}>
                    <Box width={800} mx='auto'>
                        <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            spacing={1}
                        >
                            <Grid item style={{ flexGrow: 1 }}>
                                <img
                                    src='/todox-logo.png'
                                    width={120}
                                    style={{
                                        marginTop: '5px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        window.open(
                                            'https://todox.online?utm_source=app&utm_medium=guest_view_logo',
                                            '_blank'
                                        )
                                    }}
                                />
                            </Grid>

                            {/* <Grid item>
                                <Button
                                    endIcon={<ArrowDropDownIcon />}
                                    style={{ color: '#fff' }}
                                    onClick={handleMenuClick}
                                    data-test='guest-view-change-language-btn'
                                    // disableFocusRipple
                                >
                                    {userState.language === 'en'
                                        ? 'English'
                                        : 'Русский'}
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    variant='menu'
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleChangeLanguage('en')
                                            handleMenuClose()
                                        }}
                                        data-test='guest-view-choose-english-btn'
                                    >
                                        English
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleChangeLanguage('ru')
                                            handleMenuClose()
                                        }}
                                        data-test='guest-view-choose-russian-btn'
                                    >
                                        Русский
                                    </MenuItem>
                                </Menu>
                            </Grid> */}
                            <Grid item>
                                <Hidden xsDown>
                                    <Button
                                        onClick={() => navigate('/login')}
                                        variant='text'
                                        style={{
                                            color: '#fff',
                                            // borderColor: '#e0e0e0',
                                            // marginRight: '15px',
                                        }}
                                        data-test='guest-view-sign-in-ref'
                                        disableRipple={false}
                                    >
                                        {t('login')}
                                    </Button>
                                </Hidden>
                                <Hidden smUp>
                                    <Button
                                        onClick={() => navigate('/login')}
                                        variant='text'
                                        style={{
                                            color: '#fff',
                                            wordWrap: '',
                                            // borderColor: '#e0e0e0',
                                            // marginRight: '5px',
                                        }}
                                        disableRipple={false}
                                    >
                                        <ExitToAppIcon />
                                    </Button>
                                </Hidden>
                            </Grid>
                            <Grid item>
                                <Hidden xsDown>
                                    <Button
                                        onClick={() => navigate('/register')}
                                        variant='outlined'
                                        style={{
                                            color: '#fff',
                                            borderColor: '#e0e0e0',
                                        }}
                                        data-test='guest-view-sign-up-ref'
                                        disableRipple={false}
                                    >
                                        {t('register')}
                                    </Button>
                                </Hidden>
                                <Hidden smUp>
                                    <Button
                                        onClick={() => navigate('/register')}
                                        variant='outlined'
                                        style={{
                                            color: '#fff',
                                            borderColor: '#e0e0e0',
                                        }}
                                        disableRipple={false}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Box>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <Toolbar />
                <Outlet />
            </div>
        </div>
    )
}

export default GuestLayout
