import { MatchingRules } from '../typing'

const matchingRules: MatchingRules = {
    casualNames: {
        today: 0,
        tomorrow: 1,
        tmr: 1,
        'next week': 7,
        'in a week': 7,
    },
    offsets: {
        day: ['in (\\d+) day[s]?'],
        week: ['in (\\d+) week[s]?'],
        month: ['in (\\d+) month[s]?'],
        year: ['in (\\d+) year[s]?'],
    },
    fullDate: [
        'on\\s(?<year>\\d{4})-(?<month>\\d{2})-(?<day>\\d{2})',
        'on\\s(?<day>\\d{2})\\.(?<month>\\d{2})\\.(?<year>\\d{4})',
        // Must end with space to distinguish from unfinished full year
        'on\\s(?<day>\\d{2})\\.(?<month>\\d{2})\\.(?<year>\\d{2}\\s)',
        'on\\s(?<month>\\d{2})/(?<day>\\d{2})/(?<year>\\d{4})',
    ],
    daysOfWeek: {
        sunday: 0,
        sun: 0,
        monday: 1,
        mon: 1,
        tuesday: 2,
        tue: 2,
        wednesday: 3,
        wed: 3,
        thursday: 4,
        thu: 4,
        friday: 5,
        fri: 5,
        saturday: 6,
        sat: 6,
    },
    monthsNames: {
        january: 0,
        jan: 0,
        'jan.': 0,
        february: 1,
        feb: 1,
        'feb.': 1,
        march: 2,
        mar: 2,
        'mar.': 2,
        april: 3,
        apr: 3,
        'apr.': 3,
        may: 4,
        june: 5,
        jun: 5,
        'jun.': 5,
        july: 6,
        jul: 6,
        'jul.': 6,
        august: 7,
        aug: 7,
        'aug.': 7,
        september: 8,
        sept: 8,
        sep: 8,
        'sept.': 8,
        'sep.': 8,
        october: 9,
        oct: 9,
        'oct.': 9,
        november: 10,
        nov: 10,
        'nov.': 10,
        december: 11,
        dec: 11,
        'dec.': 11,
    },
    prefixes: {
        next: 1,
        this: 0,
        current: 0,
        on: 0,
    },
    shortDateMasks: ['on {day} {month}', 'on {month} {day}'],
}

// this september
// next year, in a year + 12 month - offset month 12
// next month, in a month + 1 month - offset month 1 + login on days

export default matchingRules
