import { DateParseResult, MatchingRules } from './typing'

const longFirstSort = (a: string, b: string): number => b.length - a.length

const getMatchKeysString = (obj: object): string => {
    let keys = Object.keys(obj)
    return keys.sort(longFirstSort).join('|')
}

const getIntFromMatches = (matches: RegExpMatchArray): number | null => {
    let result = null
    for (let i = 2; i < matches.length; i++) {
        if (!isNaN(parseInt(matches[i]))) {
            return parseInt(matches[i])
        }
    }

    return result
}

const addMonths = (date: Date, offset: number): Date => {
    const endOfTargetMonth = new Date(date.getTime())
    endOfTargetMonth.setMonth(date.getMonth() + offset + 1, 0)
    if (endOfTargetMonth.getDate() < date.getDate()) {
        return endOfTargetMonth
    }
    date.setMonth(date.getMonth() + offset)
    return date
}

const joinOffsets = (offsets: string[]): string => {
    offsets.sort(longFirstSort)
    return offsets.join('|')
}

const parseStringForOffsets = (
    text: string,
    offsets: string[]
): { offset: number | null; index: number | undefined } => {
    let offset = null
    let found = text.match(new RegExp(`(${joinOffsets(offsets)})$`))
    if (found !== null) {
        offset = getIntFromMatches(found)
    }
    return { offset, index: found?.index }
}

const parseDate = (
    text: string,
    matchingRules: MatchingRules,
    refDate: Date = new Date()
): DateParseResult => {
    // Fast exit for too short strings
    if (text.length < 3) return { date: null, match: '' }

    const textLower = text.toLowerCase()
    var offset: number | null, found: RegExpMatchArray | null, parseResult
    var matchString = `(${getMatchKeysString(matchingRules.casualNames)})$`
    // console.log(matchString)
    var pattern = new RegExp(matchString)

    // Match casual day names, e.g. "today", "tomorrow", "next week"
    found = textLower.match(pattern)
    if (found !== null && found.length > 0) {
        if (matchingRules.casualNames[found[0]] !== undefined) {
            offset = matchingRules.casualNames[found[0]]
            refDate.setDate(refDate.getDate() + offset)
            return { date: refDate, match: text.slice(found.index) }
        }
    }

    // Match day offsets, e.g. "in X days"
    parseResult = parseStringForOffsets(textLower, matchingRules.offsets.day)
    if (parseResult.offset !== null) {
        refDate.setDate(refDate.getDate() + parseResult.offset)
        return { date: refDate, match: text.slice(parseResult.index) }
    }

    // Match week offsets, e.g. "in X weeks"
    parseResult = parseStringForOffsets(textLower, matchingRules.offsets.week)
    if (parseResult.offset !== null) {
        refDate.setDate(refDate.getDate() + parseResult.offset * 7)
        return { date: refDate, match: text.slice(parseResult.index) }
    }

    // Match month offsets, e.g. "in X months"
    parseResult = parseStringForOffsets(textLower, matchingRules.offsets.month)
    if (parseResult.offset !== null) {
        return {
            date: addMonths(refDate, parseResult.offset),
            match: text.slice(parseResult.index),
        }
    }

    // Year offsets, e.g. "in X years"
    parseResult = parseStringForOffsets(textLower, matchingRules.offsets.year)
    if (parseResult.offset !== null) {
        return {
            date: addMonths(refDate, parseResult.offset * 12),
            match: text.slice(parseResult.index),
        }
    }

    // Match full date, e.g. 2022-01-05
    matchingRules.fullDate.sort(longFirstSort)
    for (let i = 0; i < matchingRules.fullDate.length; i++) {
        matchString = `(${matchingRules.fullDate[i]})$`
        found = textLower.match(new RegExp(matchString))
        if (found?.groups !== undefined) {
            let year = parseInt(found?.groups.year)
            year = year < 100 ? year + 2000 : year
            return {
                date: new Date(
                    year,
                    parseInt(found?.groups.month) - 1,
                    parseInt(found?.groups.day)
                ),
                match: text.slice(found.index),
            }
        }
    }

    // Match days of week
    matchString = `(?<prefix>${getMatchKeysString(
        matchingRules.prefixes
    )})\\s(?<dayOfWeek>${getMatchKeysString(matchingRules.daysOfWeek)})$`
    found = textLower.match(new RegExp(matchString))
    if (found?.groups !== undefined) {
        let prefixOffset = matchingRules.prefixes[found.groups.prefix]
        let dayOfWeekNum = matchingRules.daysOfWeek[found.groups.dayOfWeek]
        let shift = dayOfWeekNum - refDate.getDay()
        if (shift < 0) {
            shift += 7
        }
        refDate.setDate(refDate.getDate() + shift + 7 * prefixOffset)
        return {
            date: refDate,
            match: text.slice(found.index),
        }
    }

    // Match short dates
    let rawMasks = matchingRules.shortDateMasks.sort()
    let daysGroup = '(?<day>\\d{1,2})'
    let monthGroup = `(?<month>${getMatchKeysString(
        matchingRules.monthsNames
    )})`
    for (let i = 0; i < rawMasks.length; i++) {
        let mask = rawMasks[i]
            .replace('{day}', daysGroup)
            .replace('{month}', monthGroup)
        found = textLower.match(new RegExp(mask))
        if (found?.groups !== undefined) {
            let month = matchingRules.monthsNames[found.groups.month]
            let day = parseInt(found.groups.day)
            let yearOffset = 0
            if (refDate.getMonth() > month) yearOffset = 1
            if (refDate.getMonth() == month && refDate.getDate() > day)
                yearOffset = 1
            return {
                date: new Date(refDate.getFullYear() + yearOffset, month, day),
                match: text.slice(found.index),
            }
        }
    }

    return { date: null, match: '' }
}

export default parseDate
