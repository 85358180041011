import { IconButton, Menu, MenuItem, withStyles } from '@material-ui/core'
import { ReactNode, useState } from 'react'
import {
    MOnePriorityIcon,
    MTwoPriorityIcon,
    POnePriorityIcon,
    PTwoPriorityIcon,
    ZeroPriorityIcon,
} from '../Icons'

const PriorityMenuItem = withStyles({
    root: {
        justifyContent: 'center',
    },
})(MenuItem)

const icons: { [key: string]: ReactNode } = {
    '-2': <MTwoPriorityIcon fontSize='small' />,
    '-1': <MOnePriorityIcon fontSize='small' />,
    '0': <ZeroPriorityIcon fontSize='small' />,
    '1': <POnePriorityIcon fontSize='small' />,
    '2': <PTwoPriorityIcon fontSize='small' />,
}

const TaskPriority = (props: any) => {
    const { task, updateTask } = props

    const [priorityAnchorEl, setPriorityAnchorEl] = useState(null)
    const priorities = [2, 1, 0, -1, -2]

    const handlePriorityMenuClick = (e: any) => {
        setPriorityAnchorEl(e.currentTarget)
    }

    const handlePriorityMenuClose = () => {
        setPriorityAnchorEl(null)
    }

    const handleChangeTaskPriority = async (priority: number) => {
        await updateTask(task._id, { priority })
    }

    return (
        <>
            <IconButton
                size='small'
                onClick={handlePriorityMenuClick}
                style={{ marginTop: '-1px' }}
                data-test={`priority${task.priority}`}
            >
                {icons[task.priority]}
            </IconButton>
            {Boolean(priorityAnchorEl) ? (
                <Menu
                    id='simple-menu'
                    anchorEl={priorityAnchorEl}
                    open={Boolean(priorityAnchorEl)}
                    onClose={handlePriorityMenuClose}
                    variant='menu'
                    autoFocus={false}
                    style={{ marginLeft: '-7px' }}
                    data-test='priority-menu'
                >
                    {priorities.map((priority) => (
                        <PriorityMenuItem
                            key={priority}
                            selected={priority === task.priority}
                            onClick={() => {
                                handleChangeTaskPriority(priority)
                                handlePriorityMenuClose()
                            }}
                            dense
                            data-test={`priority${priority}`}
                        >
                            {icons[priority]}
                        </PriorityMenuItem>
                    ))}
                </Menu>
            ) : null}
        </>
    )
}

export default TaskPriority
