import { Grid, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MarkdownRenderer from 'src/components/Common/MarkdownRenderer'
import TaskCard from 'src/components/TaskCard'
import TaskNotes from 'src/components/TaskNotesMD2'
import { useLanguage } from 'src/stores/useLanguage'
import useTasks from 'src/stores/useTasks'
import './styles.css'

const TaskNotesView = () => {
    const { taskId } = useParams()
    const { t } = useLanguage()
    // const [taskState, setTaskState] = useState(null)
    const { getOrFetchTaskById, updateTask, getTaskById, runningTask } =
        useTasks()
    var task = getTaskById(taskId)
    const [isEditMode, setIsEditMode] = useState(false)

    useEffect(() => {
        const fetchTask = async () => {
            await getOrFetchTaskById(taskId)
            // setTaskState(task)
        }
        fetchTask()
    }, [taskId])

    if (task === null) return null

    return (
        <>
            <Grid container>
                <Grid item xs={12} style={{ maxWidth: '870px' }}>
                    <TaskCard
                        task={task}
                        isRunning={task._id === runningTask?._id}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Typography>{t('notes')}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ maxWidth: '870px', paddingTop: '5px' }}
                >
                    {isEditMode ? (
                        <TaskNotes
                            key={task._id}
                            task={task}
                            onCancel={() => setIsEditMode(false)}
                            saveNotes={(value) => {
                                console.log(value)
                                updateTask(task._id, {
                                    notes: value,
                                })
                                setIsEditMode(false)
                            }}
                        />
                    ) : (
                        <div
                            onClick={(e) => {
                                // console.log(e)
                                setIsEditMode(true)
                            }}
                            className='task-notes-container'
                            data-test='task-notes'
                        >
                            <MarkdownRenderer
                                content={task.notes || t('notesPlaceholder')}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default TaskNotesView
