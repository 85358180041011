import { TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import convertLayoutRu from 'convert-layout/ru'
import { useLanguage } from 'src/stores/useLanguage'

const ProjectSelectDropdown = (props) => {
    let {
        projects,
        projectId,
        handleChange,
        disabledOptions = [],
        autoFocus,
        label,
        nullOptionLabel,
    } = props

    const noParentProject = {
        _id: null,
        name: nullOptionLabel,
        parent_id: null,
    }

    const getProjectById = (projects, projectId) => {
        let result = noParentProject
        projects.forEach((project) => {
            if (project._id === projectId) result = project
        })

        return result
    }

    const { t } = useLanguage()

    const getProjectLine = (option) => {
        const projectsLine = [option.name]
        if (option.parent_id !== null) {
            let project = getProjectById(projects, option.parent_id)
            projectsLine.unshift(project.name)
            while (project.parent_id !== null) {
                project = getProjectById(projects, project.parent_id)
                projectsLine.unshift(project.name)
            }
        }
        return projectsLine.join(' / ')
    }

    const defaultFilter = createFilterOptions()

    // Custom filter to allow for keyboard insensitive search
    const filter = (options, { inputValue, getOptionLabel }) => {
        let filteredOptions = defaultFilter(options, {
            inputValue,
            getOptionLabel,
        })
        const ruToEn = convertLayoutRu.toEn(inputValue)
        const enToRu = convertLayoutRu.fromEn(inputValue)
        if (ruToEn !== inputValue) {
            filteredOptions = filteredOptions.concat(
                defaultFilter(options, {
                    inputValue: ruToEn,
                    getOptionLabel,
                })
            )
        }
        if (enToRu !== inputValue) {
            filteredOptions = filteredOptions.concat(
                defaultFilter(options, {
                    inputValue: enToRu,
                    getOptionLabel,
                })
            )
        }
        return filteredOptions
    }

    return (
        <Autocomplete
            filterOptions={filter}
            data-test='project-input'
            options={[
                noParentProject,
                ...projects.filter(
                    (project) =>
                        (project.status === 'active' && project.visible) ||
                        project._id === projectId
                ),
            ]}
            onChange={(e, value) => {
                if (value !== null) {
                    handleChange(value)
                }
            }}
            openOnFocus
            disableClearable
            getOptionDisabled={(option) => disabledOptions.includes(option._id)}
            value={getProjectById(projects, projectId)}
            autoHighlight
            selectOnFocus
            closeText={t('close')}
            openText={t('open')}
            blurOnSelect
            getOptionLabel={(option) => getProjectLine(option)}
            fullWidth
            onFocus={(e) => {
                setTimeout(() => {
                    e.target.setSelectionRange(0, e.target.value.length)
                }, 1)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant='outlined'
                    autoFocus={autoFocus}
                />
            )}
            renderOption={(option, { inputValue }) => {
                let matches = match(getProjectLine(option), inputValue)
                let parts = parse(getProjectLine(option), matches)
                if (parts.length === 1) {
                    const ruToEn = convertLayoutRu.toEn(inputValue)
                    const enToRu = convertLayoutRu.fromEn(inputValue)
                    if (ruToEn !== inputValue) {
                        matches = match(getProjectLine(option), ruToEn)
                        parts = parse(getProjectLine(option), matches)
                    } else if (enToRu !== inputValue) {
                        matches = match(getProjectLine(option), enToRu)
                        parts = parse(getProjectLine(option), matches)
                    }
                }

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                }}
                            >
                                {part.text}
                            </span>
                        ))}
                    </div>
                )
            }}
        />
    )
}

export default ProjectSelectDropdown
