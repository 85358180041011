import { Box, Button, Grid, Link, Typography } from '@material-ui/core'
import { differenceInSeconds, parseISO } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { apiRequestEmailConfirmation } from 'src/api/auth'
import { apiFetchUserSelf } from 'src/api/users'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'

const EmailConfirmationLayout = () => {
    const { userState, updateUser } = useUser()
    const [isDisabled, setIsDisabled] = useState(true)
    const [delay, setDelay] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useLanguage()

    const delayAfterRequest = 60

    const updateCounter = () => {
        let difference = differenceInSeconds(
            new Date(),
            parseISO(userState.confirmationEmailSentAt)
        )
        setDelay(delayAfterRequest - difference)
        if (difference < delayAfterRequest) {
            setTimeout(updateCounter, 1000)
        } else {
            setIsDisabled(false)
            setDelay(0)
        }
    }

    const handleClick = async () => {
        setIsDisabled(true)
        const response = await apiRequestEmailConfirmation()
        if (response.status === 200) {
            enqueueSnackbar(t('nftRequestSent'))
            const response = await apiFetchUserSelf()
            if (response.status === 200) {
                updateUser(response.data.data)
            }
        } else {
            setIsDisabled(false)
            enqueueSnackbar(t('nftServerError'))
        }
    }

    useEffect(() => {
        if (userState.confirmationEmailSentAt === null) {
            setIsDisabled(false)
            return
        }

        let sinceLastRequest = differenceInSeconds(
            new Date(),
            parseISO(userState.confirmationEmailSentAt)
        )
        if (sinceLastRequest >= delayAfterRequest) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)

            setTimeout(() => {
                let sinceLastRequest = differenceInSeconds(
                    new Date(),
                    parseISO(userState.confirmationEmailSentAt)
                )
                if (sinceLastRequest < delayAfterRequest) {
                    updateCounter()
                }
            }, 1000)
        }
    }, [userState.confirmationEmailSentAt])

    // console.log(user)
    return (
        <Box m='auto' maxWidth={500} mt={10} p={2} pr={0}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        {t('confirmEmailToProceed', {
                            email: userState.email,
                        })}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color='primary'
                        disabled={isDisabled}
                        variant='contained'
                        onClick={handleClick}
                    >
                        {delay === 0
                            ? t('requestConfirmationEmail')
                            : t('nextRequestIn', { seconds: delay })}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {t('ifQuestionsContactSupport')}{' '}
                        <Link href='mailto:support@todox.online'>
                            support@todox.online
                        </Link>
                        .
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EmailConfirmationLayout
