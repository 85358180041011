import { useEffect } from 'react'
import TimePresetDropdown from 'src/components/Common/TimePresetDropdown'
import TimeStatsByTask from 'src/components/Common/TimeStatsByTask'
import { useLanguage } from 'src/stores/useLanguage'
import useTasks from 'src/stores/useTasks'
import { presetToDates } from 'src/timePresetUtils'
import type { TimePreset } from 'src/typing'
import { WidgetComponentProps } from '../BaseWidget'

const TimeByTasksWidget = (props: WidgetComponentProps) => {
    var { time_preset: preset, onChange } = props
    const { fetchTasks, tasksStore: tasks } = useTasks()
    const { getDateLocale } = useLanguage()

    const presetResult = presetToDates(preset, getDateLocale())
    var dateFrom: Date | null = null,
        dateTo: Date | null = null

    if (presetResult !== undefined) [dateFrom, dateTo] = presetResult

    useEffect(() => {
        const fetchTasksRequest = async () => {
            if (!dateFrom || !dateTo) return

            const query = {
                time_from: dateFrom.toISOString(),
                time_to: dateTo.toISOString(),
            }

            await fetchTasks(query)
        }
        fetchTasksRequest()
    }, [dateTo && dateTo.toISOString(), dateFrom && dateFrom.toISOString()])

    if (!dateFrom || !dateTo) return null

    return (
        <>
            <TimePresetDropdown
                value={preset}
                onChange={(preset: TimePreset) =>
                    onChange({ time_preset: preset })
                }
            />
            <TimeStatsByTask
                dateFrom={dateFrom}
                dateTo={dateTo}
                tasks={tasks}
                isLoading={false}
            />
        </>
    )
}

export default TimeByTasksWidget
