import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone'
import { useEffect, useState } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import useProjects from 'src/stores/useProjects'
import ProjectListRow from './ProjectListRow'

const ProjectsListView = () => {
    const { projectsTree, fetchProjects, updateProject, deleteProject } =
        useProjects()
    const { openCreateProjectModal, openEditProjectModal } = useModals()
    const { t } = useLanguage()

    const [showArchived, setShowArchived] = useState(false)

    useEffect(() => {
        const loadProjects = async () => await fetchProjects()
        loadProjects()
    }, [])

    const handleEdit = (project) => {
        openEditProjectModal(project)
    }

    const activeProjects = projectsTree.filter(
        (project) => project.status === 'active'
    )
    const archivedProjects = projectsTree.filter(
        (project) => project.status === 'archived'
    )

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                xs={12}
                md={8}
                lg={6}
            >
                <Grid item>
                    <Typography variant='h5' color='primary'>
                        <FolderOpenTwoToneIcon
                            fontSize='large'
                            style={{ marginBottom: -10 }}
                        />{' '}
                        {t('projects')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={openCreateProjectModal}
                        data-test='projects-create-project-btn'
                    >
                        {t('createProject')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container xs={12} md={8} lg={6}>
                <Grid item xs={12}>
                    <Table
                        size='small'
                        data-test='projects-list'
                        style={{ marginTop: '16px' }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>
                                    <Typography>
                                        <b>{t('name')}</b>
                                    </Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>
                                        <b>{t('actions')}</b>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activeProjects.map((project, index) => (
                                <ProjectListRow
                                    project={project}
                                    key={index}
                                    onEdit={handleEdit}
                                    open={false}
                                    onDelete={deleteProject}
                                    updateProject={updateProject}
                                />
                            ))}

                            {archivedProjects.length > 0 && (
                                <TableRow hover>
                                    <TableCell
                                        colSpan='2'
                                        onClick={() =>
                                            setShowArchived((state) => !state)
                                        }
                                        align='center'
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {showArchived
                                            ? t('hideArchive')
                                            : t('showArchive')}{' '}
                                        ({archivedProjects.length})
                                    </TableCell>
                                </TableRow>
                            )}
                            {archivedProjects.length > 0 &&
                                showArchived &&
                                archivedProjects.map((project, index) => (
                                    <ProjectListRow
                                        project={project}
                                        key={index}
                                        onEdit={handleEdit}
                                        open={false}
                                        onDelete={deleteProject}
                                        updateProject={updateProject}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </>
    )
}

export default ProjectsListView
