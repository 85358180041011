import { SvgIcon } from '@material-ui/core'

const MTwoPriorityIcon = (props) => (
    <SvgIcon data-test='icon-p-2' {...props}>
        <path d='M18 6.41 16.59 5 12 9.58 7.41 5 6 6.41l6 6z'></path>
        <path d='m18 13-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z'></path>
    </SvgIcon>
)

const ZeroPriorityIcon = (props) => (
    <SvgIcon data-test='icon-p0' {...props}></SvgIcon>
)

const MOnePriorityIcon = (props) => (
    <SvgIcon data-test='icon-p-1' {...props}>
        <path d='M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'></path>
    </SvgIcon>
)

const POnePriorityIcon = (props) => (
    <SvgIcon data-test='icon-p1' {...props}>
        <path d='M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6z'></path>
    </SvgIcon>
)

const PTwoPriorityIcon = (props) => (
    <SvgIcon data-test='icon-p2' {...props}>
        <path d='M6 17.59 7.41 19 12 14.42 16.59 19 18 17.59l-6-6z'></path>
        <path d='m6 11 1.41 1.41L12 7.83l4.59 4.58L18 11l-6-6z'></path>
    </SvgIcon>
)

export {
    MTwoPriorityIcon,
    ZeroPriorityIcon,
    MOnePriorityIcon,
    POnePriorityIcon,
    PTwoPriorityIcon,
}
