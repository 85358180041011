import { Navigate } from 'react-router-dom'
import EmailConfirmationLayout from './layouts/EmailConfirmationLayout'
import GuestLayout from './layouts/GuestLayout'
import LoadingLayout from './layouts/LoadingLayout'
import MainLayout from './layouts/MainLayout'
import LoginSuccess from './views/AuthViews/LoginSuccess'
import LoginView from './views/AuthViews/LoginView'
import RegisterView from './views/AuthViews/RegisterView'
import RequestRestorePasswordView from './views/AuthViews/RequestRestorePasswordView'
import RestorePasswordView from './views/AuthViews/RestorePasswordView'
import ConfirmEmailView from './views/ConfirmEmailView'
import PinnedProjectsView from './views/ProjectsViews/PinnedProjectsView'
import ProjectsListView from './views/ProjectsViews/ProjectsListView'
import ProjectTasksListView from './views/ProjectsViews/ProjectTasksListView'
import ProjectsTimeStatsView from './views/StatsViews/ProjectsTimeStatsView'
import ReviewView from './views/StatsViews/ReviewView'
import ChronologicalView from './views/TasksViews/ChronologicalView'
import InboxView from './views/TasksViews/InboxView'
import OverdueTasks from './views/TasksViews/OverdueTasks'
import TaskNotesView from './views/TasksViews/TaskNotesView'
import TasksSearchView from './views/TasksViews/TasksSearchView'
import TodayTasksView from './views/TasksViews/TodayView'
import TomorrowTasksView from './views/TasksViews/TomorrowView'
import UserSettingsView from './views/UserViews/UserSettingsView'
import WidgetsPanel from './views/WidgetViews/WidgetsPanel'

type State = 'LOADING' | 'LOGGEDIN' | 'GUEST' | 'EMAIL_CONFIRMATION'

const routes = (state: State) => {
    if (state === 'LOADING') return [{ path: '*', element: <LoadingLayout /> }]

    if (state === 'GUEST')
        return [
            {
                element: <GuestLayout />,
                children: [
                    { path: '*', element: <Navigate to='/register' /> },
                    { path: 'login', element: <LoginView /> },
                    { path: 'register', element: <RegisterView /> },
                    { path: 'login-success', element: <LoginSuccess /> },
                    { path: 'confirm-email', element: <ConfirmEmailView /> },
                    {
                        path: 'request-restore-password',
                        element: <RequestRestorePasswordView />,
                    },
                    {
                        path: 'restore-password',
                        element: <RestorePasswordView />,
                    },
                ],
            },
        ]

    if (state === 'EMAIL_CONFIRMATION')
        return [
            {
                // path: '',
                element: <MainLayout />,
                children: [
                    { path: 'login-success', element: <LoginSuccess /> },
                    { path: '*', element: <EmailConfirmationLayout /> },
                    {
                        path: 'app/user/settings',
                        element: <UserSettingsView />,
                    },
                    { path: 'confirm-email', element: <ConfirmEmailView /> },
                    {
                        path: 'restore-password',
                        element: <RestorePasswordView />,
                    },
                ],
            },
        ]

    if (state === 'LOGGEDIN')
        return [
            { path: '*', element: <Navigate to='/app/tasks/today' /> },
            { path: 'login-success', element: <LoginSuccess /> },
            {
                path: 'app',
                element: <MainLayout />,
                children: [
                    { path: '', element: <Navigate to='/app/tasks/today' /> },

                    {
                        path: 'tasks/inbox',
                        element: <InboxView />,
                    },
                    {
                        path: 'tasks/today',
                        element: <TodayTasksView />,
                    },
                    {
                        path: 'tasks/tomorrow',
                        element: <TomorrowTasksView />,
                    },
                    {
                        path: 'tasks/overdue',
                        element: <OverdueTasks />,
                    },
                    {
                        path: 'tasks/search',
                        element: <TasksSearchView />,
                    },
                    {
                        path: 'tasks/chronology',
                        element: <ChronologicalView />,
                    },
                    {
                        path: 'projects',
                        element: <ProjectsListView />,
                    },
                    {
                        path: 'projects/pinned',
                        element: <PinnedProjectsView />,
                    },
                    {
                        path: 'projects/:projectId/tasks',
                        element: <ProjectTasksListView />,
                    },
                    {
                        path: 'tasks/:taskId/notes',
                        element: <TaskNotesView />,
                    },

                    {
                        path: 'stats/period',
                        element: <ProjectsTimeStatsView />,
                    },
                    {
                        path: 'stats/review',
                        element: <ReviewView />,
                    },
                    {
                        path: 'widgets/',
                        element: <WidgetsPanel />,
                    },
                    {
                        path: 'user/settings',
                        element: <UserSettingsView />,
                    },
                    {
                        path: 'restore-password',
                        element: <RestorePasswordView />,
                    },
                ],
            },
        ]

    return [{ path: '*', element: <LoadingLayout /> }]
}

export default routes
