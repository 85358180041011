import { alpha, InputBase, makeStyles } from '@material-ui/core'
import { useState } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import useTasks from 'src/stores/useTasks'

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(0.75em + ${theme.spacing(0)}px)`,
        width: '100%',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.12),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        // marginTop: 10,
        marginBottom: 10,
        width: '100%',
    },
    searchIcon: {
        padding: theme.spacing(0, 0.5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const AppBarInput = (props) => {
    const { defaults } = props
    const classes = useStyles()
    const [text, setText] = useState('')

    const { t } = useLanguage()

    const { createTask } = useTasks()

    const handleKeyDown = (e) => {
        // Enter triggers submit
        if (e.keyCode === 13) {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit()
        }
        if (e.keyCode === 27) {
            e.preventDefault()
            e.stopPropagation()
            setText('')
        }
    }

    const handleSubmit = async () => {
        const task = { ...defaults, text }
        if (text.startsWith('!!')) {
            task.priority = 2
            task.text = task.text.slice(2).trim()
        } else if (text.startsWith('!')) {
            task.priority = 1
            task.text = task.text.slice(1).trim()
        }
        const result = await createTask(task)
        if (result !== null) {
            setText('')
        }
    }

    return (
        // <TextField
        //     value={text}
        //     onChange={(e) => setText(e.target.value)}
        //     onKeyDown={handleKeyDown}
        //     data-test='create-task-input'
        //     placeholder={t('topBarCreateTask')}
        //     variant='outlined'
        // />
        <div className={classes.search}>
            {/* <div className={classes.searchIcon}>
                <AddIcon />
            </div> */}
            <InputBase
                placeholder={t('topBarCreateTask')}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
                data-test='create-task-input'
            />
        </div>
    )
}

export default AppBarInput
