import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    makeStyles,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { useState } from 'react'
import { useLanguage } from 'src/stores/useLanguage'

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline',
    },
})
const ChangeTaskTextModal = (props) => {
    const { task, handleClose, handleSave } = props
    const [text, setText] = useState(task.text)

    const classes = useStyles()

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const { t } = useLanguage()

    const save = () => {
        handleSave(text)
        handleClose()
    }

    const handleKeyDown = (e) => {
        if (e.keyCode == 13 && e.ctrlKey) {
            save()
        }
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth='xs'
            classes={isSmall ? { scrollPaper: classes.scrollPaper } : {}}
            data-test='modal-task-text'
        >
            <DialogContent>
                <TextField
                    multiline
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    label={t('taskText')}
                    fullWidth
                    rows={3}
                    variant='outlined'
                    autoFocus
                    onKeyDown={handleKeyDown}
                    onFocus={(e) =>
                        e.target.setSelectionRange(
                            e.target.value.length,
                            e.target.value.length
                        )
                    }
                    data-test='task-text-input'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={save} color='primary' variant='contained'>
                    {t('save')}
                </Button>
                <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                >
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangeTaskTextModal
