import LoopIcon from '@material-ui/icons/Loop'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import useUser from 'src/stores/useUser'
import { ActiveText } from '../TaskDateInfo'

const formatTaskRepeatString = (
    repeat,
    t,
    daysOfWeek,
    isMondayFirstDayOfWeek
) => {
    let repeatText = false
    if (repeat !== undefined && repeat !== null) {
        if (repeat.repeat_type === 'daily') {
            repeatText = repeat.daily + ' ' + t('abbDays')
        }

        if (repeat.repeat_type === 'monthly') {
            repeatText = repeat.monthly.join(', ') + ' ' + t('abbDayOfMonth')
        }

        if (repeat.repeat_type === 'weekly') {
            let days = repeat.weekly
                .sort((a, b) => a - b)
                .map((dayNum) => daysOfWeek[dayNum])
            if (!isMondayFirstDayOfWeek && days.includes(daysOfWeek[6])) {
                days = days.filter((item) => item !== daysOfWeek[6])
                days.unshift(daysOfWeek[6])
            }
            repeatText = days.join(', ')
        }
    }
    return repeatText
}

const TaskCardRepeatInfo = (props) => {
    const { task } = props
    const { repeat } = task
    const { userState } = useUser()
    const { openTaskRepeatModal } = useModals()

    const { t, tArray } = useLanguage()
    const daysOfWeek = tArray('daysOfWeek')

    let repeatText = formatTaskRepeatString(
        repeat,
        t,
        daysOfWeek,
        userState.isMondayFirstDayOfWeek
    )

    return repeatText === false ? null : (
        <ActiveText
            onClick={() => {
                openTaskRepeatModal(task)
            }}
            text={repeatText}
            icon={
                <LoopIcon style={{ fontSize: '1rem', marginBottom: '-4px' }} />
            }
            data-test='repeat-settings'
        />
        // <Typography
        //     style={{
        //         borderBottom: '1px dotted #000',
        //         textDecoration: 'none',
        //         cursor: 'pointer',
        //     }}

        // >

        // </Typography>
    )
}

export default TaskCardRepeatInfo
export { formatTaskRepeatString }
