import enLocale from 'date-fns/locale/en-CA'
import ruLocale from 'date-fns/locale/ru'
import { languages, LanguageTranslation } from 'src/i18n/languages'
import useUser from './useUser'

type TranslationKey = keyof LanguageTranslation

type LanguageKey = 'ru' | 'en'

interface TranslateStringFunc {
    (
        key: TranslationKey,
        values?: { [key: string]: string | number },
        language?: LanguageKey
    ): string
}

interface TranslateStringArrayFunc {
    (key: 'daysOfWeek', language?: LanguageKey): string[]
}

const useLanguage = () => {
    // TODO: Move to proper typing
    const { userState } = useUser()

    const getLanguage = () => userState.language

    const getDateLocale = () => {
        let locale = userState.language === 'en' ? enLocale : ruLocale
        //@ts-ignore
        locale.options.weekStartsOn = userState.isMondayFirstDayOfWeek ? 1 : 0
        return locale
    }

    const t: TranslateStringFunc = (
        key,
        values = {},
        language = userState.language
    ): string => {
        let translation: string = languages[language][key]
        if (translation === undefined) return key
        for (const valueKey in values) {
            translation = translation.replace(
                '{' + valueKey + '}',
                values[valueKey].toString()
            )
        }
        return translation
    }

    const tArray: TranslateStringArrayFunc = (
        key,
        language = userState.language
    ) => {
        //@ts-ignore
        return languages[language][key]
    }

    return {
        t,
        tArray,
        getLanguage,
        getDateLocale,
        shortDateFormat: userState.shortDateFormat,
    }
}

export { useLanguage }
