import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './styles.css'

interface MarkdownRendererProps {
    content: string
}

const MarkdownRenderer = ({ content }: MarkdownRendererProps) => {
    return (
        <ReactMarkdown
            className='rendered-markdown'
            linkTarget='_blank'
            children={content}
            components={{
                a: ({ children, ...props }) => {
                    return (
                        <a onClick={(e) => e.stopPropagation()} {...props}>
                            {children}
                        </a>
                    )
                },
            }}
            remarkPlugins={[remarkGfm]}
        />
    )
}

export default MarkdownRenderer
