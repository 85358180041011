import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    InputAdornment,
    InputBase,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import MoreVertOutlined from '@material-ui/icons/MoreVertOutlined'
import { KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import useWidgets from 'src/stores/useWidgets'
import { loadFromLocalStorage } from 'src/utils'
// import './styles.css'

interface WidgetContainerProps {
    widgetId: string
    title: string
    children: ReactNode
    position: number
    widgetsCount: number
    group: string
}

const WidgetContainer = (props: WidgetContainerProps) => {
    const {
        widgetId,
        title: titleProp,
        children,
        position,
        widgetsCount,
        group,
    } = props

    const { updateWidget, deleteWidget, moveWidget } = useWidgets()
    const { t } = useLanguage()

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
    const [isEditingTitle, setIsEditingTitle] = useState(false)
    const [title, setTitle] = useState(titleProp)
    const [isExpanded, setIsExpanded] = useState<boolean>(
        loadFromLocalStorage(`${widgetId}-${group}-is-expanded`, true)
    )

    const canMoveUp = position > 0,
        canMoveDown = position < widgetsCount - 1

    useEffect(() => {
        setTitle(titleProp)
    }, [titleProp])

    const inputRef = useRef<null | HTMLInputElement>(null)

    useEffect(() => {
        if (isEditingTitle && inputRef.current) {
            inputRef.current.focus()
            inputRef.current.select()
        }
    }, [isEditingTitle])

    // console.log(inputRef.current)

    const saveTitle = (title: string) => {
        if (title.trim().length > 0) {
            updateWidget(widgetId, { title })
            setIsEditingTitle(false)
        }
    }

    const handleCancelEdit = () => {
        setIsEditingTitle(false)
        setTitle(titleProp)
    }

    const handleDelete = () => {
        setMenuAnchorEl(null)
        if (window.confirm(t('alertConfirmDeleteWidget'))) {
            deleteWidget(widgetId)
        }
    }

    const handleMoveUp = () => {
        setMenuAnchorEl(null)
        moveWidget(widgetId, -1)
    }

    const handleMoveDown = () => {
        setMenuAnchorEl(null)
        moveWidget(widgetId, 1)
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            e.stopPropagation()
            saveTitle(title)
        }
        if (e.keyCode === 27) {
            e.preventDefault()
            e.stopPropagation()
            handleCancelEdit()
        }
    }

    const handleToggleExpand = () => {
        if (!isEditingTitle) {
            let newState = !isExpanded
            setIsExpanded(newState)
            localStorage.setItem(
                `${widgetId}-${group}-is-expanded`,
                JSON.stringify(newState)
            )
        }
    }

    return (
        <Card variant='outlined'>
            <CardHeader
                style={{
                    padding: '6px 6px 0px 4px',

                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                }}
                disableTypography
                action={
                    <>
                        <IconButton
                            size='small'
                            style={{ margin: 5, marginLeft: 10 }}
                            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                        >
                            <MoreVertOutlined />
                        </IconButton>
                        <Menu
                            open={Boolean(menuAnchorEl)}
                            anchorEl={menuAnchorEl}
                            onClose={() => setMenuAnchorEl(null)}
                            variant='menu'
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transitionDuration={0}
                            onBlur={console.log}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            getContentAnchorEl={null}
                        >
                            {canMoveUp && (
                                <MenuItem dense onClick={handleMoveUp}>
                                    {t('moveUp')}
                                </MenuItem>
                            )}
                            {canMoveDown && (
                                <MenuItem dense onClick={handleMoveDown}>
                                    {t('moveDown')}
                                </MenuItem>
                            )}

                            <MenuItem
                                onClick={(e) => {
                                    setMenuAnchorEl(null)
                                    setIsEditingTitle(true)

                                    if (inputRef.current) {
                                        inputRef.current.focus()
                                    }
                                }}
                                dense
                            >
                                {t('rename')}
                            </MenuItem>
                            <MenuItem dense onClick={handleDelete}>
                                {t('delete')}
                            </MenuItem>
                        </Menu>
                    </>
                }
                title={
                    <>
                        <InputBase
                            fullWidth
                            inputRef={inputRef}
                            value={title}
                            onKeyDown={handleKeyDown}
                            margin='none'
                            onBlur={() => saveTitle(title)}
                            // autoFocus
                            onChange={(e) => setTitle(e.target.value)}
                            style={{
                                marginTop: -8,
                                display: isEditingTitle
                                    ? 'inline-flex'
                                    : 'none',
                            }}
                            inputProps={{
                                style: { paddingLeft: 12 },
                            }}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        size='small'
                                        color='primary'
                                        onClick={() => {
                                            saveTitle(title)
                                        }}
                                    >
                                        <DoneIcon />
                                    </IconButton>
                                    <IconButton
                                        size='small'
                                        color='secondary'
                                        onClick={handleCancelEdit}
                                        style={{ marginRight: -8 }}
                                        // edge='end'
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />

                        <div
                            onClick={handleToggleExpand}
                            style={{
                                cursor: 'pointer',
                                margin: -5,
                                padding: 5,
                                display: !isEditingTitle ? 'block' : 'none',
                            }}
                        >
                            <Typography
                                variant='body1'
                                // onClick={() => setIsEditingTitle(true)}
                                style={{
                                    marginBottom: 8,
                                    marginLeft: 12,
                                    overflowWrap: 'break-word',
                                    wordBreak: 'break-word',
                                    fontWeight: 700,
                                }}
                            >
                                {title}
                            </Typography>
                        </div>
                    </>
                }
            />
            <Collapse in={isExpanded}>
                <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
                    {children}
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default WidgetContainer
export type { WidgetContainerProps }
