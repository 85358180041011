import Axios from 'axios'
import { apiRefresh } from './auth'
import { v4 as uuid } from 'uuid'

const DEVICE_ID = uuid()

Axios.interceptors.response.use(undefined, async (error) => {
    const originalRequest = error.config
    if (
        error.response.status === 401 &&
        originalRequest.url.includes('auth/refresh')
    ) {
        return Promise.reject(error)
    }
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const response = await apiRefresh()
        if (response.status === 200) {
            return Axios(originalRequest)
        }
    }
    return Promise.reject(error)
})

Axios.defaults.xsrfCookieName = 'csrf_access_token'
Axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN'

const apiCall = async (params) => {
    try {
        params.headers = {
            'Device-ID': DEVICE_ID,
        }
        return await Axios.request(params)
    } catch (error) {
        console.error(error)
        return error.response
    }
}

export { apiCall, DEVICE_ID }
