import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const NavLink = ({to, text, ...props }) => {
    return (
        <Link to={to} component={RouterLink} {...props}>
            {text}
        </Link>
    )
}

export default NavLink