import { Breadcrumbs } from '@material-ui/core'
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone'
import { Link } from 'react-router-dom'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import useProjects from 'src/stores/useProjects'
import { ActiveText } from '../TaskDateInfo'

const TaskProjectInfo = (props) => {
    const { task } = props
    const { openTaskProjectModal } = useModals()
    const { projects } = useProjects()
    const { t } = useLanguage()

    const getProjectById = (projects, projectId) => {
        let result = null
        projects.forEach((project) => {
            if (project._id === projectId) result = project
        })

        return result
    }

    const projectsLine = []
    if (task.project_id !== null) {
        let project = getProjectById(projects, task.project_id)
        projectsLine.unshift(project)
        if (project !== null) {
            while (project.parent_id !== null) {
                project = getProjectById(projects, project.parent_id)
                projectsLine.unshift(project)
            }
        }
    }

    return (
        <div
            style={{
                display: 'inline-flex',
            }}
        >
            <FolderOpenTwoToneIcon
                color='disabled'
                fontSize='small'
                style={{
                    marginTop: '2px',
                    marginRight: '6px',
                    cursor: 'pointer',
                }}
                onClick={() => openTaskProjectModal(task)}
                data-test='change-project-btn'
            />
            {projectsLine.length > 0 ? (
                <Breadcrumbs data-test='task-project'>
                    {projectsLine.map((project) => (
                        <Link
                            key={project?._id}
                            to={'/app/projects/' + project?._id + '/tasks'}
                            style={{
                                textDecoration: 'none',
                                fontSize: '14px',
                                color: 'gray',
                            }}
                        >
                            {project?.name}
                        </Link>
                    ))}
                </Breadcrumbs>
            ) : (
                <ActiveText
                    text={t('noProject')}
                    onClick={() => {
                        openTaskProjectModal(task)
                    }}
                    style={{ paddingTop: '2.5px' }}
                    data-test='task-project'
                />
            )}
        </div>
    )
}

export default TaskProjectInfo
