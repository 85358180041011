import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from '@material-ui/core'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import { endOfDay, startOfDay } from 'date-fns'
import { useEffect, useState } from 'react'
import PeriodPicker from 'src/components/Common/PeriodPicker'
import TimeStatsByProject from 'src/components/Common/TimeStatsByProject'
import TimeStatsByTask from 'src/components/Common/TimeStatsByTask'
import RecordedTimeChart from 'src/components/Stats/RecordedTimeChart'
import TotalTasksStatsCard from 'src/components/Stats/TotalTasksStatsCard'
import { useLanguage } from 'src/stores/useLanguage'
import useTasks from 'src/stores/useTasks'
import { Task } from 'src/typing'

const StyledCardHeader = (props: { title: string }) => {
    return (
        <CardHeader
            style={{
                padding: '6px 6px 0px 4px',
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            }}
            disableTypography
            title={
                <Typography
                    variant='body1'
                    style={{
                        marginBottom: 8,
                        marginLeft: 12,
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                        fontWeight: 700,
                    }}
                >
                    {props.title}
                </Typography>
            }
        />
    )
}

interface ReviewViewProps {
    fullWidthCards: boolean
    hideHeading: boolean
}

const ReviewView = ({ fullWidthCards, hideHeading }: ReviewViewProps) => {
    const [tasks, setTasks] = useState<Task[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const { fetchTasks } = useTasks()

    const { t } = useLanguage()

    const today = new Date()

    const [dateFrom, setDateFrom] = useState<Date>(startOfDay(today))
    const [dateTo, setDateTo] = useState<Date>(endOfDay(today))

    useEffect(() => {
        const fetchTasksRequest = async () => {
            setIsLoading(true)
            const query = {
                time_from: dateFrom.toISOString(),
                time_to: dateTo.toISOString(),
            }
            const response = await fetchTasks(query)
            if (response.status === 200) {
                setTasks(response.data.data)
            }
            setIsLoading(false)
        }
        fetchTasksRequest()
    }, [dateTo, dateFrom])

    const cardSizes = fullWidthCards ? { xs: 12 } : { xs: 12, lg: 6, xl: 4 }

    return (
        <Grid container spacing={2}>
            {hideHeading ? null : (
                <Grid item xs={12}>
                    <Typography variant='h5' color='primary'>
                        <EqualizerIcon
                            fontSize='large'
                            style={{ marginBottom: -9 }}
                        />{' '}
                        {t('review')}
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12}>
                <PeriodPicker
                    onChangeDateFrom={(date) => setDateFrom(startOfDay(date))}
                    onChangeDateTo={(date) => setDateTo(endOfDay(date))}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                />
            </Grid>

            <Grid item xs={12} md={6} xl={8}>
                <Card variant='outlined' style={{ height: 420 }}>
                    <StyledCardHeader title={t('totalTimeRecorded')} />
                    <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
                        <RecordedTimeChart
                            timeFrom={dateFrom}
                            timeTo={dateTo}
                            tasks={tasks}
                            isLoading={isLoading}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
                <Card variant='outlined' style={{ height: 420 }}>
                    <StyledCardHeader title={t('generalStats')} />
                    <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
                        <TotalTasksStatsCard
                            timeFrom={dateFrom}
                            timeTo={dateTo}
                            disableMoveUp
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
                <Card variant='outlined'>
                    <StyledCardHeader
                        title={t('recordedTimeByProjectsTitle')}
                    />
                    <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
                        <TimeStatsByProject
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            tasks={tasks}
                            isLoading={isLoading}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
                <Card variant='outlined'>
                    <StyledCardHeader title={t('recordedTimeByTasksTitle')} />
                    <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
                        <Typography style={{ marginBottom: '16px' }}>
                            {t('recordedTimeByTasksDescription')}
                        </Typography>

                        <TimeStatsByTask
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            tasks={tasks}
                            isLoading={isLoading}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default ReviewView
