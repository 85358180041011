import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
    apiRestorePassword,
    apiValidateRestorePasswordToken,
} from 'src/api/auth'
import { useLanguage } from 'src/stores/useLanguage'

const useQuery = () => {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

const RestorePasswordView = () => {
    const [state, setState] = useState('validate')
    const [serverError, setServerError] = useState(null)
    const { t } = useLanguage()

    const query = useQuery()
    const email = query.get('email')
    const token = query.get('token')

    useEffect(() => {
        const validateToken = async () => {
            let response = await apiValidateRestorePasswordToken(email, token)
            console.log(response)
            if (response.status === 200) {
                setState('restore')
            } else {
                setState('error')
            }
        }
        validateToken()
    }, [email, token])

    if (state === 'error')
        return (
            <Box mx='auto' maxWidth={500}>
                <Grid container spacing={1} justifyContent='center'>
                    <Grid item>
                        <Typography variant='h5'>
                            {t('restorePassword')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {t('wrongEmailOrTokenOrStaleTryAgain')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )

    if (state === 'success')
        return (
            <Box mx='auto' maxWidth={500}>
                <Grid container spacing={1} justifyContent='center'>
                    <Grid item>
                        <Typography variant='h5'>
                            {t('restorePassword')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{t('successPasswordChanged')}</Typography>
                    </Grid>
                </Grid>
            </Box>
        )

    if (state === 'restore')
        return (
            <Box mx='auto' maxWidth={500}>
                <Grid container spacing={1} justifyContent='center'>
                    <Grid item>
                        <Typography variant='h5'>
                            {t('restorePassword')}
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={{
                            password: '',
                            passwordConfirm: '',
                        }}
                        validateOnBlur
                        // validateOnChange={false}
                        validate={(values) => {
                            const errors = {}
                            if (!values.password) {
                                errors.password = 'errPasswordCannotBeEmpty'
                            }
                            if (values.password !== values.passwordConfirm) {
                                errors.passwordConfirm =
                                    'errPasswordsDoNotMatch'
                            }
                            if (values.password.length < 8) {
                                errors.password = 'errNewPasswordTooShort'
                            }

                            return errors
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            const response = await apiRestorePassword(
                                email,
                                token,
                                values.password
                            )
                            if (response.status === 200) {
                                setState('success')
                            } else {
                                setState('error')
                            }
                            setSubmitting(false)
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            errors,
                            touched,
                            isSubmitting,
                            handleSubmit,
                        }) => (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('newPassword')}
                                        error={
                                            touched.password && errors.password
                                        }
                                        type='password'
                                        name='password'
                                        value={values.password}
                                        variant='outlined'
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e)
                                            setServerError(null)
                                        }}
                                        helperText={
                                            touched.password &&
                                            t(errors.password)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('newPasswordRepeat')}
                                        error={
                                            touched.passwordConfirm &&
                                            errors.passwordConfirm
                                        }
                                        type='password'
                                        name='passwordConfirm'
                                        value={values.passwordConfirm}
                                        variant='outlined'
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e)
                                            setServerError(null)
                                        }}
                                        helperText={
                                            touched.passwordConfirm &&
                                            t(errors.passwordConfirm)
                                        }
                                    />
                                </Grid>
                                {/* {serverError ? (
                                <Grid item>
                                    <FormHelperText error>
                                        {t(serverError)}
                                    </FormHelperText>
                                </Grid>
                            ) : null} */}
                                <Grid item xs={12}>
                                    <Button
                                        color='primary'
                                        fullWidth
                                        variant='contained'
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        {t('save')}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
            </Box>
        )

    return null
}

export default RestorePasswordView
