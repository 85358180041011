import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import BackspaceIcon from '@material-ui/icons/Backspace'
import SearchIcon from '@material-ui/icons/Search'
import { Skeleton } from '@material-ui/lab'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import TasksList from 'src/components/TasksList'
import useDebounce from 'src/hooks/useDebounce'
import { useLanguage } from 'src/stores/useLanguage'

const TasksSearchView = () => {
    const [queryText, setQueryText] = useState('')
    const { t } = useLanguage()
    const query = useDebounce(queryText, 500)
    const inputRef = useRef<null | HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus()
    }, [inputRef.current])

    const handleClearQuery = () => {
        setQueryText('')
        if (inputRef.current) inputRef.current.focus()
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 27) {
            e.preventDefault()
            e.stopPropagation()
            handleClearQuery()
        }
    }

    return (
        <>
            <Grid container alignItems='center' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5' color='primary'>
                        <SearchIcon
                            fontSize='large'
                            style={{ marginBottom: -9 }}
                        />{' '}
                        {t('search')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        inputRef={inputRef}
                        InputProps={{
                            // startAdornment: (
                            //     <InputAdornment position='start'>
                            //         <SearchIcon color='primary' />
                            //     </InputAdornment>
                            // ),
                            endAdornment:
                                queryText.length > 0 ? (
                                    <InputAdornment position='end'>
                                        <BackspaceIcon
                                            fontSize='small'
                                            color='disabled'
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleClearQuery}
                                        />
                                    </InputAdornment>
                                ) : undefined,
                        }}
                        value={queryText}
                        variant='outlined'
                        margin='none'
                        size='small'
                        onChange={(e) => setQueryText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        fullWidth
                        style={{ maxWidth: 400 }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} md={8}>
                    {queryText.length >= 3 ? (
                        query.trim() === queryText.trim() ? (
                            <TasksList
                                query={{
                                    search: query.trim(),
                                    limit: 50,
                                    // sort_by: 'status',
                                    // sort_dir: 1,
                                }}
                                tasksPerPage={10}
                                showDone={true}
                                isolatedMode
                                listName='search'
                                sortFunction={() => 0}
                            />
                        ) : (
                            <>
                                <Skeleton height={50} />
                                <Skeleton height={50} />
                                <Skeleton height={50} />
                            </>
                        )
                    ) : (
                        <Typography>{t('typeAtLeast3Chars')}</Typography>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default TasksSearchView
