import {
    endOfDay,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays
} from 'date-fns'
import { TimePreset } from './typing'


const localStartOfWeek = (date: Date, locale: Locale | undefined) => {
    return startOfWeek(date, { locale: locale })
}

// const localEndOfWeek = (date: Date, locale: Locale) => {
//     return endOfWeek(date, { locale })
// }

const presetToDates = (
    preset: TimePreset,
    locale: Locale | undefined
): [Date, Date] | undefined => {
    const today = new Date()
    switch (preset) {
        case 'today':
            return [startOfDay(today), endOfDay(today)]
        case 'last_7_days':
            return [startOfDay(subDays(today, 6)), endOfDay(today)]
        case 'current_month_to_date':
            return [startOfMonth(today), endOfDay(today)]
        case 'current_week_to_date':
            return [localStartOfWeek(today, locale), endOfDay(today)]
    }
}

export { presetToDates }
