import { useEffect } from 'react'
import TimePresetDropdown from 'src/components/Common/TimePresetDropdown'
import TimeStatsByProject from 'src/components/Common/TimeStatsByProject'
import { useLanguage } from 'src/stores/useLanguage'
import useTasks from 'src/stores/useTasks'
import { presetToDates } from 'src/timePresetUtils'
import type {
    // Task,
    TimePreset,
} from 'src/typing'
import { WidgetComponentProps } from '../BaseWidget'

const TimeByProjectsWidget = (props: WidgetComponentProps) => {
    var { time_preset: preset, onChange } = props
    // const [isLoading, setIsLoading] = useState(false)
    const { fetchTasks, tasksStore: tasks } = useTasks()
    // const [tasks, setTasks] = useState<Task[]>([])
    const { getDateLocale } = useLanguage()
    var dateFrom: Date | null = null,
        dateTo: Date | null = null

    // Temporary for demonstration, to be replaced by global state changes

    const presetResult = presetToDates(preset, getDateLocale())

    if (presetResult !== undefined) [dateFrom, dateTo] = presetResult

    useEffect(() => {
        const fetchTasksRequest = async () => {
            if (!dateFrom || !dateTo) return
            // setIsLoading(true)
            const query = {
                time_from: dateFrom.toISOString(),
                time_to: dateTo.toISOString(),
            }
            // const response =
            await fetchTasks(query)
            // if (response.status === 200) {
            //     setTasks(response.data.data)
            // }
            // setIsLoading(false)
        }
        fetchTasksRequest()
    }, [
        preset,
        dateTo && dateTo.toISOString(),
        dateFrom && dateFrom.toISOString(),
    ])

    if (!dateFrom || !dateTo) return null

    return (
        <>
            <TimePresetDropdown
                value={preset}
                onChange={(preset: TimePreset) =>
                    onChange({ time_preset: preset })
                }
            />
            <TimeStatsByProject
                dateFrom={dateFrom}
                dateTo={dateTo}
                tasks={tasks}
                isLoading={false}
            />
        </>
    )
}

export default TimeByProjectsWidget
