import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import { useContext, useEffect, useState } from 'react'
import { ProjectsContext } from 'src/stores/projectsStore'
import { useLanguage } from 'src/stores/useLanguage'
import useProjects from 'src/stores/useProjects'

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline',
    },
})

const FilterByProjectsModal = (props) => {
    const {
        tasks,
        handleClose,
        handleSave,
        filterState: filterStateProp = [],
    } = props
    const { state: projectsState } = useContext(ProjectsContext)
    const { t } = useLanguage()
    const { projects } = useProjects()

    const projectsStats = {}
    tasks.forEach((task) => {
        if (projectsStats[task.project_id] === undefined) {
            projectsStats[task.project_id] = 0
        }
        projectsStats[task.project_id]++
    })
    const [filterState, setFilterState] = useState({})
    const [highlighted, setHighlighted] = useState([])

    // Prepare flat tree of projects to display
    // Includes projects present in tasks and provided in filterStateProp
    const projectsToDisplay = []
    if (projectsStats[null] !== undefined || filterState[null] !== undefined) {
        projectsToDisplay.push({ _id: null, name: t('noProject'), depth: -1 })
    }
    const filterProjects = (project) => {
        let alreadyAdded = false
        if (
            projectsStats[project._id] !== undefined ||
            filterStateProp.includes(project._id)
        ) {
            projectsToDisplay.push(project)
            alreadyAdded = true
        }
        if (project.subProjectsIds !== undefined) {
            let subProjectsToDisplay = project.subProjectsIds.filter(
                (projectId) => projectsStats[projectId] !== undefined
            )
            if (
                !alreadyAdded &&
                subProjectsToDisplay.length > 0 &&
                projectsStats[project._id] === undefined
            )
                projectsToDisplay.push(project)
            project.subProjects.forEach((subProject) =>
                filterProjects(subProject)
            )
        }
    }
    projectsState.projectsTree.forEach((project) => filterProjects(project))

    const toggleSelection = (projectId) => {
        const project = projectsToDisplay.filter(
            (item) => item._id === projectId
        )[0]
        const newState = { ...filterState }

        if (projectId === null) {
            newState[projectId] = !newState[projectId]
            setFilterState(newState)
            return
        }

        if (newState[projectId] === true) {
            newState[projectId] = false
            project.subProjectsIds.forEach((id) => (newState[id] = false))
        } else if (newState[projectId] === false) {
            newState[projectId] = true
            project.subProjectsIds.forEach((id) => (newState[id] = 1))
        }
        setFilterState(newState)
    }

    const save = () => {
        let projectIds = []
        Object.keys(filterState).forEach((projectId) => {
            if (filterState[projectId] === true) {
                if (projectId !== 'null') {
                    projectIds.push(projectId)
                } else projectIds.push(null)
            }
        })
        handleSave(projectIds)
        handleClose()
    }

    // Sync prop and internal state
    useEffect(() => {
        const state = {}
        projectsToDisplay.forEach((project) => {
            state[project._id] = false
        })
        filterStateProp.forEach((projectId) => {
            state[projectId] = true
            let project = projects.filter((item) => item._id === projectId)[0]
            if (project !== undefined && project.subProjectsIds !== undefined) {
                project.subProjectsIds.forEach((id) => (state[id] = 1))
            }
        })
        setFilterState(state)
    }, [])

    const handleMouseOver = (projectId) => {
        const project = projectsToDisplay.filter(
            (item) => item._id === projectId
        )[0]
        if (project.subProjectsIds !== undefined) {
            setHighlighted(project.subProjectsIds)
        }
    }

    const handleMouseLeave = () => {
        setHighlighted([])
    }

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth='xs'
            classes={isSmall ? { scrollPaper: classes.scrollPaper } : {}}
            data-test='modal-project-filter'
        >
            <div
                disableTypography
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    padding: '16px 10px 10px 24px',
                }}
            >
                <Typography
                    variant='h6'
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >
                    {t('filterByProjects')}
                </Typography>
                <IconButton
                    onClick={handleClose}
                    size='small'
                    style={{ marginLeft: '15px' }}
                    data-test='close-btn'
                >
                    <CloseIcon />
                </IconButton>
            </div>
            {/* <DialogTitle></DialogTitle> */}
            <DialogContent>
                <List
                    dense
                    style={{ marginLeft: '-16px', marginRight: '-16px' }}
                >
                    {projectsToDisplay.map((project) => (
                        <ListItem
                            key={project._id}
                            onClick={() => toggleSelection(project._id)}
                            button
                            disableRipple
                            onMouseEnter={() => handleMouseOver(project._id)}
                            onMouseLeave={() => handleMouseLeave(project._id)}
                            selected={filterState[project._id]}
                            style={
                                highlighted.includes(project._id)
                                    ? {
                                          backgroundColor:
                                              theme.palette.action.hover,
                                          borderTop:
                                              project.depth === 0
                                                  ? '1px solid rgba(0, 0, 0, 0.12)'
                                                  : 'none',
                                      }
                                    : {
                                          borderTop:
                                              project.depth === 0
                                                  ? '1px solid rgba(0, 0, 0, 0.12)'
                                                  : 'none',
                                      }
                            }
                        >
                            <ListItemIcon style={{ minWidth: '36px' }}>
                                <Checkbox
                                    edge='start'
                                    color='primary'
                                    checked={filterState[project._id]}
                                    disabled={filterState[project._id] === 1}
                                    disableRipple
                                    // indeterminate={
                                    //     filterState[project._id] ===
                                    //     'partly'
                                    // }
                                    size='small'
                                />
                            </ListItemIcon>
                            <ListItemText
                                style={{
                                    paddingLeft:
                                        18 * (project.depth - 1) + 'px',
                                }}
                            >
                                {project.depth > 0 && (
                                    <SubdirectoryArrowRightIcon
                                        fontSize='small'
                                        color='disabled'
                                        style={{ marginBottom: '-2px' }}
                                    />
                                )}
                                <span
                                    style={{
                                        fontWeight:
                                            project.depth <= 1 ? 700 : 400,
                                    }}
                                >
                                    {project.name}
                                </span>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                {projectsStats[project._id]}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        let newState = {}
                        Object.keys(filterState).forEach(
                            (id) => (newState[id] = false)
                        )
                        setFilterState(newState)
                    }}
                    variant='outlined'
                    data-test='clear-btn'
                >
                    {t('clearAll')}
                </Button>
                <Button
                    onClick={save}
                    color='primary'
                    variant='contained'
                    data-test='save-btn'
                >
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FilterByProjectsModal
