import { createContext, Dispatch, FC, useMemo, useReducer } from 'react'
import type { Task, TaskInStore } from 'src/typing'
import { dateToToken } from 'src/utils'
import { updateArray } from './storeTools'

interface TasksStore {
    tasks: TaskInStore[]
    runningTask: TaskInStore | null
    todayToken: number
}

enum TasksStoreActionType {
    UPDATE_STORE,
    DELETE,
    SET_RUNNING_TASK,
    CLEAR_RUNNING_TASK,
    SET_TODAY_TOKEN,
    CLEAR_STORE,
}

interface UpdateTasksStoreAction {
    type: TasksStoreActionType.UPDATE_STORE
    payload: Task[]
}

interface DeleteTaskAction {
    type: TasksStoreActionType.DELETE
    payload: string
}

interface SetRunningTaskAction {
    type: TasksStoreActionType.SET_RUNNING_TASK
    payload: Task[]
}

interface ClearRunningTaskAction {
    type: TasksStoreActionType.CLEAR_RUNNING_TASK
    payload: Task
}

interface SetTodayTokenAction {
    type: TasksStoreActionType.SET_TODAY_TOKEN
    payload?: undefined
}

interface ClearTasksStoreAction {
    type: TasksStoreActionType.CLEAR_STORE
    payload?: undefined
}

type TasksStoreAction =
    | UpdateTasksStoreAction
    | DeleteTaskAction
    | SetRunningTaskAction
    | ClearRunningTaskAction
    | SetTodayTokenAction
    | ClearTasksStoreAction

const initTasksStore = (): TasksStore => {
    return {
        tasks: [],
        runningTask: null,
        todayToken: dateToToken(new Date()),
    }
}

const reducer = (state: TasksStore, action: TasksStoreAction): TasksStore => {
    const { type, payload } = action

    switch (type) {
        case TasksStoreActionType.UPDATE_STORE:
            return {
                ...state,
                tasks: updateArray(state.tasks, payload),
            }
        case TasksStoreActionType.DELETE:
            // Clear running task if it is deleted
            let runningTask = state.runningTask
            if (runningTask && runningTask._id === payload) {
                runningTask = null
            }
            return {
                ...state,
                tasks: state.tasks.filter((task) => task._id !== payload),
                runningTask: runningTask,
            }
        case TasksStoreActionType.SET_RUNNING_TASK:
            return {
                ...state,
                runningTask: payload[0],
                tasks: updateArray(state.tasks, payload),
            }
        case TasksStoreActionType.CLEAR_RUNNING_TASK:
            return {
                ...state,
                runningTask: null,
                tasks: updateArray(state.tasks, [payload]),
            }
        case TasksStoreActionType.SET_TODAY_TOKEN:
            return {
                ...state,
                todayToken: dateToToken(new Date()),
            }
        case TasksStoreActionType.CLEAR_STORE:
            return initTasksStore()
    }
}

const TasksContext = createContext<{
    state: TasksStore
    dispatch: Dispatch<TasksStoreAction>
}>({
    state: initTasksStore(),
    dispatch: () => {},
})

const TasksDispatchContext = createContext<Dispatch<TasksStoreAction>>(() => {})

const TasksContextProvider: FC = (props) => {
    const [state, dispatch] = useReducer(reducer, undefined, initTasksStore)
    const contextValue = useMemo(() => ({ state, dispatch }), [state])

    return (
        <TasksContext.Provider value={contextValue}>
            <TasksDispatchContext.Provider value={dispatch}>
                {props.children}
            </TasksDispatchContext.Provider>
        </TasksContext.Provider>
    )
}

export {
    TasksContext,
    TasksContextProvider,
    TasksDispatchContext,
    TasksStoreActionType,
}
