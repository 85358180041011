import { useContext, useMemo } from 'react'
import {
    FORMATTING_HELP_MODAL,
    ModalsDispatchContext,
    OPEN_CREATE_GOAL_MODAL,
    OPEN_EDIT_GOAL_MODAL,
    OPEN_PROJECTS_FILTER_MODAL,
    OPEN_PROJECT_MODAL,
    OPEN_SELECT_DATE_MODAL,
    OPEN_TASK_DATE_MODAL,
    OPEN_TASK_PROJECT_MODAL,
    OPEN_TASK_PROJECT_MODAL_NO_API,
    OPEN_TASK_REPEAT_MODAL,
    OPEN_TASK_REPEAT_MODAL_NO_API,
    OPEN_TASK_TEXT_MODAL,
    OPEN_TIME_RECORDS_MODAL,
    TIME_RECORD_MODAL,
} from './modalsStore'

const useModals = () => {
    const modalsDispatch = useContext(ModalsDispatchContext)

    const openCreateGoalModal = () => {
        modalsDispatch({ type: OPEN_CREATE_GOAL_MODAL })
    }

    const openEditGoalModal = (goal) => {
        modalsDispatch({ type: OPEN_EDIT_GOAL_MODAL, payload: goal })
    }

    const openTaskTimeRecordsModal = (task) => {
        modalsDispatch({ type: OPEN_TIME_RECORDS_MODAL, payload: task })
    }

    const openProjectsFilterModal = (tasks, filterState, handleSave) => {
        modalsDispatch({
            type: OPEN_PROJECTS_FILTER_MODAL,
            payload: { tasks, handleSave, filterState },
        })
    }

    const openCreateProjectModal = (project = {}, options = {}) => {
        const { handleCancel, callAfterCreate } = options
        modalsDispatch({
            type: OPEN_PROJECT_MODAL,
            payload: { project, handleCancel, callAfterCreate },
        })
    }

    const openEditProjectModal = (project, options = {}) => {
        // const { handleClose } = options
        modalsDispatch({
            type: OPEN_PROJECT_MODAL,
            payload: { project },
        })
    }

    const openTaskDateModal = (task) => {
        modalsDispatch({ type: OPEN_TASK_DATE_MODAL, payload: task })
    }

    const openTaskProjectModal = (task) => {
        modalsDispatch({ type: OPEN_TASK_PROJECT_MODAL, payload: task })
    }

    const openTaskRepeatModal = (task) => {
        modalsDispatch({ type: OPEN_TASK_REPEAT_MODAL, payload: task })
    }

    const openTaskRepeatModalNoApi = (handleSave, value) => {
        modalsDispatch({
            type: OPEN_TASK_REPEAT_MODAL_NO_API,
            payload: {
                handleSave,
                value,
            },
        })
    }

    const openTaskProjectModalNoApi = (handleSave, value) => {
        modalsDispatch({
            type: OPEN_TASK_PROJECT_MODAL_NO_API,
            payload: {
                handleSave,
                value,
            },
        })
    }

    const openTaskTextModal = (task) => {
        modalsDispatch({ type: OPEN_TASK_TEXT_MODAL, payload: task })
    }

    const openSelectDateModal = (handleSave, value) => {
        modalsDispatch({
            type: OPEN_SELECT_DATE_MODAL,
            payload: {
                handleSave,
                value,
            },
        })
    }

    const openTimeRecordModal = (task, timeRecord, handleSave) => {
        modalsDispatch({
            type: TIME_RECORD_MODAL,
            payload: { task, timeRecord, handleSave },
        })
    }

    const openFormattingHelpModal = () => {
        modalsDispatch({
            type: FORMATTING_HELP_MODAL,
        })
    }

    return useMemo(
        () => ({
            openCreateGoalModal,
            openEditGoalModal,
            openTaskTimeRecordsModal,
            openCreateProjectModal,
            openEditProjectModal,
            openTaskDateModal,
            openTaskProjectModal,
            openTaskRepeatModal,
            openTaskTextModal,
            openProjectsFilterModal,
            openSelectDateModal,
            openTaskRepeatModalNoApi,
            openTaskProjectModalNoApi,
            openTimeRecordModal,
            openFormattingHelpModal,
        }),
        []
    )
}

export default useModals
