import InboxIcon from '@material-ui/icons/Inbox'
import TasksList from 'src/components/TasksList'
import { useLanguage } from 'src/stores/useLanguage'

const InboxView = () => {
    const query = { project_id: -1 }

    const { t } = useLanguage()

    return (
        <TasksList
            query={query}
            tasksPerPage={50}
            title={t('inbox')}
            listName='inbox'
            icon={
                <InboxIcon
                    color='primary'
                    fontSize='large'
                    style={{ marginBottom: -9 }}
                />
            }
        />
    )
}

export default InboxView
