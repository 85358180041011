import { useState } from 'react'
import MarkdownRenderer from '../MarkdownRenderer'
import TextEditor from '../TextEditor'

interface EditableNoteProps {
    content: string
    onSave: (content: string) => void
    placeholder: string
    maxRows: number | undefined
    minRows: number | undefined
    style: any
}

const EditableNote = (props: EditableNoteProps) => {
    const {
        content,
        onSave,
        placeholder = '',
        maxRows,
        minRows,
        style: styleProp,
    } = props

    const [isEditMode, setIsEditMode] = useState(false)

    if (isEditMode) {
        return (
            <TextEditor
                onCancel={() => setIsEditMode(false)}
                onSave={(content) => {
                    onSave(content)
                    setIsEditMode(false)
                }}
                content={content}
                maxRows={maxRows}
                minRows={minRows}
            />
        )
    }

    var style = { cursor: 'pointer' }

    if (styleProp !== undefined) style = { style, ...styleProp }

    // t('notesPlaceholder')
    return (
        <div
            onClick={(e) => {
                // console.log(e)
                setIsEditMode(true)
            }}
            style={style}
        >
            <MarkdownRenderer content={content || placeholder} />
        </div>
    )

    return null
}

export default EditableNote
