// import { useState } from 'react'
import EditableNote from 'src/components/Common/EditableNote'
import { useLanguage } from 'src/stores/useLanguage'
import { WidgetComponentProps } from '../BaseWidget'

// interface NoteWidgetProps extends WidgetProps {
//     content: string
// }

const NoteWidget = (props: WidgetComponentProps) => {
    const { content, onChange } = props
    const { t } = useLanguage()
    // const [internalContent, setInternalContent] = useState(content)

    // const onSave = () => {}

    return (
        <>
            <EditableNote
                // maxRows={9}
                minRows={2}
                content={content}
                onSave={(content: string) => onChange({ content })}
                placeholder={t('notesPlaceholder')}
                style={{ marginTop: -0 }}
            />
        </>
    )
}

export default NoteWidget
