import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/Archive'
// import BookmarkIcon from '@material-ui/icons/Star'
// import BookmarkBorderIcon from '@material-ui/icons/StarOutline'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import { useRef, useState } from 'react'
import ProjectSelectDropdown from 'src/components/ProjectSelectDropdown'
import { useLanguage } from 'src/stores/useLanguage'
import useProjects from 'src/stores/useProjects'

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline',
    },
})

const ProjectModal = (props) => {
    const classes = useStyles()

    const { t } = useLanguage()

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    let { handleClose, project, projects, callAfterCreate, handleCancel } =
        props
    let createMode = false
    if (project?._id === undefined) {
        createMode = true
        project = {
            name: '',
            parent_id: project?.parent_id || null,
            favorite: false,
            status: 'active',
        }
    }
    const [name, setName] = useState(project.name)
    const [parentId, setParentId] = useState(project.parent_id)
    const [isPinned, setIsPinned] = useState(project.favorite)
    const [status, setStatus] = useState(project.status)
    const nameInputRef = useRef(null)

    const toggleProjectStatus = () => {
        setStatus((previousState) =>
            previousState === 'active' ? 'archived' : 'active'
        )
    }

    const toggleProjectPinnedStatus = () => {
        setIsPinned((previousState) => !previousState)
    }

    const { createProject, updateProject } = useProjects()

    const handleSubmit = async () => {
        let update = { name, parent_id: parentId, status, favorite: isPinned }
        if (createMode) {
            const newProject = await createProject(update)
            if (callAfterCreate !== undefined) {
                callAfterCreate(newProject)
            }
        } else {
            await updateProject(project._id, update)
        }
        handleClose()
    }

    const handleKeyDown = (e) => {
        // Enter triggers submit
        if (e.keyCode === 13) {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit()
        }
    }

    const handleChangeParentProject = (newValue) => {
        setParentId(newValue === null ? null : newValue._id)
        nameInputRef.current.focus()
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth='xs'
            classes={isSmall ? { scrollPaper: classes.scrollPaper } : {}}
            data-test='project-modal'
            transitionDuration={0}
        >
            <DialogTitle>
                {createMode ? t('newProject') : t('changeProject')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            type='text'
                            label={t('projectName')}
                            variant='outlined'
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onKeyDown={handleKeyDown}
                            inputRef={nameInputRef}
                            data-test='modal-project-name'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ProjectSelectDropdown
                            handleChange={handleChangeParentProject}
                            label={t('parentProject')}
                            projects={projects}
                            projectId={parentId}
                            disabledOptions={[project?._id]}
                            nullOptionLabel={'Root project'}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item>
                        <Button
                            startIcon={
                                isPinned ? (
                                    <BookmarkIcon />
                                ) : (
                                    <BookmarkBorderIcon />
                                )
                            }
                            onClick={toggleProjectPinnedStatus}
                            variant='outlined'
                        >
                            {isPinned ? t('pinned') : t('notPinned')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            startIcon={
                                status === 'active' ? (
                                    <UnarchiveIcon />
                                ) : (
                                    <ArchiveIcon />
                                )
                            }
                            onClick={toggleProjectStatus}
                            variant='outlined'
                        >
                            {status === 'active' ? t('active') : t('archived')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    variant='contained'
                    color='primary'
                    data-test='modal-project-submit-btn'
                >
                    {createMode ? t('create') : t('save')}
                </Button>
                <Button
                    onClick={handleCancel}
                    variant='contained'
                    color='secondary'
                    data-test='cancel-btn'
                >
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProjectModal
