import { Grid, Typography } from '@material-ui/core'
import React from 'react'

function formatText(text) {
    // var original_text = text
    var exp =
        /(\b(?:https|http):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi

    text = text.split(exp)
    if (text.length > 1) {
        for (let i = 1; i < text.length; i += 2) {
            let urlText = text[i].replace(/(\b(?:https|http):\/\/)/gi, '')
            urlText =
                urlText.length > 24 ? urlText.substring(0, 24) + '...' : urlText
            urlText =
                urlText[urlText.length - 1] === '/'
                    ? urlText.substring(0, urlText.length - 1)
                    : urlText
            text[i] = (
                <a
                    href={text[i]}
                    key={i}
                    target='_blank'
                    onClick={(e) => e.stopPropagation()}
                    style={{ color: '#3f51b5' }}
                >
                    {urlText}
                </a>
            )
        }
    }

    return text
}

const TaskCardTextInfo = (props) => {
    const { task } = props

    return (
        <Grid
            container
            style={{ paddingTop: '9px', paddingBottom: '9px' }}
            spacing={1}
        >
            <Grid item>
                <Typography
                    data-test='task-card-text'
                    variant='body1'
                    style={{
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                    }}
                >
                    {formatText(task.text)}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default TaskCardTextInfo
