import {
    Badge,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Typography,
} from '@material-ui/core'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone'
import HistoryIcon from '@material-ui/icons/History'
import InboxIcon from '@material-ui/icons/Inbox'
import SearchIcon from '@material-ui/icons/Search'
import TodayIcon from '@material-ui/icons/Today'
import ViewDayIcon from '@material-ui/icons/ViewDay'
import { addDays } from 'date-fns'
import { ReactNode, useContext, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ProjectsContext } from 'src/stores/projectsStore'
import { TasksContext } from 'src/stores/tasksStore'
import { useLanguage } from 'src/stores/useLanguage'
import { TaskInStore } from 'src/typing'
import { dateToToken } from 'src/utils'

const calculateMenuStats = (tasks: TaskInStore[]) => {
    const stats = {
        today: 0,
        tomorrow: 0,
        overdue: 0,
        inbox: 0,
    }

    const todayToken = dateToToken(new Date())
    const tomorrowToken = dateToToken(addDays(new Date(), 1))

    for (const task of tasks) {
        if (task.status === 'active') {
            if (task.project_id === null) {
                stats.inbox++
            }
            if (task.date_token !== null && task.date_token < todayToken) {
                stats.overdue++
            }
            if (task.date_token === todayToken) {
                stats.today++
            }
            if (task.date_token === tomorrowToken) {
                stats.tomorrow++
            }
        }
    }

    return stats
}

interface MenuItemInterface {
    text: string
    name: string
    href: string
    icon: ReactNode
    color?: 'secondary'
    counter?: number
}

const MainMenu = ({ isNarrow }: { isNarrow: boolean | undefined }) => {
    const location = useLocation()
    const { state: tasksState } = useContext(TasksContext)
    const { state: projectsState } = useContext(ProjectsContext)
    const { t, getLanguage } = useLanguage()

    const stats = calculateMenuStats(tasksState.tasks)

    const divider = 'divider'
    const items: (MenuItemInterface | 'divider')[] = useMemo(
        () => [
            {
                text: t('today'),
                name: 'today',
                href: '/app/tasks/today',
                icon: <ViewDayIcon color='primary' />,
                counter: stats.today,
            },
            {
                text: t('tomorrow'),
                name: 'tomorrow',
                href: '/app/tasks/tomorrow',
                icon: <TodayIcon color='primary' />,
                counter: stats.tomorrow,
            },

            {
                text: t('inbox'),
                name: 'inbox',
                href: '/app/tasks/inbox',
                icon: <InboxIcon color='primary' />,
                counter: stats.inbox,
            },
            {
                text: t('overdue'),
                name: 'overdue',
                href: '/app/tasks/overdue',
                icon: <AssignmentLateIcon color='primary' />,
                color: 'secondary',
                counter: stats.overdue,
            },
            divider,
            {
                text: t('chronology'),
                name: 'chronology',
                href: '/app/tasks/chronology',
                icon: <HistoryIcon color='primary' />,
            },
            {
                text: t('review'),
                name: 'review',
                href: '/app/stats/review',
                icon: <EqualizerIcon color='primary' />,
            },
            {
                text: t('search'),
                name: 'search',
                href: '/app/tasks/search',
                icon: <SearchIcon color='primary' />,
            },

            divider,
            {
                text: t('projects'),
                name: 'projects',
                href: '/app/projects',
                icon: <FolderOpenTwoToneIcon color='primary' />,
                counter: projectsState.projects.length,
            },
        ],
        [JSON.stringify(stats), projectsState.projects.length, getLanguage()]
    )

    const pinnedProjects = projectsState.projects.filter(
        (project) => project.status === 'active' && project.favorite
    )

    const projectItems: { text: string; _id: string; href: string }[] = []

    if (pinnedProjects.length > 0) {
        pinnedProjects.forEach((project) => {
            projectItems.push({
                text: project.name,
                _id: project._id,
                href: '/app/projects/' + project._id + '/tasks',
            })
        })
    }

    const activeRoute = (routeName: string) => {
        return location.pathname === routeName
    }

    return (
        <MenuList
            data-test='main-menu'
            onMouseOver={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
        >
            {items.map((item, index) => {
                if (item === divider) {
                    return <Divider key={index} />
                } else {
                    const { text, href, icon, counter, color, name } = item
                    return (
                        <NavLink
                            to={href}
                            style={{
                                textDecoration: 'none',
                                color: '#000',
                            }}
                            key={index}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <MenuItem
                                selected={activeRoute(href)}
                                style={{ minHeight: '48px' }}
                                data-test={'main-menu-' + name}
                            >
                                {icon !== undefined ? (
                                    <ListItemIcon>
                                        <Badge
                                            badgeContent={counter}
                                            data-test={`counter-${name}`}
                                            color={color || undefined}
                                        >
                                            {icon}
                                        </Badge>
                                    </ListItemIcon>
                                ) : null}

                                <ListItemText primary={text} color='primary' />
                            </MenuItem>
                        </NavLink>
                    )
                }
            })}

            {projectItems.length > 0 ? (
                <NavLink
                    to='/app/projects/pinned'
                    style={{
                        textDecoration: 'none',
                        color: '#000',
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <MenuItem selected={activeRoute('/app/projects/pinned')}>
                        <ListItemIcon>
                            <BookmarkIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('pinnedProjects')}
                            color='primary'
                        />
                    </MenuItem>
                </NavLink>
            ) : null}
            {projectItems.length > 0 && !isNarrow && <Divider />}
            {!isNarrow &&
                projectItems.map((item) => (
                    <NavLink
                        to={item.href}
                        style={{
                            textDecoration: 'none',
                            color: '#000',
                        }}
                        key={item._id}
                    >
                        <MenuItem selected={activeRoute(item.href)} dense>
                            <ListItemText>
                                <Typography variant='body2'>
                                    {item.text}
                                </Typography>
                            </ListItemText>
                        </MenuItem>
                    </NavLink>
                ))}
        </MenuList>
    )
}

export default MainMenu
export { calculateMenuStats }
