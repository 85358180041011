import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import { useState } from 'react'
import { apiRequestRestorePasswordEmail } from 'src/api/auth'
import { useLanguage } from 'src/stores/useLanguage'

const RequestRestorePasswordView = () => {
    const { t } = useLanguage()
    // const [serverError, setServerError] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)

    if (isSuccess) {
        return (
            <Box mx='auto' maxWidth={500}>
                <Grid container spacing={1} justifyContent='center'>
                    <Grid item>
                        <Typography variant='h5'>
                            {t('restorePassword')}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            {t('successPasswordRecoveryRequested')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box mx='auto' maxWidth={500}>
            <Grid container spacing={1} justifyContent='center'>
                <Grid item>
                    <Typography variant='h5'>{t('restorePassword')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{t('fillInEmailToRestorePassword')}</Typography>
                </Grid>
                <Formik
                    initialValues={{ email: '' }}
                    validateOnBlur
                    validate={(values) => {
                        const errors = {}

                        if (!values.email) {
                            errors.email = 'errEmailCannotBeEmpty'
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(
                                values.email
                            )
                        ) {
                            errors.email = 'errWrongEmailFormat'
                        }
                        return errors
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const response = await apiRequestRestorePasswordEmail(
                            values.email
                        )
                        if (response.status === 200) {
                            setIsSuccess(true)
                        }
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        errors,
                        touched,
                        isSubmitting,
                        handleSubmit,
                    }) => (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    error={touched.email && errors.email}
                                    label={t('email')}
                                    name='email'
                                    type='email'
                                    value={values.email}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        // setServerError(null)
                                    }}
                                    helperText={
                                        touched.email && t(errors.email)
                                    }
                                />
                            </Grid>
                            {/* {serverError ? (
                                <Grid item>
                                    <FormHelperText error>
                                        {t(serverError)}
                                    </FormHelperText>
                                </Grid>
                            ) : null} */}
                            <Grid item xs={12}>
                                <Button
                                    color='primary'
                                    fullWidth
                                    variant='contained'
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    {t('restorePassword')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Formik>
            </Grid>
        </Box>
    )
}

export default RequestRestorePasswordView
