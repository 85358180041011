import {
    Button,
    ButtonGroup,
    Grid,
    Hidden,
    // IconButton,
    Typography,
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone'
import { Pagination, Skeleton } from '@material-ui/lab'
import { useEffect, useReducer, useState } from 'react'
import {
    MOnePriorityIcon,
    MTwoPriorityIcon,
    POnePriorityIcon,
    PTwoPriorityIcon,
    ZeroPriorityIcon,
} from 'src/components/Icons'
import usePagination from 'src/hooks/usePagination'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import useProjects from 'src/stores/useProjects'
import useTasks from 'src/stores/useTasks'
import { loadFromLocalStorage } from 'src/utils'
import TaskCard from '../TaskCard'

const filterInitialState = {
    active: true,
    done: false,
    two: true,
    one: true,
    zero: true,
    mOne: true,
    mTwo: true,
}

const noPriorities = {
    two: false,
    one: false,
    zero: false,
    mOne: false,
    mTwo: false,
}

const allPriorities = {
    two: true,
    one: true,
    zero: true,
    mOne: true,
    mTwo: true,
}

const SET_STATE = Symbol('SET_STATE')
const TOGGLE_FILTER = Symbol('TOGGLE_FILTER')

const filterReducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case TOGGLE_FILTER:
            return { ...state, [payload]: !state[payload] }
        case SET_STATE:
            return { ...state, ...payload }
        default:
            console.warn('Unknown reducer action ' + type.toString())
            return state
    }
}

const compareStatuses = (a, b) => {
    if (a === b) return 0
    if (a === 'active') return -1
    return 1
}

const compareTexts = (a, b) => {
    if (a < b) return -1
    if (a > b) return 1
    return 0
}

// Sort by date (earlier up, empty down)
const compareDates = (a, b) => {
    if (a === null) a = 99999999
    if (b === null) b = 99999999
    if (a < b) return -1
    if (a > b) return 1
    return 0
}

const comparePriority = (a, b) => b - a

const sortByStatusPriorityText = (a, b) => {
    return (
        compareStatuses(a.status, b.status) ||
        comparePriority(a.priority, b.priority) ||
        compareDates(a.date_token, b.date_token) ||
        compareTexts(a.text, b.text)
    )
}

const calculatePrioritiesStats = (tasks) => {
    const stats = {
        two: 0,
        one: 0,
        zero: 0,
        mOne: 0,
        mTwo: 0,
    }

    tasks.forEach((task) => {
        if (task.priority === 0) {
            stats.zero++
            return
        } else if (task.priority === 1) {
            stats.one++
            return
        } else if (task.priority === 2) {
            stats.two++
            return
        } else if (task.priority === -1) {
            stats.mOne++
            return
        }
        stats.mTwo++
    })

    return stats
}

const calculateStatusStats = (tasks) => {
    const stats = {
        active: 0,
        done: 0,
    }

    tasks.forEach((task) => {
        task.status === 'active' ? stats.active++ : stats.done++
    })

    return stats
}

const filterTasks = (tasks, filter) => {
    let result = [...tasks]
    if (filter.taskIds !== undefined) {
        result = result.filter((task) => filter.taskIds.includes(task._id))
    }

    if (filter.project_id !== undefined) {
        result = result.filter((task) => task.project_id === filter.project_id)
    }

    if (filter.date_token !== undefined) {
        result = result.filter((task) => task.date_token === filter.date_token)
    }

    if (filter.date_to !== undefined) {
        result = result.filter(
            (task) =>
                task.date_token !== null && task.date_token <= filter.date_to
        )
    }

    if (filter.status !== undefined) {
        result = result.filter((task) => task.status === filter.status)
    }

    if (filter.statuses !== undefined) {
        result = result.filter((task) => filter.statuses.includes(task.status))
    }

    if (filter.priorities !== undefined) {
        result = result.filter((task) =>
            filter.priorities.includes(task.priority)
        )
    }

    if (filter.projects !== undefined && filter.projects.length > 0) {
        result = result.filter((task) => {
            return filter.projects.includes(task.project_id)
        })
    }

    return result
}

const TasksList = (props) => {
    let {
        query,
        tasksPerPage = 10,
        sortFunction = sortByStatusPriorityText,
        showDone = false,
        hideCounters = false,
        isolatedMode = false,
        title = null,
        icon = null,
        listName = null,
    } = props

    const { t } = useLanguage()

    filterInitialState.done = showDone

    const { fetchTasks, tasksStore, runningTask } = useTasks()
    const { projects } = useProjects()
    const { openProjectsFilterModal } = useModals()

    const [tasksIds, setTasksIds] = useState([])
    const [filterState, dispatchFilter] = useReducer(
        filterReducer,
        loadFromLocalStorage(`${listName}-tasks-filter`, filterInitialState)
    )
    const [projectsFilterState, setProjectsFilterStateHook] = useState(
        loadFromLocalStorage(`${listName}-tasks-projects-filter`, [])
    )

    const setProjectsFilterState = (newState) => {
        setProjectsFilterStateHook(newState)
        setPage(0)
        dispatchFilter({
            type: SET_STATE,
            payload: allPriorities,
        })
    }

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (listName !== null) {
            localStorage.setItem(
                `${listName}-tasks-filter`,
                JSON.stringify(filterState)
            )
            localStorage.setItem(
                `${listName}-tasks-projects-filter`,
                JSON.stringify(projectsFilterState)
            )
        }
    }, [JSON.stringify(filterState), JSON.stringify(projectsFilterState)])

    if (query === undefined) query = {}

    const { limit, page, setPage, totalCount, setTotalCount, paginate } =
        usePagination(tasksPerPage)

    useEffect(() => {
        const fetchTasksWithQuery = async () => {
            setIsLoading(true)
            const response = await fetchTasks({ ...query })
            if (response.data.data) {
                // Update state only if the list in isolated mode
                // to prevent waste re-rendering
                if (isolatedMode) {
                    setTasksIds(response.data.data.map((task) => task._id))
                }
            }
            setIsLoading(false)
        }
        fetchTasksWithQuery()
    }, [page, JSON.stringify(query), isolatedMode])

    let filter = { ...query }
    if (filter.project_id === -1) {
        filter['project_id'] = null
    }
    if (filter.date_token === -1) {
        filter['date_token'] = null
    }
    if (isolatedMode) {
        filter['taskIds'] = tasksIds
    }

    let allTasks = filterTasks(tasksStore, filter)
    let stats = calculateStatusStats(allTasks)

    // Filter by status
    filter = { statuses: [] }
    if (filterState.done) filter.statuses.push('done')
    if (filterState.active) filter.statuses.push('active')

    let afterStatusFilter = filterTasks(allTasks, filter)

    // Filter by projects
    const projectsStats = {}
    afterStatusFilter.forEach((task) => {
        if (projectsStats[task.project_id] === undefined) {
            projectsStats[task.project_id] = 0
        }
        projectsStats[task.project_id]++
    })

    let allowedProjects = []

    if (projectsFilterState.length > 0) {
        projects.forEach((project) => {
            if (projectsFilterState.includes(project._id)) {
                allowedProjects = [
                    project._id,
                    ...allowedProjects,
                    ...project.subProjectsIds,
                ]
            }
        })
        if (projectsFilterState.includes(null)) allowedProjects.push(null)
    }

    let projectsListCounter = 0
    let presentProjects = Object.keys(projectsStats)
    for (let projectId of allowedProjects) {
        if (presentProjects.includes(projectId)) {
            projectsListCounter++
        } else if (projectId === null && projectsStats[null] !== undefined) {
            projectsListCounter++
        }
    }

    let afterProjectsFilter = filterTasks(afterStatusFilter, {
        projects: allowedProjects,
    })

    stats = {
        ...calculatePrioritiesStats(afterProjectsFilter),
        ...stats,
        projectsStats,
    }

    // Filter by priority
    filter = {
        priorities: [],
    }
    if (filterState.two) filter.priorities.push(2)
    if (filterState.one) filter.priorities.push(1)
    if (filterState.zero) filter.priorities.push(0)
    if (filterState.mOne) filter.priorities.push(-1)
    if (filterState.mTwo) filter.priorities.push(-2)

    let allFilteredTasks = filterTasks(afterProjectsFilter, filter)

    const focusFilter = (e, state) => {
        e.preventDefault()
        setPage(0)
        dispatchFilter({
            type: SET_STATE,
            payload: state,
        })
        // setProjectsFilterState([])
    }

    allFilteredTasks.sort(sortFunction)

    useEffect(() => {
        setTotalCount(allFilteredTasks.length)
    }, [
        JSON.stringify(filterState),
        JSON.stringify(stats),
        JSON.stringify(projectsFilterState),
    ])

    if (isLoading && isolatedMode) {
        return (
            <>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </>
        )
    }

    let tasks = paginate(allFilteredTasks, page).slice(0, query.limit || 1000)

    const pagesNumber = Math.ceil(totalCount / limit)

    if (pagesNumber > 0 && page >= pagesNumber) setPage(pagesNumber - 1)

    if (allTasks.length === 0) {
        return (
            <Grid
                container
                alignItems='center'
                spacing={2}
                style={{ marginBottom: '4px' }}
            >
                {title !== null ? (
                    <Grid item xs={12}>
                        <Typography variant='h5' color='primary'>
                            {icon} {title}
                        </Typography>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Typography color='textSecondary'>
                        {t('noTasks')}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Grid
                container
                alignItems='center'
                spacing={2}
                style={{ marginBottom: '4px' }}
            >
                {title !== null ? (
                    <Grid item xs={12}>
                        <Typography variant='h5' color='primary'>
                            {icon} {title}
                        </Typography>
                    </Grid>
                ) : null}
                {hideCounters ? null : (
                    <Grid
                        item
                        container
                        spacing={1}
                        alignContent='center'
                        alignItems='center'
                        xs={12}
                    >
                        <Grid item>
                            <Hidden xsDown>
                                <ButtonGroup size='small' variant='contained'>
                                    {stats.active > 0 && (
                                        <Button
                                            startIcon={
                                                <CheckBoxOutlineBlankIcon />
                                            }
                                            color={
                                                filterState.active && 'primary'
                                            }
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'active',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...allPriorities,
                                                    active: true,
                                                    done: false,
                                                })
                                                setProjectsFilterState([])
                                            }}
                                            data-test='task-filter-active'
                                        >
                                            {stats.active}
                                        </Button>
                                    )}
                                    {stats.done > 0 && (
                                        <Button
                                            startIcon={<CheckBoxIcon />}
                                            color={
                                                filterState.done
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            onClick={() => {
                                                setPage(0)
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'done',
                                                })
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...allPriorities,
                                                    active: false,
                                                    done: true,
                                                })
                                                setProjectsFilterState([])
                                            }}
                                            data-test='task-filter-done'
                                        >
                                            {stats.done}
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </Hidden>
                            <Hidden smUp>
                                <ButtonGroup size='small' variant='contained'>
                                    {stats.active > 0 && (
                                        <Button
                                            color={
                                                filterState.active && 'primary'
                                            }
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'active',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...allPriorities,
                                                    active: true,
                                                    done: false,
                                                })
                                                setProjectsFilterState([])
                                            }}
                                            data-test='task-filter-active'
                                        >
                                            <CheckBoxOutlineBlankIcon fontSize='small' />
                                        </Button>
                                    )}
                                    {stats.done > 0 && (
                                        <Button
                                            color={
                                                filterState.done
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            onClick={() => {
                                                setPage(0)
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'done',
                                                })
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...allPriorities,
                                                    active: false,
                                                    done: true,
                                                })
                                                setProjectsFilterState([])
                                            }}
                                            data-test='task-filter-done'
                                        >
                                            <CheckBoxIcon fontSize='small' />
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </Hidden>
                        </Grid>

                        {!(
                            Object.keys(projectsStats).length >= 2 ||
                            projectsFilterState.length > 0
                        ) ? null : (
                            <Grid item>
                                <Hidden xsDown>
                                    <ButtonGroup
                                        size='small'
                                        variant='contained'
                                    >
                                        <Button
                                            onClick={(e) => {
                                                openProjectsFilterModal(
                                                    afterStatusFilter,
                                                    projectsFilterState,
                                                    (state) => {
                                                        setProjectsFilterState(
                                                            state
                                                        )
                                                        // focusFilter(e, {
                                                        //     ...allPriorities,
                                                        // })
                                                    }
                                                )
                                            }}
                                            startIcon={
                                                <FolderOpenTwoToneIcon fontSize='small' />
                                            }
                                            color={
                                                projectsFilterState.length > 0
                                                    ? 'primary'
                                                    : ''
                                            }
                                            data-test='task-filter-projects'
                                            onContextMenu={(e) => {
                                                e.preventDefault()
                                                setProjectsFilterState([])
                                                // focusFilter(e, {
                                                //     ...allPriorities,
                                                // })
                                            }}
                                        >
                                            {projectsFilterState.length > 0
                                                ? projectsListCounter
                                                : Object.keys(projectsStats)
                                                      .length}
                                        </Button>
                                    </ButtonGroup>
                                </Hidden>
                                <Hidden smUp>
                                    <ButtonGroup
                                        size='small'
                                        variant='contained'
                                    >
                                        <Button
                                            onClick={(e) => {
                                                openProjectsFilterModal(
                                                    afterStatusFilter,
                                                    projectsFilterState,
                                                    (state) => {
                                                        setProjectsFilterState(
                                                            state
                                                        )
                                                        focusFilter(e, {
                                                            ...allPriorities,
                                                        })
                                                    }
                                                )
                                            }}
                                            color={
                                                projectsFilterState.length > 0
                                                    ? 'primary'
                                                    : ''
                                            }
                                            data-test='task-filter-projects'
                                            onContextMenu={(e) => {
                                                e.preventDefault()
                                                setProjectsFilterState([])
                                                focusFilter(e, {
                                                    ...allPriorities,
                                                })
                                            }}
                                            style={{ minWidth: 32 }}
                                        >
                                            <FolderOpenTwoToneIcon fontSize='small' />
                                        </Button>
                                    </ButtonGroup>
                                </Hidden>
                            </Grid>
                        )}

                        <Grid item>
                            <Hidden xsDown>
                                <ButtonGroup size='small' variant='contained'>
                                    {stats.two > 0 && (
                                        <Button
                                            color={filterState.two && 'primary'}
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'two',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    two: true,
                                                })
                                            }}
                                            startIcon={
                                                <PTwoPriorityIcon fontSize='small' />
                                            }
                                            data-test='task-filter-priority2'
                                        >
                                            {stats.two}
                                        </Button>
                                    )}
                                    {stats.one > 0 && (
                                        <Button
                                            color={filterState.one && 'primary'}
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'one',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    one: true,
                                                })
                                            }}
                                            startIcon={
                                                <POnePriorityIcon fontSize='small' />
                                            }
                                            data-test='task-filter-priority1'
                                        >
                                            {stats.one}
                                        </Button>
                                    )}
                                    {stats.zero > 0 && (
                                        <Button
                                            color={
                                                filterState.zero && 'primary'
                                            }
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'zero',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    zero: true,
                                                })
                                            }}
                                            startIcon={
                                                <ZeroPriorityIcon fontSize='small' />
                                            }
                                            data-test='task-filter-priority0'
                                        >
                                            {stats.zero}
                                        </Button>
                                    )}
                                    {stats.mOne > 0 && (
                                        <Button
                                            color={
                                                filterState.mOne
                                                    ? 'primary'
                                                    : 'disabled'
                                            }
                                            onClick={() => {
                                                dispatchFilter({
                                                    payload: 'mOne',
                                                    type: TOGGLE_FILTER,
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    mOne: true,
                                                })
                                            }}
                                            startIcon={
                                                <MOnePriorityIcon fontSize='small' />
                                            }
                                            data-test='task-filter-priority-1'
                                        >
                                            {stats.mOne}
                                        </Button>
                                    )}
                                    {stats.mTwo > 0 && (
                                        <Button
                                            color={
                                                filterState.mTwo && 'primary'
                                            }
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'mTwo',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    mTwo: true,
                                                })
                                            }}
                                            startIcon={
                                                <MTwoPriorityIcon fontSize='small' />
                                            }
                                            data-test='task-filter-priority-2'
                                        >
                                            {stats.mTwo}
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </Hidden>

                            <Hidden smUp>
                                <ButtonGroup size='small' variant='contained'>
                                    {stats.two > 0 && (
                                        <Button
                                            color={filterState.two && 'primary'}
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'two',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    two: true,
                                                })
                                            }}
                                        >
                                            <PTwoPriorityIcon fontSize='small' />
                                        </Button>
                                    )}
                                    {stats.one > 0 && (
                                        <Button
                                            color={filterState.one && 'primary'}
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'one',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    one: true,
                                                })
                                            }}
                                        >
                                            <POnePriorityIcon fontSize='small' />
                                        </Button>
                                    )}
                                    {stats.zero > 0 && (
                                        <Button
                                            color={
                                                filterState.zero && 'primary'
                                            }
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'zero',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    zero: true,
                                                })
                                            }}
                                        >
                                            <ZeroPriorityIcon fontSize='small' />
                                        </Button>
                                    )}
                                    {stats.mOne > 0 && (
                                        <Button
                                            color={
                                                filterState.mOne
                                                    ? 'primary'
                                                    : 'disabled'
                                            }
                                            onClick={() => {
                                                dispatchFilter({
                                                    payload: 'mOne',
                                                    type: TOGGLE_FILTER,
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    mOne: true,
                                                })
                                            }}
                                        >
                                            <MOnePriorityIcon fontSize='small' />
                                        </Button>
                                    )}
                                    {stats.mTwo > 0 && (
                                        <Button
                                            color={
                                                filterState.mTwo && 'primary'
                                            }
                                            onClick={() => {
                                                dispatchFilter({
                                                    type: TOGGLE_FILTER,
                                                    payload: 'mTwo',
                                                })
                                                setPage(0)
                                            }}
                                            onContextMenu={(e) => {
                                                focusFilter(e, {
                                                    ...noPriorities,
                                                    mTwo: true,
                                                })
                                            }}
                                        >
                                            <MTwoPriorityIcon fontSize='small' />
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </Hidden>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={12} style={{ maxWidth: '870px' }}>
                    {pagesNumber > 1 && (
                        <Pagination
                            count={pagesNumber}
                            onChange={(e, value) => setPage(value - 1)}
                            page={page + 1}
                            style={{ marginBottom: '10px' }}
                        />
                    )}
                    {tasks.map((task) => {
                        return (
                            <TaskCard
                                task={task}
                                key={task._id}
                                isRunning={runningTask?._id === task._id}
                            />
                        )
                    })}
                    {pagesNumber > 1 && (
                        <Pagination
                            count={pagesNumber}
                            onChange={(e, value) => setPage(value - 1)}
                            page={page + 1}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default TasksList
