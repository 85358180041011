import { makeStyles, useMediaQuery, useTheme, Zoom } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import 'fontsource-roboto'
import { SnackbarProvider } from 'notistack'
import { useRoutes } from 'react-router-dom'
import routes from 'src/routes'
import ScrollToTop from './components/ScrollToTop'
import ModalsLayout from './layouts/ModalsLayout'
import EventListener from './stores/eventListener'
import { ModalsStateProvider } from './stores/modalsStore'
import Preloader from './stores/preloader'
import { ProjectsContextProvider } from './stores/projectsStore'
import { StableRouterContextProvider } from './stores/routerStore'
import useUser from './stores/useUser'
import { WidgetsContextProvider } from './stores/widgetsStore'

const useStyles = makeStyles({
    containerAnchorOriginBottomRight: {
        bottom: '73px',
    },
})

function App() {
    const classes = useStyles()
    const { userState } = useUser()
    var state = undefined
    if (userState.isLoggedIn === undefined) state = 'LOADING'
    if (userState.isLoggedIn === false) state = 'GUEST'
    if (userState.isLoggedIn === true) {
        if (userState.emailConfirmedAt === null) {
            state = 'EMAIL_CONFIRMATION'
        } else state = 'LOGGEDIN'
    }
    let routing = useRoutes(routes(state))
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <SnackbarProvider
            maxSnack={5}
            autoHideDuration={3000}
            // dense={true}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            TransitionComponent={Zoom}
            classes={{
                containerAnchorOriginBottomRight: isSmall
                    ? classes.containerAnchorOriginBottomRight
                    : '',
            }}
        >
            <ModalsStateProvider>
                <CssBaseline />
                <ScrollToTop />
                <ProjectsContextProvider>
                    <StableRouterContextProvider>
                        <Preloader />
                        <WidgetsContextProvider>
                            {routing}
                        </WidgetsContextProvider>
                        <ModalsLayout />
                    </StableRouterContextProvider>
                    <EventListener />
                </ProjectsContextProvider>
            </ModalsStateProvider>
        </SnackbarProvider>
    )
}

export default App
