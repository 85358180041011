interface HasId {
    _id: string
}

interface IdsStore {
    [key: string]: number
}


const updateArray = <T extends HasId>(state: Array<T>, update: Array<T>): Array<T> => {
    // Prepare array of _id's already in store
    const currentIds: IdsStore = {}
    state.forEach((item, index) => (currentIds[item._id] = index))
    update.forEach((item) => {
        if (item?._id !== undefined) {
            const index = currentIds[item._id]
            if (index === undefined) {
                state.push(item)
            } else {
                state[index] = item
            }
        }
    })
    return state
}

export { updateArray }
