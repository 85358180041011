import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import MarkdownRenderer from 'src/components/Common/MarkdownRenderer'
import { useLanguage } from 'src/stores/useLanguage'

interface FormattingHelpModalProps {
    onClose: () => {}
}

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline',
    },
})

const FormattingHelpModal = ({ onClose }: FormattingHelpModalProps) => {
    const { t } = useLanguage()

    const classes = useStyles()

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Dialog
            open={true}
            onClose={onClose}
            fullWidth
            maxWidth='xs'
            classes={isSmall ? { scrollPaper: classes.scrollPaper } : {}}
        >
            <DialogContent>
                <Typography>
                    {t('formattingHelpIntro') + ' '}
                    <a
                        href='https://github.github.com/gfm/'
                        target='_blank'
                        style={{ color: '#3f51b5' }}
                    >
                        {t('fullDocumentation')}
                    </a>
                </Typography>
                <MarkdownRenderer content={t('formattingHelpContent')} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('close')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormattingHelpModal
