import {
    Grid,
    MenuList,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import { useContext } from 'react'
import useStableNavigate from 'src/hooks/useStableNavigate'
import { ProjectsContext } from 'src/stores/projectsStore'
import { useLanguage } from 'src/stores/useLanguage'
import useProjects from 'src/stores/useProjects'

const PinnedProjectsView = () => {
    const { state: projectsState } = useContext(ProjectsContext)
    const { getProjectsLine } = useProjects()
    const { t } = useLanguage()
    const navigate = useStableNavigate()

    const pinnedProjects = projectsState.projects.filter(
        (project) => project.status === 'active' && project.favorite
    )

    if (pinnedProjects.length === 0)
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5' color='primary'>
                        <BookmarkIcon
                            fontSize='large'
                            style={{ marginBottom: -9 }}
                        />{' '}
                        {t('pinnedProjects')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                    <Typography variant='body2' color='textSecondary'>
                        {t('noPinnedProjects')}
                    </Typography>
                </Grid>
            </Grid>
        )

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5' color='primary'>
                    <BookmarkIcon
                        fontSize='large'
                        style={{ marginBottom: -9 }}
                    />{' '}
                    {t('pinnedProjects')}
                </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
                <Table>
                    <TableBody>
                        {pinnedProjects.map((project) => (
                            <TableRow
                                hover
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    navigate(
                                        `/app/projects/${project._id}/tasks`
                                    )
                                }
                            >
                                <TableCell>
                                    <Typography>{project.name}</Typography>
                                    <Typography
                                        variant='body2'
                                        color='textSecondary'
                                    >
                                        {getProjectsLine(project.parent_id)
                                            .map((project) => project.name)
                                            .join(' / ')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <MenuList></MenuList>
            </Grid>
        </Grid>
    )
}

export default PinnedProjectsView
