import { useEffect } from 'react'

const LoginSuccess = () => {
    useEffect(() => {
        window.opener.postMessage('success', "*")
        window.close()
    }, [])
    return null
}

export default LoginSuccess
