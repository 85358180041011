import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { differenceInCalendarDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { apiFetchPeriodStats } from 'src/api/stats'
import { useLanguage } from 'src/stores/useLanguage'
import { dateToToken, formatDurationFromSeconds } from 'src/utils'

interface TotalTasksStatsCardProps {
    timeFrom: Date
    timeTo: Date
    fetchedAt: Date
    disableMoveUp?: boolean
}

const StyledTableCell = (props) => {
    const {
        noBorder,
        bgSize,
        noWrap,
        children,
        overflowWrap,
        style: styleProp,
        ...rest
    } = props
    let style = {}
    if (noBorder) {
        style['borderBottom'] = 'none'
    }
    if (overflowWrap) {
        style['wordBreak'] = 'break-word'
        style['overflowWrap'] = 'break-word'
    }
    if (bgSize) {
        style['backgroundSize'] = `${bgSize}% 100%`
        style['backgroundImage'] =
            'linear-gradient(to bottom, #EEE 0%, #EEE 100%,transparent 0)'
        style['backgroundRepeat'] = 'no-repeat'
    }
    if (noWrap) {
        style['whiteSpace'] = 'nowrap'
    }
    if (styleProp) {
        style = { ...style, ...styleProp }
    }

    return (
        <TableCell style={style} {...rest}>
            {children}
        </TableCell>
    )
}

const TotalTasksStatsCard = (props: TotalTasksStatsCardProps) => {
    const { timeFrom, timeTo, fetchedAt, disableMoveUp } = props
    const [isLoading, setIsLoading] = useState()
    const [stats, setStats] = useState({
        time: { manual: 0, timer: 0 },
        tasks: { active: 0, done: 0, created: 0, completed: 0 },
    })
    const { t } = useLanguage()

    useEffect(() => {
        const fetchPeriodStats = async () => {
            setIsLoading(true)
            const response = await apiFetchPeriodStats({
                time_from: timeFrom,
                time_to: timeTo,
                date_from: dateToToken(timeFrom),
                date_to: dateToToken(timeTo),
            })
            if (response.status === 200 && response.data.data) {
                setStats(response.data.data)
            }
            setIsLoading(false)
        }
        fetchPeriodStats()
    }, [
        timeTo && timeTo.toISOString(),
        timeFrom && timeFrom.toISOString(),
        fetchedAt,
    ])

    const daysNum = differenceInCalendarDays(timeTo, timeFrom) + 1

    const totalTime = stats.time.manual + stats.time.timer
    var manualTimeShare = 0
    var timerTimeShare = 0
    if (totalTime > 0) {
        manualTimeShare = Math.floor((stats.time.manual / totalTime) * 100)
        timerTimeShare = 100 - manualTimeShare
    }

    if (isLoading)
        return (
            <>
                <Skeleton height={48} />
                <Skeleton height={48} />
                <Skeleton height={48} />
                <Skeleton height={48} />
                <Skeleton height={48} />
                <Skeleton height={48} />
            </>
        )

    return (
        <TableContainer
            style={{
                marginTop: disableMoveUp ? undefined : daysNum === 1 ? 0 : -32,
            }}
        >
            <Table size='small'>
                {daysNum <= 1 ? null : (
                    <TableHead>
                        <TableRow>
                            <StyledTableCell noBorder></StyledTableCell>
                            <StyledTableCell noBorder noWrap>
                                <Typography>
                                    <b>{t('perDay')}</b>
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell noBorder>
                                <Typography>
                                    <b>{t('total')}</b>
                                </Typography>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>
                    <TableRow hover>
                        <StyledTableCell noBorder style={{ width: '100%' }}>
                            <Typography>
                                <b>{t('timeRecorded')}</b>
                            </Typography>
                        </StyledTableCell>
                        {daysNum <= 1 ? null : (
                            <StyledTableCell noBorder align='center'>
                                <Typography>
                                    {formatDurationFromSeconds(
                                        totalTime / daysNum,
                                        false
                                    )}
                                </Typography>
                            </StyledTableCell>
                        )}
                        <StyledTableCell noBorder align='center'>
                            <Typography>
                                {formatDurationFromSeconds(totalTime, false)}{' '}
                            </Typography>
                        </StyledTableCell>
                    </TableRow>

                    <TableRow hover>
                        <StyledTableCell
                            size='small'
                            noBorder
                            bgSize={timerTimeShare}
                            style={{ paddingLeft: '32px' }}
                        >
                            <Typography>
                                {t('withTimer') + ' – ' + timerTimeShare + '%'}
                            </Typography>
                        </StyledTableCell>
                        {daysNum <= 1 ? null : (
                            <StyledTableCell
                                size='small'
                                noBorder
                                align='center'
                            >
                                <Typography>
                                    {formatDurationFromSeconds(
                                        stats.time.timer / daysNum,
                                        false
                                    )}
                                </Typography>
                            </StyledTableCell>
                        )}
                        <StyledTableCell size='small' noBorder align='center'>
                            <Typography>
                                {formatDurationFromSeconds(
                                    stats.time.timer,
                                    false
                                )}
                            </Typography>
                        </StyledTableCell>
                    </TableRow>

                    <TableRow hover>
                        <StyledTableCell
                            size='small'
                            bgSize={manualTimeShare}
                            style={{ paddingLeft: '32px' }}
                        >
                            <Typography>
                                {t('manually') + ' – ' + manualTimeShare + '%'}
                            </Typography>
                        </StyledTableCell>
                        {daysNum <= 1 ? null : (
                            <StyledTableCell size='small' align='center'>
                                <Typography>
                                    {formatDurationFromSeconds(
                                        stats.time.manual / daysNum,
                                        false
                                    )}
                                </Typography>
                            </StyledTableCell>
                        )}
                        <StyledTableCell size='small' align='center'>
                            <Typography>
                                {formatDurationFromSeconds(
                                    stats.time.manual,
                                    false
                                )}
                            </Typography>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell noBorder colSpan={3}>
                            <Typography>
                                <b>{t('activity')}</b>
                            </Typography>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow hover>
                        <StyledTableCell
                            noBorder
                            style={{ paddingLeft: '32px' }}
                        >
                            <Typography>{t('createdTasks')}</Typography>
                        </StyledTableCell>
                        {daysNum <= 1 ? null : (
                            <StyledTableCell noBorder align='center'>
                                <Typography>
                                    {(stats.tasks.created / daysNum).toFixed(1)}
                                </Typography>
                            </StyledTableCell>
                        )}
                        <StyledTableCell noBorder align='center'>
                            <Typography>{stats.tasks.created}</Typography>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow hover>
                        <StyledTableCell style={{ paddingLeft: '32px' }}>
                            <Typography>{t('completedTasks')}</Typography>
                        </StyledTableCell>
                        {daysNum <= 1 ? null : (
                            <StyledTableCell align='center'>
                                <Typography>
                                    {(stats.tasks.completed / daysNum).toFixed(
                                        1
                                    )}
                                </Typography>
                            </StyledTableCell>
                        )}
                        <StyledTableCell align='center'>
                            <Typography>{stats.tasks.completed}</Typography>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell noBorder colSpan={3}>
                            <Typography>
                                <b>{t('tasksStatuses')}</b>
                            </Typography>
                        </StyledTableCell>
                    </TableRow>

                    <TableRow hover>
                        <StyledTableCell
                            noBorder
                            style={{ paddingLeft: '32px' }}
                            colSpan={daysNum === 1 ? 1 : 2}
                        >
                            <Typography>{t('active')}</Typography>
                        </StyledTableCell>
                        <StyledTableCell noBorder align='center'>
                            <Typography>{stats.tasks.active}</Typography>
                        </StyledTableCell>
                    </TableRow>

                    <TableRow hover>
                        <StyledTableCell
                            noBorder
                            style={{ paddingLeft: '32px' }}
                            colSpan={daysNum === 1 ? 1 : 2}
                        >
                            <Typography>{t('done')}</Typography>
                        </StyledTableCell>

                        <StyledTableCell noBorder align='center'>
                            <Typography>{stats.tasks.done}</Typography>
                        </StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TotalTasksStatsCard
export { StyledTableCell }
