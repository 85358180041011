import { apiCall } from './base'

const BASE_URL = '/api/v1/tasks/'

const apiCreateTask = async (task) => {
    return await apiCall({
        method: 'POST',
        url: BASE_URL,
        data: task,
    })
}

const apiFetchTasks = async (params = {}) => {
    return await apiCall({
        method: 'GET',
        url: BASE_URL,
        params,
    })
}

const apiDeleteTask = async (id) => {
    return await apiCall({
        method: 'DELETE',
        url: BASE_URL + id + '/',
    })
}

const apiUpdateTask = async (id, changes) => {
    return await apiCall({
        method: 'PUT',
        url: BASE_URL + id + '/',
        data: changes,
    })
}

const apiFetchTask = async (id) => {
    return await apiCall({
        method: 'GET',
        url: BASE_URL + id + '/',
    })
}

export {
    apiCreateTask,
    apiFetchTasks,
    apiDeleteTask,
    apiUpdateTask,
    apiFetchTask,
}
