import { Grid } from '@material-ui/core'
import TodayIcon from '@material-ui/icons/Today'
import { addDays } from 'date-fns'
import TasksList from 'src/components/TasksList'
import { useLanguage } from 'src/stores/useLanguage'
import useTasks from 'src/stores/useTasks'
import { dateToToken, tokenToDate } from 'src/utils'

const TomorrowTasksView = () => {
    const { todayToken } = useTasks()
    const { t } = useLanguage()

    const tomorrow_token = dateToToken(addDays(tokenToDate(todayToken), 1))
    const query = { date_token: tomorrow_token }

    return (
        <Grid container>
            <Grid item xs={12} md={8} lg={6}>
                <TasksList
                    query={query}
                    tasksPerPage={50}
                    title={t('tomorrow')}
                    listName='tomorrow'
                    icon={
                        <TodayIcon
                            fontSize='large'
                            style={{ marginBottom: -9 }}
                        />
                    }
                />
            </Grid>
        </Grid>
    )
}

export default TomorrowTasksView
