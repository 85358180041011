import {
    Avatar,
    Box,
    Button,
    Divider,
    FormHelperText,
    Grid,
    Link,
    TextField,
    Typography,
} from '@material-ui/core'
import { Formik } from 'formik'
import { useState } from 'react'
import { apiLogIn } from 'src/api/auth'
import NavLink from 'src/components/NavLink'
// import { apiFetchUserSelf } from 'src/api/users'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'

const LoginView = () => {
    const { t } = useLanguage()
    const [serverError, setServerError] = useState(null)
    const { logIn } = useUser()

    const openWindow = () => {
        window.open(
            process.env.REACT_APP_GOOGLE_LOGIN_API_URL,
            '_blank',
            `width=600,height=700,left=${(window.innerWidth - 600) / 2},top=${
                (window.innerHeight - 700) / 2
            }`
        )
        window.addEventListener(
            'message',
            (event) => {
                if (
                    event.origin !==
                    process.env.REACT_APP_GOOGLE_LOGIN_WINDOW_ORIGIN
                )
                    return
                if (event.data === 'success') {
                    window.location.reload()
                }
            },
            false
        )
    }

    return (
        <Box mx='auto' maxWidth={500}>
            <Grid container spacing={1} justifyContent='center'>
                <Grid item>
                    <Typography variant='h5'>{t('login')}</Typography>
                </Grid>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validateOnBlur
                    // validateOnChange={false}
                    validate={(values) => {
                        const errors = {}
                        if (!values.password) {
                            errors.password = 'errPasswordCannotBeEmpty'
                        }
                        if (!values.email) {
                            errors.email = 'errEmailCannotBeEmpty'
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(
                                values.email
                            )
                        ) {
                            errors.email = t('errWrongEmailFormat')
                        }
                        return errors
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const response = await apiLogIn(
                            values.email,
                            values.password
                        )
                        if (response.status === 200) {
                            logIn(response.data.data)
                        } else {
                            setServerError('errWrongCredentials')
                        }
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        errors,
                        touched,
                        isSubmitting,
                        handleSubmit,
                    }) => (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    error={touched.email && errors.email}
                                    label={t('email')}
                                    name='email'
                                    type='email'
                                    data-test='login-form-email'
                                    value={values.email}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setServerError(null)
                                    }}
                                    helperText={
                                        touched.email && t(errors.email)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('password')}
                                    error={touched.password && errors.password}
                                    type='password'
                                    name='password'
                                    data-test='login-form-password'
                                    value={values.password}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setServerError(null)
                                    }}
                                    helperText={
                                        touched.password && t(errors.password)
                                    }
                                />
                            </Grid>
                            {/* <Grid item xs={12}> */}
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent='flex-end'
                            >
                                <Grid item>
                                    <NavLink
                                        data-test='login-form-restore-password-ref'
                                        to='/request-restore-password'
                                        text={t('restorePassword')}
                                        color='textSecondary'
                                    />
                                </Grid>
                            </Grid>
                            {/* </Grid> */}
                            {serverError ? (
                                <Grid item>
                                    <FormHelperText error>
                                        {t(serverError)}
                                    </FormHelperText>
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Button
                                    color='primary'
                                    fullWidth
                                    variant='contained'
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    data-test='login-form-submit-btn'
                                >
                                    {t('login')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Formik>
                <Grid
                    item
                    fullWidth
                    container
                    spacing={1}
                    xs={12}
                    alignContent='center'
                    justifyContent='center'
                    alignItems='center'
                >
                    {/* <Grid container fullWidth alignItems='center' spacing={1}> */}
                    <Grid item style={{ flexGrow: 1 }}>
                        <Divider fullWidth />
                    </Grid>
                    <Grid item>
                        <Typography
                            align='center'
                            style={{ marginTop: '-2.5px' }}
                        >
                            {t('or')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Divider fullWidth />
                    </Grid>
                    {/* </Grid> */}
                </Grid>
                <Grid item justifyContent='center'>
                    <Button
                        data-test='google-sign-in-button'
                        startIcon={
                            <Avatar
                                style={{ width: '20px', height: '20px' }}
                                src={'/google-logo.svg'}
                            />
                        }
                        variant='outlined'
                        onClick={
                            openWindow
                            //     () => {
                            //     window.location =
                            //         'https://app.todox.online/api/v1/auth/google/login/'
                            // }
                        }
                    >
                        {t('signInWithGoogle')}
                    </Button>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item>
                    {t('DoNotHaveAnAccount?')}{' '}
                    <NavLink
                        data-test='login-form-sign-up-ref'
                        to='/register'
                        text={t('register')}
                    />
                </Grid>

                {/* Regulator's position on PD concent https://www.priv.gc.ca/en/privacy-topics/collecting-personal-information/consent/gl_omc_201805/ */}
                <Grid item xs={12} style={{ marginTop: '60px' }}>
                    <p align='center'>
                        {t('byContinuingYouAgreeTo')}{' '}
                        <Link
                            href='https://todox.online/legal?utm_source=app&utm_medium=registration
'
                            target='_blank'
                        >
                            {t('here')}
                        </Link>
                        .
                    </p>
                </Grid>
                {/* <Grid item alignItems='center'>
                    or{' '}
                    <img
                        src='/google-sign-in.png'
                        width='200'
                        onClick={() => {
                            window.location =
                                'http://localhost:8042/api/v1/auth/google/login/'
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default LoginView
