import LoopIcon from '@material-ui/icons/Loop'
import { useState } from 'react'
import ActiveText from 'src/components/Common/ActiveText'
import TimePresetDropdown from 'src/components/Common/TimePresetDropdown'
// import TimeStatsByTask from 'src/components/Common/TimeStatsByTask'
import TotalTasksStatsCard from 'src/components/Stats/TotalTasksStatsCard'
import { useLanguage } from 'src/stores/useLanguage'
// import useTasks from 'src/stores/useTasks'
import { presetToDates } from 'src/timePresetUtils'
import type { TimePreset } from 'src/typing'
import { WidgetComponentProps } from '../BaseWidget'

const GeneralStatsWidget = (props: WidgetComponentProps) => {
    var { time_preset: preset, onChange } = props
    // const [isLoading, setIsLoading] = useState(false)
    // const { fetchTasks } = useTasks()
    // const [tasks, setTasks] = useState<Task[]>([])
    const { getDateLocale } = useLanguage()
    const [fetchedAt, setFetchedAt] = useState(new Date())

    var dateFrom: Date | null = null,
        dateTo: Date | null = null

    const presetResult = presetToDates(preset, getDateLocale())

    if (presetResult !== undefined) [dateFrom, dateTo] = presetResult

    if (!dateFrom || !dateTo) return null

    return (
        <>
            <TimePresetDropdown
                value={preset}
                onChange={(preset: TimePreset) =>
                    onChange({ time_preset: preset })
                }
            />

            <ActiveText
                // text={'refresh'}
                style={{ marginLeft: 8 }}
                onClick={() => {
                    setFetchedAt(new Date())
                }}
                icon={
                    <LoopIcon
                        style={{ fontSize: '1rem', marginBottom: '-4px' }}
                    />
                }
            />
            <TotalTasksStatsCard
                timeFrom={dateFrom}
                timeTo={dateTo}
                fetchedAt={fetchedAt}
                // tasks={tasks}
                // isLoading={isLoading}
            />
        </>
    )
}

export default GeneralStatsWidget
