import { apiCall } from './base'

const apiLogIn = async (email, password) => {
    return await apiCall({
        method: 'POST',
        url: '/api/v1/auth/login/',
        data: { email, password },
    })
}

const apiRegister = async (email, password, language, invite) => {
    return await apiCall({
        method: 'POST',
        url: '/api/v1/auth/register/',
        data: { email, password, language, invite },
    })
}

const apiRefresh = async () => {
    return await apiCall({
        method: 'GET',
        url: '/api/v1/auth/refresh/',
    })
}

const apiLogOut = async () => {
    return await apiCall({
        method: 'GET',
        url: '/api/v1/auth/logout/',
    })
}

const apiRequestEmailConfirmation = async () => {
    return await apiCall({
        method: 'GET',
        url: '/api/v1/auth/request-confirm/',
    })
}

const apiConfirmEmail = async (email, token) => {
    return await apiCall({
        method: 'PUT',
        url: '/api/v1/auth/confirm-email/',
        data: { email, email_confirmation_token: token },
    })
}

const apiRequestRestorePasswordEmail = async (email) => {
    return await apiCall({
        method: 'POST',
        url: '/api/v1/auth/request-password-restore-token/',
        data: { email },
    })
}

const apiValidateRestorePasswordToken = async (email, token) => {
    return await apiCall({
        method: 'PUT',
        url: '/api/v1/auth/validate-password-restore-token/',
        data: { email, token },
    })
}

const apiRestorePassword = async (email, token, newPassword) => {
    return await apiCall({
        method: 'POST',
        url: '/api/v1/auth/restore-password/',
        data: { email, token, new_password: newPassword },
    })
}

export {
    apiLogIn,
    apiRegister,
    apiLogOut,
    apiRefresh,
    apiRequestEmailConfirmation,
    apiConfirmEmail,
    apiRequestRestorePasswordEmail,
    apiValidateRestorePasswordToken,
    apiRestorePassword,
}
