import type { WidgetInCreate, WidgetInUpdate } from 'src/typing'
import { apiCall } from './base'

const BASE_URL = '/api/v1/widgets/'


const apiCreateWidget = async (widget: WidgetInCreate) => {
    return await apiCall({
        method: 'POST',
        url: BASE_URL,
        data: widget
    })
}


const apiFetchWidgetsGroup = async (group: string) => {
    return await apiCall({
        method: 'GET',
        url: BASE_URL,
        params: {group}
    })
}

const apiUpdateWidget = async (widgetId: string, changes: WidgetInUpdate) => {
    return await apiCall({
        method: 'PUT',
        url: `${BASE_URL}${widgetId}/`,
        data: changes
    })
}

const apiDeleteWidget = async (widgetId: string) => {
    return await apiCall({
        method: 'DELETE',
        url: `${BASE_URL}${widgetId}/`,
    })
}

export { apiCreateWidget, apiFetchWidgetsGroup, apiUpdateWidget, apiDeleteWidget }
