import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    Link,
    TextField,
    Typography,
} from '@material-ui/core'
import { Formik } from 'formik'
import { useState } from 'react'
import { apiRegister } from 'src/api/auth'
import NavLink from 'src/components/NavLink'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'

const RegisterView = () => {
    const [serverError, setServerError] = useState(null)
    const { t } = useLanguage()
    const { userState } = useUser()
    const [isSuccess, setIsSuccess] = useState(false)

    const openWindow = () => {
        window.open(
            process.env.REACT_APP_GOOGLE_LOGIN_API_URL,
            '_blank',
            `width=600,height=700,left=${(window.innerWidth - 600) / 2},top=${
                (window.innerHeight - 700) / 2
            }`
        )
        window.addEventListener(
            'message',
            (event) => {
                if (
                    event.origin !==
                    process.env.REACT_APP_GOOGLE_LOGIN_WINDOW_ORIGIN
                )
                    return
                if (event.data === 'success') {
                    window.location.reload()
                }
            },
            false
        )
    }

    if (isSuccess) {
        return (
            <Box mx='auto' maxWidth={500}>
                <Grid container spacing={1} justifyContent='center'>
                    <Grid item>
                        <Typography variant='h5'>{t('register')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography data-test='sign-up-form-success'>
                            {t('successRegistrationCheckEmailAndLogin')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box mx='auto' maxWidth={500} data-test='sign-up-form'>
            <Grid container spacing={1} justifyContent='center'>
                <Grid item>
                    <Typography variant='h5'>{t('register')}</Typography>
                </Grid>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        passwordConfirm: '',
                        // agree_to_tos: false,
                    }}
                    validateOnBlur
                    // validateOnChange={false}
                    validate={(values) => {
                        const errors = {}
                        if (!values.password) {
                            errors.password = 'errPasswordCannotBeEmpty'
                        }
                        if (values.password !== values.passwordConfirm) {
                            errors.passwordConfirm = 'errPasswordsDoNotMatch'
                        }
                        if (values.password.length < 8) {
                            errors.password = 'errNewPasswordTooShort'
                        }

                        if (!values.email) {
                            errors.email = 'errEmailCannotBeEmpty'
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(
                                values.email
                            )
                        ) {
                            errors.email = 'errWrongEmailFormat'
                        }

                        // if (!values.agree_to_tos) {
                        //     errors.agree_to_tos =
                        //         'toProceedYouNeedToAgreeToUserAgreement'
                        // }

                        return errors
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const response = await apiRegister(
                            values.email,
                            values.password,
                            userState.language,
                            ''
                        )
                        if (response.status === 201) {
                            setIsSuccess(true)
                        } else if (response.status === 409) {
                            setServerError(
                                'emailAlreadyRegisteredSuggestRestorePassword'
                            )
                        } else {
                            setServerError('serverError')
                        }
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        errors,
                        touched,
                        isSubmitting,
                        handleSubmit,
                    }) => (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    error={touched.email && errors.email}
                                    label={t('email')}
                                    name='email'
                                    type='email'
                                    data-test='sign-up-form-email'
                                    value={values.email}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setServerError(null)
                                    }}
                                    helperText={
                                        touched.email && t(errors.email)
                                    }
                                    FormHelperTextProps={{
                                        'data-test': 'sign-up-form-email-error',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('password')}
                                    error={touched.password && errors.password}
                                    type='password'
                                    name='password'
                                    data-test='sign-up-form-password'
                                    value={values.password}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setServerError(null)
                                    }}
                                    helperText={
                                        touched.password && t(errors.password)
                                    }
                                    FormHelperTextProps={{
                                        'data-test':
                                            'sign-up-form-password-error',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('passwordRepeat')}
                                    error={
                                        touched.passwordConfirm &&
                                        errors.passwordConfirm
                                    }
                                    type='password'
                                    name='passwordConfirm'
                                    data-test='sign-up-form-password-confirm'
                                    value={values.passwordConfirm}
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setServerError(null)
                                    }}
                                    helperText={
                                        touched.passwordConfirm &&
                                        t(errors.passwordConfirm)
                                    }
                                    FormHelperTextProps={{
                                        'data-test':
                                            'sign-up-form-password-confirm-error',
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name='agree_to_tos'
                                            onChange={(e) => {
                                                handleChange(e)
                                                setServerError(null)
                                            }}
                                            checked={values.agree_to_tos}
                                            color='primary'
                                            onBlur={handleBlur}
                                            data-test='sign-up-form-agree-to-tos'
                                        />
                                    }
                                    label={
                                        <>
                                            {t('iAgreeTo')}{' '}
                                            <Link
                                                href='https://todox.online/legal?utm_source=app&utm_medium=registration
'
                                                target='_blank'
                                            >
                                                {t('withUserAgreement')}
                                            </Link>
                                        </>
                                    }
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setServerError(null)
                                    }}
                                    name='agree_to_tos'
                                />
                                {errors.agree_to_tos && touched.agree_to_tos && (
                                    <Grid item xs={12}>
                                        <FormHelperText
                                            error
                                            data-test='sign-up-form-agree-to-tos-error'
                                        >
                                            {t(errors.agree_to_tos)}
                                        </FormHelperText>
                                    </Grid>
                                )}
                            </Grid> */}
                            {serverError ? (
                                <Grid item>
                                    <FormHelperText error>
                                        {t(serverError)}
                                    </FormHelperText>
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Button
                                    color='primary'
                                    fullWidth
                                    variant='contained'
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    data-test='sign-up-form-submit-btn'
                                >
                                    {t('register')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Formik>
                <Grid
                    item
                    container
                    fullWidth
                    spacing={1}
                    xs={12}
                    alignContent='center'
                    justifyContent='center'
                    alignItems='center'
                >
                    {/* <Grid container fullWidth alignItems='center' spacing={1}> */}
                    <Grid item style={{ flexGrow: 1 }}>
                        <Divider fullWidth />
                    </Grid>
                    <Grid item>
                        <Typography
                            align='center'
                            style={{ marginTop: '-2.5px' }}
                        >
                            {t('or')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Divider fullWidth />
                    </Grid>
                    {/* </Grid> */}
                </Grid>
                <Grid item justifyContent='center'>
                    <Button
                        data-test='google-sign-up-button'
                        startIcon={
                            <Avatar
                                style={{ width: '20px', height: '20px' }}
                                src={'/google-logo.svg'}
                            />
                        }
                        variant='outlined'
                        onClick={
                            openWindow
                            //     () => {
                            //     window.location =
                            //         'https://app.todox.online/api/v1/auth/google/login/'
                            // }
                        }
                    >
                        {t('signUpWithGoogle')}
                    </Button>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item>
                    {t('AlreadyHaveAnAccount?')}{' '}
                    <NavLink
                        to='/login'
                        text={t('login')}
                        data-test='sign-up-form-sign-in-ref'
                    />
                </Grid>
                {/* Regulator's position on PD concent https://www.priv.gc.ca/en/privacy-topics/collecting-personal-information/consent/gl_omc_201805/ */}
                <Grid xs={12} item style={{ marginTop: '60px' }}>
                    <p align='center'>
                        {t('byContinuingYouAgreeTo')}{' '}
                        <Link
                            href='https://todox.online/legal?utm_source=app&utm_medium=registration
'
                            target='_blank'
                        >
                            {t('here')}
                        </Link>
                        .
                    </p>
                </Grid>
            </Grid>
        </Box>
    )
}

export default RegisterView
