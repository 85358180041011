import { createContext, Dispatch, FC, useMemo, useReducer } from 'react'
import { Project } from 'src/typing'
import { updateArray } from './storeTools'

interface ProjectInStore extends Project {
    depth: number
    subProjectsIds: string[]
    visible: boolean
}

interface ProjectInStoreTree extends ProjectInStore {
    subProjects: ProjectInStoreTree[]
}

interface ProjectsStore {
    projects: ProjectInStore[]
    projectsTree: ProjectInStoreTree[]
}

enum ProjectsStoreActionType {
    UPDATE_STORE,
    DELETE,
    CLEAR_STORE,
}

interface UpdateProjectsStoreAction {
    type: ProjectsStoreActionType.UPDATE_STORE
    payload: Project[]
}

interface DeleteProjectAction {
    type: ProjectsStoreActionType.DELETE
    payload: {
        deletedProjectId: string
        updatedProjects: Project[]
    }
}

interface ClearProjectsStore {
    type: ProjectsStoreActionType.CLEAR_STORE
    payload?: undefined
}

type ProjectsStoreAction =
    | UpdateProjectsStoreAction
    | DeleteProjectAction
    | ClearProjectsStore

const initProjectsState = (): ProjectsStore => {
    return {
        projects: [],
        projectsTree: [],
    }
}

const buildProjectsTrees = (
    projects: Project[]
): [ProjectInStoreTree[], ProjectInStore[]] => {
    projects.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
    })
    const gatherSubProjects = (
        parentId: string,
        projects: Project[],
        depth: number,
        parentVisible: boolean = true
    ): [ProjectInStoreTree[], string[]] => {
        const subProjects: ProjectInStoreTree[] = []
        var subProjectsIds: string[] = []
        projects.forEach((project) => {
            if (project.parent_id === parentId) {
                subProjectsIds.push(project._id)
                let visible = parentVisible
                if (project.status !== 'active') visible = false

                // Leave space for current project to have it before its sub-projects
                let flatTreeIndex = flatProjectsTree.length
                // @ts-ignore
                flatProjectsTree.push(null)

                let [subSubProjects, subSubProjectsIds] = gatherSubProjects(
                    project._id,
                    projects,
                    depth + 1,
                    visible
                )

                subProjectsIds = subProjectsIds.concat(subSubProjectsIds)

                subProjects.push({
                    ...project,
                    depth,
                    visible,
                    subProjects: subSubProjects,
                    subProjectsIds: subSubProjectsIds,
                })

                flatProjectsTree[flatTreeIndex] = {
                    ...project,
                    depth,
                    visible,
                    subProjectsIds: subSubProjectsIds,
                }
            }
        })
        return [subProjects, subProjectsIds]
    }

    const projectsTree: ProjectInStoreTree[] = []
    const flatProjectsTree: ProjectInStore[] = []
    projects.forEach((project) => {
        if (project.parent_id === null) {
            let visible = project.status === 'active'
            // Leave place for parent root project on top
            let flatTreeIndex = flatProjectsTree.length
            // @ts-ignore
            flatProjectsTree.push(null)

            let [subProjects, subProjectsIds] = gatherSubProjects(
                project._id,
                projects,
                1,
                visible
            )
            projectsTree.push({
                ...project,
                depth: 0,
                subProjects,
                subProjectsIds,
                visible,
            })
            flatProjectsTree[flatTreeIndex] = {
                ...project,
                depth: 0,
                visible,
                subProjectsIds,
            }
        }
    })

    return [projectsTree, flatProjectsTree]
}

const projectsStoreReducer = (
    state: ProjectsStore,
    action: ProjectsStoreAction
): ProjectsStore => {
    const { type, payload } = action
    switch (type) {
        case ProjectsStoreActionType.UPDATE_STORE: {
            let [projectsTree, projectsFlatTree] = buildProjectsTrees(
                updateArray(state.projects, payload)
            )
            return {
                ...state,
                projects: projectsFlatTree,
                projectsTree,
                // loadedAt: format(new Date(), 'yyyy-MM-dd-HH-mm-ss'),
            }
        }
        case ProjectsStoreActionType.DELETE: {
            let {
                // updatedTasks,
                updatedProjects,
                deletedProjectId,
            } = payload
            let [projectsTree, projectsFlatTree] = buildProjectsTrees(
                updateArray(
                    state.projects.filter(
                        (project) => project._id !== deletedProjectId
                    ),
                    updatedProjects
                )
            )
            return {
                ...state,
                projects: projectsFlatTree,
                projectsTree,
                // tasks: updateArray(state.tasks, updatedTasks),
                // loadedAt: format(new Date(), 'yyyy-MM-dd-HH-mm-ss'),
            }
        }
        case ProjectsStoreActionType.CLEAR_STORE: {
            return initProjectsState()
        }
    }
}

const ProjectsContext = createContext<{
    state: ProjectsStore
    dispatch: Dispatch<ProjectsStoreAction>
}>({
    state: initProjectsState(),
    dispatch: () => {},
})

const ProjectsContextProvider: FC = (props) => {
    const [state, dispatch] = useReducer(
        projectsStoreReducer,
        undefined,
        initProjectsState
    )

    const contextValue = useMemo(() => ({ state, dispatch }), [state])

    return (
        <ProjectsContext.Provider value={contextValue}>
            {props.children}
        </ProjectsContext.Provider>
    )
}

export { ProjectsContext, ProjectsContextProvider, ProjectsStoreActionType }
export type { ProjectInStore }
