import {
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    // DialogTitle,
    // DialogActions,
    // Button,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
// import React from 'react'
import ProjectSelectDropdown from '../../../components/ProjectSelectDropdown'

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline',
    },
})

const ChangeTaskProjectModal = (props) => {
    const { task, handleClose, handleSave, projects } = props
    const classes = useStyles()
    const { openTaskProjectModal, openCreateProjectModal } = useModals()

    const { t } = useLanguage()

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const handleChange = (project) => {
        handleSave(project._id)
        handleClose()
    }

    const handleCreateNewProject = (project) => {
        if (project !== null) {
            handleSave(project._id)
            // handleClose()
        }
    }

    const handleCancelCreateProject = () => {
        openTaskProjectModal(task)
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth='xs'
            transitionDuration={0}
            classes={isSmall ? { scrollPaper: classes.scrollPaper } : {}}
            data-test='modal-change-project'
        >
            <DialogContent>
                <Grid container xs={12} alignItems='center'>
                    <Grid item style={{ flexGrow: 1 }}>
                        <ProjectSelectDropdown
                            autoFocus
                            handleChange={handleChange}
                            projects={projects}
                            projectId={task.project_id}
                            label={t('project')}
                            nullOptionLabel={t('noProject')}
                        />
                    </Grid>
                    <Grid>
                        <IconButton
                            data-test='create-project-btn'
                            size='small'
                            style={{ marginRight: -12, marginLeft: 4 }}
                            onClick={() => {
                                openCreateProjectModal(
                                    {},
                                    {
                                        handleCancel: handleCancelCreateProject,
                                        callAfterCreate: handleCreateNewProject,
                                    }
                                )
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ChangeTaskProjectModal
