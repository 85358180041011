import HistoryIcon from '@material-ui/icons/History'
import TasksList from 'src/components/TasksList'
import { useLanguage } from 'src/stores/useLanguage'

const ChronologicalView = () => {
    const query = { sort_by: 'updated_at', sort_dir: -1, limit: 100 }

    const { t } = useLanguage()

    const sortByUpdatedAt = (a, b) => {
        if (a.updated_at < b.updated_at) {
            return 1
        } else if (a.updated_at > b.updated_at) {
            return -1
        }
        return 0
    }

    return (
        <TasksList
            query={query}
            sortFunction={sortByUpdatedAt}
            showDone={true}
            title={t('chronology')}
            listName='chronology'
            icon={<HistoryIcon fontSize='large' style={{ marginBottom: -9 }} />}
        />
    )
}

export default ChronologicalView
