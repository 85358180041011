import {
    alpha,
    AppBar,
    Avatar,
    Card,
    CardContent,
    Grid,
    Hidden,
    InputAdornment,
    InputBase,
    makeStyles,
    Menu,
    MenuItem,
    Slide,
    Toolbar,
    Typography,
    withStyles,
} from '@material-ui/core'
import BackspaceIcon from '@material-ui/icons/Backspace'
import DateRangeIcon from '@material-ui/icons/DateRange'
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone'
import LoopIcon from '@material-ui/icons/Loop'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PersonIcon from '@material-ui/icons/Person'
import { format } from 'date-fns'
import { memo, useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiLogOut } from 'src/api/auth'
import { formatTaskRepeatString } from 'src/components/TaskCardRepeatInfo'
import parseDate from 'src/parsers/dateParser'
import { en as dateRulesEn } from 'src/parsers/dateParser/locales'
import parsePriority from 'src/parsers/priorityParser'
import { en as priorityRulesEn } from 'src/parsers/priorityParser/locales'
import { useLanguage } from 'src/stores/useLanguage'
import useLogOut from 'src/stores/useLogOut'
import useModals from 'src/stores/useModals'
import useProjects from 'src/stores/useProjects'
import useTasks from 'src/stores/useTasks'
import useUser from 'src/stores/useUser'
import { dateToToken } from 'src/utils'
import {
    MOnePriorityIcon,
    MTwoPriorityIcon,
    POnePriorityIcon,
    PTwoPriorityIcon,
    ZeroPriorityIcon,
} from '../../../components/Icons'

const PriorityMenuItem = withStyles({
    root: {
        justifyContent: 'center',
    },
})(MenuItem)

const iconsSmall = {
    '-2': (
        <MTwoPriorityIcon
            fontSize='small'
            style={{
                fontSize: '1rem',
                marginBottom: '-4px',
            }}
        />
    ),
    '-1': (
        <MOnePriorityIcon
            fontSize='small'
            style={{
                fontSize: '1rem',
                marginBottom: '-4px',
            }}
        />
    ),
    1: (
        <POnePriorityIcon
            fontSize='small'
            style={{
                fontSize: '1rem',
                marginBottom: '-4px',
            }}
        />
    ),
    2: (
        <PTwoPriorityIcon
            fontSize='small'
            style={{
                fontSize: '1rem',
                marginBottom: '-4px',
            }}
        />
    ),
}

const icons = {
    '-2': <MTwoPriorityIcon fontSize='small' />,
    '-1': <MOnePriorityIcon fontSize='small' />,
    0: <ZeroPriorityIcon fontSize='small' />,
    1: <POnePriorityIcon fontSize='small' />,
    2: <PTwoPriorityIcon fontSize='small' />,
}

const ActiveText = (props) => {
    let { text, style, icon, ...rest } = props

    if (style === undefined) style = {}

    return (
        <Typography
            noWrap
            style={{
                borderBottom: '1px dotted gray',
                textDecoration: 'none',
                cursor: 'pointer',
                color: 'gray',
                fontSize: '14px',
                ...style,
            }}
            {...rest}
        >
            {icon} {text}
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 1.5),
        // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        width: '100%',
    },
    search: {
        position: 'relative',
        border: 0,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
    },
}))

const getNewTaskInitialState = () => {
    return {
        text: '',
        date: null,
        project_id: null,
        repeat: null,
        priority: null,
        isChanged: false,
    }
}

// https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
function useStateRef(initialValue) {
    const [value, setValue] = useState(initialValue)
    const ref = useRef(value)

    useEffect(() => {
        ref.current = value
    }, [value])

    return [value, setValue, ref]
}

const initUndoReducerState = () => {
    return {
        doNotParse: [],
        previousState: null,
        recognized: null,
        afterParsing: null,
    }
}

const undoReducer = (state, action) => {
    const { type, payload } = action
    // console.log(action)
    switch (type) {
        case 'SET_UNDO':
            return {
                ...state,
                previousState: payload.previousState,
                recognized: payload.recognized,
                afterParsing: payload.afterParsing,
            }
        case 'CLEAR_UNDO':
            return {
                ...state,
                previousState: null,
                recognized: null,
                afterParsing: null,
            }
        case 'UNDO':
            return {
                ...state,
                previousState: null,
                recognized: null,
                doNotParse: [...state.doNotParse, state.recognized],
                afterParsing: null,
            }
        case 'RESET_STATE':
            return initUndoReducerState()
        default:
            return state
    }
}

const TopBar = ({ isXS }) => {
    const navigate = useNavigate()
    const [priorityAnchorEl, setPriorityAnchorEl] = useState(null)
    const { t, tArray, shortDateFormat } = useLanguage()
    const [anchorEl, setAnchorEl] = useState(null)
    const { userState } = useUser()
    const { logOut } = useLogOut()
    const [undoState, undoDispatch] = useReducer(
        undoReducer,
        undefined,
        initUndoReducerState
    )
    const [isTaskPanelOpen, setIsTaskPanelOpen, isTaskPanelOpenRef] =
        useStateRef(false)
    const [newTaskState, setNewTaskState, newTaskStateRef] = useStateRef(
        getNewTaskInitialState()
    )
    const {
        openSelectDateModal,
        openTaskRepeatModalNoApi,
        openTaskProjectModalNoApi,
    } = useModals()

    const inputRef = useRef()

    const clearNewTaskState = () => {
        setNewTaskState(getNewTaskInitialState())
        undoDispatch({ type: 'RESET_STATE' })
    }

    const { getProjectById } = useProjects()

    const { createTask } = useTasks()

    const handlePriorityMenuClick = (e) => {
        setPriorityAnchorEl(e.currentTarget)
    }

    const handlePriorityMenuClose = () => {
        setPriorityAnchorEl(null)
    }

    const classes = useStyles()
    const priorities = [2, 1, 0, -1, -2]

    const updateNewTaskState = (key, value) => {
        setNewTaskState((state) => ({
            ...state,
            [key]: value,
            isChanged: true,
        }))
    }

    const handleKeyPress = (e) => {
        if (!['TEXTAREA', 'INPUT'].includes(e.target.nodeName)) {
            if (e.keyCode === 27) {
                if (isTaskPanelOpenRef.current) {
                    e.preventDefault()
                    e.stopPropagation()
                    closeTaskPanel()
                }
            } else if (e.keyCode === 13 && isTaskPanelOpenRef.current) {
                e.preventDefault()
                e.stopPropagation()
                handleSubmit()
            }
        }
        if (e.keyCode == 81 && (e.metaKey || e.ctrlKey)) {
            e.preventDefault()
            e.stopPropagation()
            inputRef.current.focus()
            setIsTaskPanelOpen(true)
        }
    }

    useEffect(() => {
        console.log('registering event listener')
        window.addEventListener('keydown', handleKeyPress)
        return () => {
            console.log('removing event listener')
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [])

    const handleChangeTaskPriority = (priority) => {
        updateNewTaskState('priority', priority)
    }

    const openTaskPanel = () => {
        setIsTaskPanelOpen(true)
    }

    const closeTaskPanel = () => {
        setIsTaskPanelOpen(false)
    }

    useEffect(() => {
        if (newTaskState.text.substr(-1) === ' ') {
            let textToParse = newTaskState.text.trim()
            undoState.doNotParse.forEach((item) => {
                textToParse = textToParse.replace(new RegExp(item, 'g'))
            })
            textToParse = textToParse.trim()

            // parse dates
            if (newTaskState.date === null) {
                let found = parseDate(textToParse.trim(), dateRulesEn)
                if (found.date !== null) {
                    let newText = newTaskState.text
                        .trim()
                        .replace(new RegExp(found.match + '$'), '')
                        .trim()
                    undoDispatch({
                        type: 'SET_UNDO',
                        payload: {
                            recognized: found.match,
                            previousState: newTaskState,
                            afterParsing: newText,
                        },
                    })
                    updateNewTaskState('date', found.date)
                    updateNewTaskState('text', newText)
                    return
                }
            }

            // Parse priority
            if (newTaskState.priority === null) {
                let found = parsePriority(textToParse, priorityRulesEn)
                if (found.priority !== null) {
                    let newText = newTaskState.text
                        .trim()
                        .replace(new RegExp(found.match + '$'), '')
                        .trim()
                    undoDispatch({
                        type: 'SET_UNDO',
                        payload: {
                            recognized: found.match,
                            previousState: newTaskState,
                            afterParsing: newText,
                        },
                    })
                    updateNewTaskState('priority', found.priority)
                    updateNewTaskState('text', newText)
                }
            }
        }
    }, [newTaskState.text])

    const handleKeyDown = (e) => {
        // console.log(undoState)
        if (
            undoState.recognized !== null &&
            e.keyCode === 90 &&
            (e.ctrlKey || e.metaKey)
        ) {
            // console.log('undo')
            setNewTaskState(undoState.previousState)
            undoDispatch({ type: 'UNDO' })
            e.preventDefault()
            e.stopPropagation()
        }
        if (
            undoState.recognized &&
            undoState.afterParsing !== newTaskState.text
        ) {
            undoDispatch({ type: 'CLEAR_UNDO' })
        }
        if (e.keyCode === 13 && newTaskState.text.length > 0) {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit()
        }
        if (e.keyCode === 27) {
            e.preventDefault()
            e.stopPropagation()
            clearNewTaskState()
            if (
                JSON.stringify(newTaskState) ===
                JSON.stringify(getNewTaskInitialState())
            ) {
                closeTaskPanel()
                e.target.blur()
            }
        }
    }

    const handleSubmit = async () => {
        // const task = { text }
        // if (text.startsWith('!!')) {
        //     task.priority = 2
        //     task.text = task.text.slice(2).trim()
        // } else if (text.startsWith('!')) {
        //     task.priority = 1
        //     task.text = task.text.slice(1).trim()
        // }
        const newTask = { ...newTaskStateRef.current }
        if (newTask.text.length > 0) {
            if (newTask.date !== null)
                newTask.date_token = dateToToken(newTask.date)
            delete newTask.date
            if (newTask.priority === null) newTask.priority = 0
            delete newTask.isChanged
            const result = await createTask(newTask)
            if (result !== null) {
                clearNewTaskState()
            }
        }
    }

    const handleLogOut = async () => {
        await apiLogOut()
        logOut()
        navigate('/login')
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <AppBar
                position='fixed'
                // className={classes.appBar}
                style={{ zIndex: '1200' }}
                elevation={2}
            >
                <Toolbar
                    style={{
                        height: '56px',
                        minHeight: '56px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        wrap='nowrap'
                        justifyContent='space-between'
                        alignItems='center'
                        alignContent='center'
                        // direction='column'
                    >
                        <Hidden xsDown>
                            <Grid item>
                                <img
                                    src='/todox-logo-only.png'
                                    width={40}
                                    style={{ marginTop: '5px' }}
                                />
                            </Grid>
                        </Hidden>
                        <Grid item style={{ width: '800px' }}>
                            <div className={classes.search}>
                                <InputBase
                                    placeholder={t('topBarCreateTask')}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputRef={inputRef}
                                    value={newTaskState.text}
                                    onChange={(e) =>
                                        updateNewTaskState(
                                            'text',
                                            e.target.value
                                        )
                                    }
                                    onKeyDown={handleKeyDown}
                                    data-test='top-bar-create-task'
                                    onFocus={openTaskPanel}
                                    endAdornment={
                                        newTaskState.isChanged ? (
                                            <InputAdornment
                                                style={{ marginRight: '12px' }}
                                                position='end'
                                            >
                                                <BackspaceIcon
                                                    fontSize='small'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        clearNewTaskState()
                                                        inputRef.current.focus()
                                                    }}
                                                />
                                            </InputAdornment>
                                        ) : (
                                            !isTaskPanelOpen &&
                                            !isXS && (
                                                <InputAdornment
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    position='end'
                                                >
                                                    <Typography
                                                        style={{
                                                            color: '#A0A9DA',
                                                        }}
                                                    >
                                                        ctrl + q
                                                    </Typography>
                                                </InputAdornment>
                                            )
                                        )
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item>
                            <Avatar
                                variant='circular'
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                }}
                                onClick={handleClick}
                                data-test='user-avatar'
                            >
                                <PersonIcon color='primary' />
                            </Avatar>
                            <Menu
                                id='user-menu'
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem
                                    dense
                                    disabled
                                    // onClick={() => {
                                    //     handleClose()
                                    //     navigate('/app/user/settings')
                                    // }}
                                >
                                    {userState.email}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose()
                                        navigate('/app/user/settings')
                                    }}
                                    data-test='user-menu-settings'
                                >
                                    {t('settings')}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        window.open(
                                            'https://todox.online/features/?utm_source=app&utm_medium=user_menu',
                                            '_blank'
                                        )
                                    }}
                                    data-test='user-menu-help'
                                >
                                    {t('help')}
                                    <OpenInNewIcon
                                        style={{
                                            fontSize: 14,
                                            marginLeft: 4,
                                        }}
                                        // fontSize='small'
                                        color='disabled'
                                    />
                                </MenuItem>
                                {/* <MenuItem
                                onClick={() => {
                                    globalDispatch({ type: FORCE_RELOAD })
                                    handleClose()
                                }}
                            >
                                {t('refreshData')}
                            </MenuItem> */}
                                <MenuItem
                                    onClick={handleLogOut}
                                    data-test='user-menu-logout'
                                >
                                    <Typography color='secondary'>
                                        {t('logout')}
                                    </Typography>
                                </MenuItem>
                            </Menu>
                            {/* <Button onClick={handleLogOut}>Выйти</Button> */}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {isTaskPanelOpen && (
                <Grid
                    container
                    spacing={0}
                    direction='row'
                    // direction='column'
                    alignItems='flex-start'
                    justifyContent='center'
                    style={{
                        minWidth: '100vw',
                        height: '100vh',
                        minHeight: '100vh',
                        position: 'fixed',
                        zIndex: '1199',
                        width: 'auto',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }}
                    onClick={closeTaskPanel}
                    data-test='top-bar-create-task-panel'
                >
                    <Grid item onClick={(e) => e.stopPropagation()} style={{}}>
                        <Slide
                            in={isTaskPanelOpen}
                            direction='down'
                            timeout={250}
                            mountOnEnter
                        >
                            <Card
                                style={{
                                    maxWidth: '800px',
                                    width: 'auto',
                                    paddingTop: '50px',
                                }}
                            >
                                <CardContent style={{ paddingBottom: '16px' }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='center'
                                        style={{ width: 'auto' }}
                                    >
                                        {/* <Grid item xs={12}>
                                            <Grid container spacing={2}> */}
                                        <Grid item>
                                            <ActiveText
                                                text={
                                                    newTaskState.priority
                                                        ? t('priority')
                                                        : t('noPriority')
                                                }
                                                icon={
                                                    newTaskState.priority
                                                        ? iconsSmall[
                                                              newTaskState
                                                                  .priority
                                                          ]
                                                        : null
                                                }
                                                onClick={
                                                    handlePriorityMenuClick
                                                }
                                                data-test='create-task-priority'
                                            />
                                            <Menu
                                                anchorEl={priorityAnchorEl}
                                                // keepMounted
                                                open={Boolean(priorityAnchorEl)}
                                                onClose={
                                                    handlePriorityMenuClose
                                                }
                                                variant='menu'
                                                autoFocus={false}
                                                style={{
                                                    marginLeft: '-7px',
                                                }}
                                                data-test='create-task-priority-menu'
                                            >
                                                {priorities.map((priority) => (
                                                    <PriorityMenuItem
                                                        selected={
                                                            priority ===
                                                            (newTaskState.priority ||
                                                                0)
                                                        }
                                                        onClick={() => {
                                                            handleChangeTaskPriority(
                                                                priority
                                                            )
                                                            handlePriorityMenuClose()
                                                        }}
                                                        dense
                                                        data-test={`priority${priority}`}
                                                    >
                                                        {icons[priority]}
                                                        {/* {priority !== 0 ? priority : '\u00A0'} */}
                                                    </PriorityMenuItem>
                                                ))}
                                            </Menu>
                                        </Grid>
                                        <Grid item>
                                            <ActiveText
                                                text={
                                                    newTaskState.date === null
                                                        ? t('noDate')
                                                        : format(
                                                              newTaskState.date,
                                                              shortDateFormat
                                                          )
                                                }
                                                icon={
                                                    <DateRangeIcon
                                                        style={{
                                                            fontSize: '1rem',
                                                            marginBottom:
                                                                '-4px',
                                                        }}
                                                    />
                                                }
                                                onClick={() => {
                                                    openSelectDateModal(
                                                        (value) => {
                                                            updateNewTaskState(
                                                                'date',
                                                                value
                                                            )
                                                        },
                                                        newTaskState.date
                                                    )
                                                }}
                                                data-test='create-task-date'
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ActiveText
                                                data-test='create-task-project'
                                                text={
                                                    getProjectById(
                                                        newTaskState.project_id
                                                    )?.name || t('noProject')
                                                }
                                                icon={
                                                    <FolderOpenTwoToneIcon
                                                        style={{
                                                            fontSize: '1rem',
                                                            marginBottom:
                                                                '-4px',
                                                        }}
                                                    />
                                                }
                                                onClick={() => {
                                                    openTaskProjectModalNoApi(
                                                        (value) => {
                                                            updateNewTaskState(
                                                                'project_id',
                                                                value
                                                            )
                                                        },
                                                        newTaskState.project_id
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ActiveText
                                                text={
                                                    formatTaskRepeatString(
                                                        newTaskState.repeat,
                                                        t,
                                                        tArray('daysOfWeek'),
                                                        userState.isMondayFirstDayOfWeek
                                                    ) || '' // t('noRepetition')
                                                }
                                                onClick={() => {
                                                    openTaskRepeatModalNoApi(
                                                        (value) => {
                                                            updateNewTaskState(
                                                                'repeat',
                                                                value
                                                            )
                                                        },
                                                        newTaskState.repeat
                                                    )
                                                }}
                                                icon={
                                                    <LoopIcon
                                                        style={{
                                                            fontSize: '1rem',
                                                            marginBottom:
                                                                '-4px',
                                                        }}
                                                    />
                                                }
                                            ></ActiveText>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Slide>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default memo(TopBar)
