import {
    AppBar,
    Badge,
    BottomNavigation,
    BottomNavigationAction,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer,
} from '@material-ui/core'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone'
import HistoryIcon from '@material-ui/icons/History'
import InboxIcon from '@material-ui/icons/Inbox'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import SearchIcon from '@material-ui/icons/Search'
import TodayIcon from '@material-ui/icons/Today'
import ViewDayIcon from '@material-ui/icons/ViewDay'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ProjectsContext } from 'src/stores/projectsStore'
import { TasksContext } from 'src/stores/tasksStore'
import { useLanguage } from 'src/stores/useLanguage'
import { calculateMenuStats } from '../MainMenu'

const BottomMenu = () => {
    const { t } = useLanguage()
    const location = useLocation()
    const { state: tasksState } = useContext(TasksContext)
    const { state: projectsState } = useContext(ProjectsContext)
    const navigate = useNavigate()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const navigateFromDrawer = (path) => {
        navigate(path)
        setIsDrawerOpen(false)
    }

    const stats = calculateMenuStats(tasksState.tasks)

    const pinnedProjects = projectsState.projects.filter(
        (project) => project.status === 'active' && project.favorite
    )

    return (
        <>
            <AppBar
                position='fixed'
                color='primary'
                style={{
                    top: 'auto',
                    bottom: 0,
                }}
                elevation={12}
            >
                <BottomNavigation
                    value={location.pathname}
                    onChange={(e, value) => {
                        if (value !== 'more') {
                            navigate(value)
                        }
                    }}
                    showLabels
                    style={{ minHeight: '70px', paddingBottom: 8 }}
                >
                    <BottomNavigationAction
                        label={t('today')}
                        icon={
                            <Badge badgeContent={stats.today}>
                                <ViewDayIcon />
                            </Badge>
                        }
                        value='/app/tasks/today'
                    />
                    <BottomNavigationAction
                        label={t('inbox')}
                        icon={
                            <Badge badgeContent={stats.inbox}>
                                <InboxIcon />
                            </Badge>
                        }
                        value='/app/tasks/inbox'
                    />
                    <BottomNavigationAction
                        label={t('tomorrow')}
                        icon={
                            <Badge badgeContent={stats.tomorrow}>
                                <TodayIcon />
                            </Badge>
                        }
                        value='/app/tasks/tomorrow'
                    />
                    <BottomNavigationAction
                        label={t('overdue')}
                        icon={
                            <Badge
                                badgeContent={stats.overdue}
                                color='secondary'
                            >
                                <AssignmentLateIcon />
                            </Badge>
                        }
                        value='/app/tasks/overdue'
                    />

                    <BottomNavigationAction
                        label={t('more')}
                        icon={<MoreHorizIcon />}
                        value='more'
                        onClick={() => setIsDrawerOpen(true)}
                    />
                </BottomNavigation>
            </AppBar>
            <SwipeableDrawer
                anchor='bottom'
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                style={{ zIndex: 1500 }}
            >
                <List>
                    {pinnedProjects.length > 0 && (
                        <ListItem
                            onClick={() =>
                                navigateFromDrawer('/app/projects/pinned')
                            }
                        >
                            <ListItemIcon>
                                <BookmarkIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pinnedProjects')} />
                        </ListItem>
                    )}

                    <ListItem
                        onClick={() => navigateFromDrawer('/app/projects')}
                    >
                        <ListItemIcon>
                            <Badge badgeContent={projectsState.projects.length}>
                                <FolderOpenTwoToneIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={t('projects')} />
                    </ListItem>
                    <Divider />
                    <ListItem
                        onClick={() =>
                            navigateFromDrawer('/app/tasks/chronology')
                        }
                    >
                        <ListItemIcon>
                            <HistoryIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('chronology')} />
                    </ListItem>
                    <ListItem
                        onClick={() => navigateFromDrawer('/app/tasks/search')}
                    >
                        <ListItemIcon>
                            <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('search')} />
                    </ListItem>
                    <Divider />
                    <ListItem
                        onClick={() => navigateFromDrawer('/app/stats/review')}
                        style={{ paddingBottom: '20px' }}
                    >
                        <ListItemIcon>
                            <EqualizerIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('review')} />
                    </ListItem>
                </List>
            </SwipeableDrawer>
        </>
    )
}

export default BottomMenu
