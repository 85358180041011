import { useContext } from 'react'
import FormattingHelpModal from 'src/modals/FormattingHelpModal'
import ChangeTaskDateModal from 'src/modals/TasksModals/ChangeTaskDateModal'
// import ChangeTaskDateModal from 'src/components/ChangeTaskDateModal'
import ChangeTaskProjectModal from 'src/modals/TasksModals/ChangeTaskProjectModal'
import ChangeTaskRepeatModal from 'src/modals/TasksModals/ChangeTaskRepeatModal'
import ChangeTaskTextModal from 'src/modals/TasksModals/ChangeTaskTextModal'
import FilterByProjectsModal from 'src/modals/TasksModals/FilterByProjectsModal'
import SelectDateModal from 'src/modals/TasksModals/SelectDateModal'
import {
    CLOSE_MODAL,
    FORMATTING_HELP_MODAL,
    // CREATE_GOAL_MODAL,
    // EDIT_GOAL_MODAL,
    ModalsContext,
    OPEN_PROJECTS_FILTER_MODAL,
    PROJECT_MODAL,
    SELECT_DATE_MODAL,
    TASK_DATE_MODAL,
    TASK_PROJECT_MODAL,
    TASK_PROJECT_MODAL_NO_API,
    TASK_REPEAT_MODAL,
    TASK_REPEAT_MODAL_NO_API,
    TASK_TEXT_MODAL,
    TIME_RECORDS_MODAL,
    TIME_RECORD_MODAL,
} from 'src/stores/modalsStore'
import useProjects from 'src/stores/useProjects'
import useTasks from 'src/stores/useTasks'
import { dateToToken } from 'src/utils'
// import GoalModal from 'src/views/GoalsViews/GoalModal'
import ProjectModal from 'src/views/ProjectsViews/ProjectModal'
import TaskTimeRecordsModal from 'src/views/TasksViews/TaskTimeRecordsModal'
import TimeRecordModal from 'src/views/TasksViews/TimeRecordModal'

const ModalsLayout = () => {
    const { modalsState, modalsDispatch } = useContext(ModalsContext)
    const { getTaskById, updateTask } = useTasks()
    const { projects } = useProjects()

    const closeModal = (e) => {
        if (e !== undefined) e.stopPropagation()
        modalsDispatch({ type: CLOSE_MODAL })
    }

    switch (modalsState.modalOpen) {
        case TIME_RECORD_MODAL:
            return (
                <TimeRecordModal
                    task={modalsState.modalPayload.task}
                    handleClose={closeModal}
                    handleSave={modalsState.modalPayload.handleSave}
                    timeRecord={modalsState.modalPayload.timeRecord}
                />
            )
        case TIME_RECORDS_MODAL:
            return (
                <TaskTimeRecordsModal
                    handleClose={closeModal}
                    task={getTaskById(modalsState.modalPayload._id)}
                />
            )
        case PROJECT_MODAL:
            return (
                <ProjectModal
                    handleClose={
                        modalsState.modalPayload.handleClose || closeModal
                    }
                    handleCancel={
                        modalsState.modalPayload.handleCancel || closeModal
                    }
                    project={modalsState.modalPayload.project}
                    callAfterCreate={modalsState.modalPayload.callAfterCreate}
                    projects={projects}
                />
            )
        case TASK_PROJECT_MODAL:
            return (
                <ChangeTaskProjectModal
                    handleClose={closeModal}
                    handleSave={(projectId) =>
                        updateTask(modalsState.modalPayload._id, {
                            project_id: projectId,
                        })
                    }
                    task={modalsState.modalPayload}
                    projects={projects}
                />
            )
        case TASK_PROJECT_MODAL_NO_API:
            return (
                <ChangeTaskProjectModal
                    handleClose={closeModal}
                    handleSave={modalsState.handleSave}
                    task={{ project_id: modalsState.value }}
                    projects={projects}
                />
            )
        case TASK_REPEAT_MODAL:
            return (
                <ChangeTaskRepeatModal
                    handleClose={closeModal}
                    handleSave={(repeat) => {
                        return updateTask(modalsState.modalPayload._id, {
                            repeat,
                        })
                    }}
                    task={modalsState.modalPayload}
                />
            )
        case TASK_REPEAT_MODAL_NO_API:
            return (
                <ChangeTaskRepeatModal
                    handleClose={closeModal}
                    handleSave={modalsState.handleSave}
                    task={{ repeat: modalsState.value }}
                />
            )
        case TASK_TEXT_MODAL:
            return (
                <ChangeTaskTextModal
                    handleClose={closeModal}
                    handleSave={(text) => {
                        updateTask(modalsState.modalPayload._id, {
                            text,
                        })
                    }}
                    task={modalsState.modalPayload}
                />
            )
        case TASK_DATE_MODAL:
            return (
                <ChangeTaskDateModal
                    handleClose={closeModal}
                    handleSave={(date) => {
                        updateTask(modalsState.modalPayload._id, {
                            date_token:
                                date === null ? null : dateToToken(date),
                        })
                        closeModal()
                    }}
                    task={modalsState.modalPayload}
                />
            )
        case SELECT_DATE_MODAL:
            return (
                <SelectDateModal
                    handleClose={closeModal}
                    handleSave={modalsState.handleSave}
                    value={modalsState.value || null}
                />
            )
        case OPEN_PROJECTS_FILTER_MODAL:
            return (
                <FilterByProjectsModal
                    tasks={modalsState.modalPayload.tasks}
                    handleClose={closeModal}
                    handleSave={modalsState.modalPayload.handleSave}
                    filterState={modalsState.modalPayload.filterState}
                />
            )
        case FORMATTING_HELP_MODAL:
            return <FormattingHelpModal onClose={closeModal} />
        default:
            return null
    }
}

export default ModalsLayout
