import { createTheme, ThemeProvider } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
// import './index.css'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { TasksContextProvider } from './stores/tasksStore'
import { UserContextProvider } from './stores/userStore'

if (process.env.REACT_APP_SENTRY_URL) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.1,
    })
}

const theme = createTheme({
    props: {
        MuiButtonBase: {
            focusRipple: false,
            disableRipple: true,
        },
        MuiButton: {
            disableElevation: true,
        },
        MuiButtonGroup: {
            disableRipple: true,
            disableFocusRipple: true,
            disableElevation: true,
        },
        MuiAppBar: {
            elevation: 0,
        },
    },
    zIndex: { snackbar: 1100 },
})

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <UserContextProvider>
                <TasksContextProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </TasksContextProvider>
            </UserContextProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: (registration: any) => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener(
                'statechange',
                (event: any) => {
                    if (event.target.state === 'activated') {
                        window.location.reload()
                    }
                }
            )
            waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
    },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
