import { Button, ButtonGroup } from '@material-ui/core'
import PauseIcon from '@material-ui/icons/Pause'
import { parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { formatDurationFromSeconds } from 'src/utils'

const RunningTimer = (props) => {
    const { handleStop, duration, timer, handleTimeChange = () => {} } = props
    const [runningTime, setRunningTime] = useState(0)
    const formattedTime = formatDurationFromSeconds(duration + runningTime)

    useEffect(() => {
        if (timer !== null) {
            let time = Math.floor(
                (new Date() - parseISO(timer.start_dt)) / 1000
            )
            time < 0 && (time = 0)
            setRunningTime(time)
            const interval = setInterval(() => {
                let runningTime = Math.floor(
                    (new Date() - parseISO(timer.start_dt)) / 1000
                )
                setRunningTime(runningTime)
                handleTimeChange(duration + runningTime)
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [timer])
    return (
        <ButtonGroup size='small' style={{ paddingBottom: '1.5px' }}>
            <Button
                variant='outlined'
                disabled
                style={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    // fontWeight: 800,
                    width: '97px',
                }}
                data-test='task-time'
            >
                {formattedTime}
            </Button>
            <Button
                variant='contained'
                color='secondary'
                onClick={handleStop}
                // disableElevation
                data-test='stop-timer-btn'
            >
                {<PauseIcon />}
            </Button>
        </ButtonGroup>
    )
}

export default RunningTimer
