import GeneralStatsWidget from 'src/components/Widgets/GeneralStatsWidget'
import NotesWidget from 'src/components/Widgets/NoteWidget'
import TimeByProjectsWidget from 'src/components/Widgets/TimeByProjectsWidget'
import TimeByTasksWidget from 'src/components/Widgets/TimeByTasksWidget'
import { Widget, WidgetInUpdate } from 'src/typing'
import WidgetContainer from '../WidgetContainer'

interface WidgetComponentProps extends Widget {
    position: number
    widgetsCount: number
    onChange: (changes: WidgetInUpdate) => void
}

const BaseWidget = (props: WidgetComponentProps) => {
    const { type, title, _id, position, widgetsCount, group } = props
    var BaseElement = null
    switch (type) {
        case 'notes':
            BaseElement = NotesWidget
            break
        case 'general_stats':
            BaseElement = GeneralStatsWidget
            break
        case 'time_by_projects':
            BaseElement = TimeByProjectsWidget
            break
        case 'time_by_tasks':
            BaseElement = TimeByTasksWidget
            break
    }

    if (BaseElement === null) return null

    return (
        <WidgetContainer
            widgetId={_id}
            title={title}
            position={position}
            widgetsCount={widgetsCount}
            group={group}
        >
            <BaseElement {...props} />
        </WidgetContainer>
    )
}

export default BaseWidget
export type { WidgetComponentProps }
