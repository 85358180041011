import React, { useReducer } from 'react'

const initialState = {
    modalOpen: null,
    modalPayload: null,
}

const CLOSE_MODAL = Symbol('CLOSE_MODAL')
const OPEN_CREATE_GOAL_MODAL = Symbol('OPEN_CREATE_GOAL_MODAL')
const OPEN_EDIT_GOAL_MODAL = Symbol('OPEN_EDIT_GOAL_MODAL')
const OPEN_TIME_RECORDS_MODAL = Symbol('OPEN_TIME_RECORDS_MODAL')
const OPEN_PROJECT_MODAL = Symbol('OPEN_PROJECT_MODAL')
const OPEN_TASK_DATE_MODAL = Symbol('OPEN_TASK_DATE_MODAL')
const OPEN_TASK_PROJECT_MODAL = Symbol('OPEN_TASK_PROJECT_MODAL')
const OPEN_TASK_REPEAT_MODAL = Symbol('OPEN_TASK_REPEAT_MODAL')
const OPEN_TASK_TEXT_MODAL = Symbol('OPEN_TASK_TEXT_MODAL')
const OPEN_SELECT_DATE_MODAL = Symbol('OPEN_SELECT_DATE_MODAL')
const OPEN_TASK_REPEAT_MODAL_NO_API = Symbol('OPEN_TASK_REPEAT_MODAL_NO_API')
const OPEN_TASK_PROJECT_MODAL_NO_API = Symbol('OPEN_TASK_PROJECT_MODAL_NO_API')
const TIME_RECORD_MODAL = Symbol('TIME_RECORD_MODAL')
const FORMATTING_HELP_MODAL = Symbol('FORMATTING_HELP_MODAL')

const OPEN_PROJECTS_FILTER_MODAL = Symbol('OPEN_PROJECTS_FILTER_MODAL')

const CREATE_GOAL_MODAL = Symbol('CREATE_GOAL_MODAL')
const EDIT_GOAL_MODAL = Symbol('EDIT_GOAL_MODAL')
const TIME_RECORDS_MODAL = Symbol('TIME_RECORDS_MODAL')
const PROJECT_MODAL = Symbol('PROJECT_MODAL')
const TASK_DATE_MODAL = Symbol('TASK_DATE_MODAL')
const TASK_PROJECT_MODAL = Symbol('TASK_PROJECT_MODAL')
const TASK_REPEAT_MODAL = Symbol('TASK_REPEAT_MODAL')
const TASK_TEXT_MODAL = Symbol('TASK_TEXT_MODAL')
const SELECT_DATE_MODAL = Symbol('SELECT_DATE_MODAL')
const TASK_REPEAT_MODAL_NO_API = Symbol('TASK_REPEAT_MODAL_NO_API')
const TASK_PROJECT_MODAL_NO_API = Symbol('TASK_PROJECT_MODAL_NO_API')

const reducer = (state, action) => {
    const { type, payload } = action
    // console.log(type.toString())

    switch (type) {
        case OPEN_CREATE_GOAL_MODAL:
            return {
                ...state,
                modalOpen: CREATE_GOAL_MODAL,
            }
        case CLOSE_MODAL:
            return {
                ...state,
                modalOpen: null,
                modalPayload: null,
            }
        case OPEN_EDIT_GOAL_MODAL:
            return {
                ...state,
                modalOpen: EDIT_GOAL_MODAL,
                modalPayload: payload,
            }
        case OPEN_TIME_RECORDS_MODAL:
            return {
                ...state,
                modalOpen: TIME_RECORDS_MODAL,
                modalPayload: payload,
            }
        case OPEN_PROJECT_MODAL:
            return {
                ...state,
                modalOpen: PROJECT_MODAL,
                modalPayload: payload,
            }
        case OPEN_TASK_DATE_MODAL:
            return {
                ...state,
                modalOpen: TASK_DATE_MODAL,
                modalPayload: payload,
            }
        case OPEN_TASK_PROJECT_MODAL:
            return {
                ...state,
                modalOpen: TASK_PROJECT_MODAL,
                modalPayload: payload,
            }
        case OPEN_TASK_REPEAT_MODAL:
            return {
                ...state,
                modalOpen: TASK_REPEAT_MODAL,
                modalPayload: payload,
            }
        case OPEN_TASK_TEXT_MODAL:
            return {
                ...state,
                modalOpen: TASK_TEXT_MODAL,
                modalPayload: payload,
            }
        case OPEN_PROJECTS_FILTER_MODAL:
            return {
                ...state,
                modalOpen: OPEN_PROJECTS_FILTER_MODAL,
                modalPayload: payload,
            }
        case OPEN_SELECT_DATE_MODAL:
            return {
                ...state,
                modalOpen: SELECT_DATE_MODAL,
                handleSave: payload.handleSave,
                value: payload.value,
            }
        case OPEN_TASK_REPEAT_MODAL_NO_API:
            return {
                ...state,
                modalOpen: TASK_REPEAT_MODAL_NO_API,
                handleSave: payload.handleSave,
                value: payload.value,
            }
        case OPEN_TASK_PROJECT_MODAL_NO_API:
            return {
                ...state,
                modalOpen: TASK_PROJECT_MODAL_NO_API,
                handleSave: payload.handleSave,
                value: payload.value,
            }
        case FORMATTING_HELP_MODAL:
            return {
                ...state,
                modalOpen: FORMATTING_HELP_MODAL,
            }
        case TIME_RECORD_MODAL:
            return {
                ...state,
                modalOpen: TIME_RECORD_MODAL,
                modalPayload: {
                    handleSave: payload.handleSave,
                    task: payload.task,
                    timeRecord: payload.record,
                },
            }
        default:
            console.warn('Unknown reducer action ' + type.toString())
            return state
    }
}

const ModalsDispatchContext = React.createContext(() => {})
const ModalsContext = React.createContext(initialState)

const ModalsStateProvider = (props) => {
    const [modalsState, modalsDispatch] = useReducer(reducer, initialState)

    return (
        <ModalsContext.Provider value={{ modalsState, modalsDispatch }}>
            <ModalsDispatchContext.Provider value={modalsDispatch}>
                {props.children}
            </ModalsDispatchContext.Provider>
        </ModalsContext.Provider>
    )
}

export {
    ModalsContext,
    ModalsDispatchContext,
    ModalsStateProvider,
    OPEN_CREATE_GOAL_MODAL,
    OPEN_EDIT_GOAL_MODAL,
    OPEN_TIME_RECORDS_MODAL,
    OPEN_PROJECT_MODAL,
    OPEN_TASK_DATE_MODAL,
    OPEN_TASK_PROJECT_MODAL,
    OPEN_TASK_REPEAT_MODAL,
    OPEN_TASK_TEXT_MODAL,
    CLOSE_MODAL,
    CREATE_GOAL_MODAL,
    EDIT_GOAL_MODAL,
    TIME_RECORDS_MODAL,
    PROJECT_MODAL,
    TASK_DATE_MODAL,
    TASK_PROJECT_MODAL,
    TASK_REPEAT_MODAL,
    TASK_TEXT_MODAL,
    OPEN_PROJECTS_FILTER_MODAL,
    OPEN_SELECT_DATE_MODAL,
    SELECT_DATE_MODAL,
    TASK_REPEAT_MODAL_NO_API,
    OPEN_TASK_REPEAT_MODAL_NO_API,
    TASK_PROJECT_MODAL_NO_API,
    OPEN_TASK_PROJECT_MODAL_NO_API,
    TIME_RECORD_MODAL,
    FORMATTING_HELP_MODAL,
}
