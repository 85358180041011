import DateFnsUtils from '@date-io/date-fns'
import {
    Button,
    Dialog,
    DialogActions,
    Grid,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useContext } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'
import { tokenToDate } from 'src/utils'

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline',
    },
})

const ChangeTaskDateModal = (props) => {
    const { task, handleClose, handleSave } = props
    const { userState } = useUser()

    const { t, getDateLocale } = useLanguage()

    const classes = useStyles()

    const locale = getDateLocale()

    let date = null
    if (task.date_token) {
        date = tokenToDate(task.date_token)
    }

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            // fullWidth
            maxWidth='xs'
            classes={isSmall ? { scrollPaper: classes.scrollPaper } : {}}
            data-test='modal-choose-date'
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <DatePicker
                    clearable
                    okLabel=''
                    cancelLabel=''
                    variant='static'
                    autoOk
                    format={userState.shortDateFormat}
                    value={date}
                    onChange={handleSave}
                />
            </MuiPickersUtilsProvider>
            <DialogActions>
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <Button
                            onClick={() => handleSave(null)}
                            color='primary'
                            data-test='no-date-btn'
                        >
                            {t('noDate')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => handleSave(new Date())}
                            color='primary'
                            data-test='today-btn'
                        >
                            {t('today')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default ChangeTaskDateModal
