import { useContext } from "react"
import { apiCreateWidget, apiDeleteWidget, apiFetchWidgetsGroup, apiUpdateWidget } from "src/api/widgets"
import { Widget, WidgetInCreate, WidgetInUpdate } from "src/typing"
import { WidgetsContext, WidgetsStoreActionType } from "./widgetsStore"


const useWidgets = () => {
    const {state, dispatch} = useContext(WidgetsContext)
    const { widgets } = state

    const fetchWidgetsGroup = async (group: string) => {
        const response = await apiFetchWidgetsGroup(group)
        if (response.status === 200) {
            dispatch({type: WidgetsStoreActionType.UPDATE_STORE, payload: response.data.data})
        }
        return response
    }

    const createWidget = async (widget: WidgetInCreate) => {
        const response = await apiCreateWidget(widget)
        if (response.status === 201) {
            dispatch({type: WidgetsStoreActionType.UPDATE_STORE, payload: [response.data.data]})
        }
        return response
    }

    const updateWidget = async (widgetId: string, widget: WidgetInUpdate) => {
        const response = await apiUpdateWidget(widgetId, widget)
        if (response.status === 200) {
            dispatch({type: WidgetsStoreActionType.UPDATE_STORE, payload: [response.data.data]})
        }
        return response
    }

    const getWidgetsGroup = (group: string): Widget[] => {
        return widgets.filter(widget => widget.group === group)
    }

    const deleteWidget = async (widgetId: string) => {
        let result = await apiDeleteWidget(widgetId)
        if (result.status === 200) {
            dispatch({type: WidgetsStoreActionType.DELETE, payload: widgetId})
        }
    }

    const getWidgetById = (widgetId: string): Widget | null => {
        for (const item of widgets) {
            if (item._id === widgetId)
            return item
        }
        return null
    } 

    const moveWidget = (widgetId: string, direction: 1 | -1) => {
        const widget = getWidgetById(widgetId)
        if (widget !== null) {
            let widgetsGroup = getWidgetsGroup(widget.group).sort((a, b) => a.order - b.order)
            let widgetIndex = 0
            widgetsGroup.forEach((item, index) => {
                if (item._id === widgetId) widgetIndex = index
            })
            if (direction === -1 && widgetIndex === 0) return
            if (direction === 1 && widgetIndex === widgetsGroup.length - 1) return
            let switchingWithWidgetId = widgetsGroup[widgetIndex + direction]._id
            let newWidgetOrder = widgetsGroup[widgetIndex + direction].order
            updateWidget(widgetId, {order: newWidgetOrder})
            updateWidget(switchingWithWidgetId, {order: widget.order})
        }
    }


    return {
        createWidget,
        fetchWidgetsGroup,
        updateWidget,
        getWidgetsGroup,
        deleteWidget,
        moveWidget,
        widgets
    }
}

export default useWidgets