// https://wavelop.com/en/story/implementing-multi-language-without-any-library-in-react-hooks/

interface LanguageTranslation {
    showDone: string
    hideDone: string
    settings: string
    logout: string
    refreshData: string
    changePassword: string
    newPassword: string
    newPasswordRepeat: string
    currentPassword: string
    save: string
    passwordMinLength: string
    errIncorrectCurrentPassword: string
    errNewPasswordRequirementsViolation: string
    ntfPasswordChanged: string
    errPasswordCannotBeEmpty: string
    errPasswordsDoNotMatch: string
    errNewPasswordTooShort: string
    today: string
    tomorrow: string
    inbox: string
    overdue: string
    chronology: string
    search: string
    goals: string
    projects: string
    statisticsTable: string
    topBarCreateTask: string
    noProject: string
    project: string
    noDate: string
    name: string
    time: string
    totalTimeRecorded: string
    '7days': string
    week: string
    month: string
    year: string
    timeStatsFrom: string
    timeStatsTo: string
    abbDays: string
    abbDay: string
    createProject: string
    projectName: string
    actions: string
    showArchive: string
    hideArchive: string
    open: string
    close: string
    delete: string
    notes: string
    repeat: string
    editText: string
    alertConfirmDelete: string
    everyXDays: string
    certainDaysOfWeek: string
    noRepeat: string
    certainDaysOfMonth: string
    mon: string
    tue: string
    wed: string
    thu: string
    fri: string
    sat: string
    sun: string
    cancel: string
    taskText: string
    ntfTaskDeleted: string
    nftTimeRecorded: string
    nftTaskCreated: string
    nftNewTaskDate: string
    nftTaskDateRemoved: string
    nftNewTaskText: string
    nftTaskNotesSaved: string
    nftTaskProjectChanged: string
    nftEveryXDaysOfMonth: string
    nftEveryXDaysOfWeek: string
    nftEveryXDays: string
    // daysOfWeek: string[]
    nftNewRepeatSettings: string
    nftTaskCompletedNextRepeat: string
    nftTaskCompleted: string
    nftTaskChanged: string
    nftTimeEntryDeleted: string
    taskRepeatSettings: string
    nftTimerStarted: string
    nftTimerStopped: string
    newProject: string
    changeProject: string
    parentProject: string
    rootProject: string
    create: string
    alertConfirmTimeEntryDelete: string
    timeEntriesForTask: string
    abbDayOfMonth: string
    interfaceLanguage: string
    english: string
    russian: string
    nftLanguageChanged: string
    active: string
    archived: string
    pinned: string
    notPinned: string
    more: string
    email: string
    password: string
    login: string
    inviteCode: string
    errWrongCredentials: string
    errEmailCannotBeEmpty: string
    errWrongEmailFormat: string
    or: string
    register: string
    passwordRepeat: string
    restorePassword: string
    iAgreeTo: string
    withUserAgreement: string
    toProceedYouNeedToAgreeToUserAgreement: string
    confirmEmailToProceed: string
    ifQuestionsContactSupport: string
    requestConfirmationEmail: string
    nextRequestIn: string
    nftRequestSent: string
    nftServerError: string
    nftEmailConfirmed: string
    nftWrongEmailOrToken: string
    fillInEmailToRestorePassword: string
    successPasswordRecoveryRequested: string
    wrongEmailOrTokenOrStaleTryAgain: string
    successPasswordChanged: string
    successRegistrationCheckEmailAndLogin: string
    pinnedProjects: string
    emailAlreadyRegisteredSuggestRestorePassword: string
    notesPlaceholder: string
    dateFormat: string
    dateSettings: string
    nftSettingsSaved: string
    yyyy: string
    dd: string
    MM: string
    firstDayOfWeek: string
    monday: string
    sunday: string
    filterByProjects: string
    noTasks: string
    nftTaskDeleted: string
    hh: string
    priority: string
    noPriority: string
    noRepetition: string
    alertConfirmDeleteProject: string
    noSubprojects: string
    subprojects: string
    DoNotHaveAnAccount: string
    AlreadyHaveAnAccount: string
    signInWithGoogle: string
    signUpWithGoogle: string
    clearAll: string
    here: string
    byContinuingYouAgreeTo: string
    duration: string
    nextDay: string
    addTime: string
    back: string
    to: string
    from: string
    date: string
    noTimeRecorded: string
    help: string
    notesEditorPlaceholder: string
    confirmNotSavingNotes: string
    atLeastOneNumberFrom1To31Comma: string
    pleaseChooseAtLeastOne: string
    serverError: string
    mustBePositive: string
    formattingHelp: string
    formattingHelpContent: string
    formattingHelpIntro: string
    fullDocumentation: string
    generalStats: string
    perDay: string
    total: string
    timeRecorded: string
    withTimer: string
    manually: string
    activity: string
    createdTasks: string
    completedTasks: string
    tasksStatuses: string
    done: string
    review: string
    recordedTimeByTasksTitle: string
    recordedTimeByTasksDescription: string
    task: string
    recordedTimeByProjectsTitle: string
    current_month_to_date: string
    current_week_to_date: string
    last_7_days: string
    rename: string
    moveUp: string
    moveDown: string
    time_by_projects: string
    general_stats: string
    time_by_tasks: string
    addWidget: string
    widgets: string
    alertConfirmDeleteWidget: string
    typeAtLeast3Chars: string
    noPinnedProjects: string
    hours: string
}

interface LanguagesStore {
    ru: LanguageTranslation
    en: LanguageTranslation
}

const languages: LanguagesStore = {
    ru: require('./languages/ru.json'),
    en: require('./languages/en.json'),
}

export { languages }
export type { LanguageTranslation }
