import { apiCall } from './base'

const apiDeleteTaskTimeRecord = async (taskId, recordId) => {
    return await apiCall({
        url: '/api/v1/tasks/' + taskId + '/time-records/' + recordId + '/',
        method: 'DELETE',
    })
}

const apiCreateTaskTimeRecord = async (taskId, record) => {
    return await apiCall({
        url: '/api/v1/tasks/' + taskId + '/time-records/',
        method: 'POST',
        data: record,
    })
}

export { apiDeleteTaskTimeRecord, apiCreateTaskTimeRecord }
