import { Button, ButtonGroup, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import { useState } from 'react'
import InputMask from 'react-input-mask'
import { useLanguage } from 'src/stores/useLanguage'

const durationStringToSeconds = (duration) => {
    const parts = duration.split(':')
    return (
        parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 // + parseInt(parts[2])
    )
}

const AddManualTimeForm = (props) => {
    const { handleClose, handleSubmit } = props

    const { t } = useLanguage()

    const [duration, setDuration] = useState('')
    // console.log(durationStringToSeconds(duration))

    const handleKeyDown = (e) => {
        // Enter triggers submit
        if (e.keyCode === 13) {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit(durationStringToSeconds(duration))
            handleClose()
        }
        if (e.keyCode === 27) {
            e.preventDefault()
            e.stopPropagation()
            handleClose()
        }
    }

    return (
        <ButtonGroup size='small'>
            <InputMask
                mask='99:99'
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                maskChar='0'
                onKeyDown={handleKeyDown}
            >
                {(inputProps) => (
                    <TextField
                        {...inputProps}
                        inputProps={{
                            style: {
                                fontSize: '0.8125rem',
                                paddingLeft: '11px',
                                paddingRight: '8px',
                                paddingTop: '9px',
                                paddingBottom: '8px',
                                // padding: '8px',
                            },
                            inputMode: 'numeric',
                        }}
                        InputLabelProps={{ style: { marginLeft: '-0px' } }}
                        autoFocus
                        size='small'
                        margin='none'
                        variant='outlined'
                        label={t('hh:mm')}
                        style={{ width: '54px', paddingTop: '1px' }}
                        data-test='add-time-input'
                    />
                )}
            </InputMask>
            <Button
                variant='contained'
                color='primary'
                // disableElevation
                onClick={() => {
                    handleSubmit(durationStringToSeconds(duration))
                    handleClose()
                }}
                data-test='submit-btn'
            >
                <DoneIcon />
            </Button>
            <Button
                variant='contained'
                color='secondary'
                onClick={handleClose}
                // disableElevation
                data-test='cancel-btn'
            >
                <CloseIcon />
            </Button>
        </ButtonGroup>
    )
}

export default AddManualTimeForm
