import { TableCell, TableRow, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useState } from 'react'
import { StyledTableCell } from 'src/components/Stats/TotalTasksStatsCard'
import { formatDurationFromSeconds } from 'src/utils'

const ProjectStatsRow = (props) => {
    const { project, totalTime, open = false, maxTime } = props
    const [expanded, setExpanded] = useState(open)
    let expandSymbol = null
    if (project.subProjects.length > 0) {
        expandSymbol = expanded ? (
            <ExpandMoreIcon
                fontSize='small'
                style={{ marginBottom: -5, marginLeft: -24 }}
            />
        ) : (
            <ChevronRightIcon
                fontSize='small'
                style={{ marginBottom: -5, marginLeft: -24 }}
            />
        )
    }
    const rowStyle = project.subProjects.length > 0 ? { cursor: 'pointer' } : {}
    const cellStyle = { paddingLeft: 32 + project.depth * 20 }
    if (project.depth === 0) {
        cellStyle.fontWeight = 'bold'
    }
    return (
        <>
            <TableRow
                hover
                onClick={() => setExpanded((state) => !state)}
                style={rowStyle}
            >
                {/* <TableCell>{expandSymbol}</TableCell> */}
                <StyledTableCell
                    bgSize={
                        ((project.time + project.subProjectsTime) * 100) /
                        maxTime
                    }
                    style={cellStyle}
                    width='100%'
                >
                    <Typography>
                        {expandSymbol} {project?.name}
                    </Typography>
                </StyledTableCell>
                {/* <TableCell>
                    {formatDurationFromSeconds(project.time, false)}
                </TableCell>
                <TableCell>
                    {formatDurationFromSeconds(project.subProjectsTime, false)}
                </TableCell> */}
                <TableCell align='center'>
                    <Typography>
                        {formatDurationFromSeconds(
                            project.time + project.subProjectsTime,
                            false
                        )}
                    </Typography>
                </TableCell>
                <TableCell align='center'>
                    <Typography>
                        {Math.round(
                            ((project.time + project.subProjectsTime) /
                                totalTime) *
                                10000
                        ) /
                            100 +
                            '%'}
                    </Typography>
                </TableCell>
            </TableRow>
            {expanded &&
                project.subProjects.map(
                    (project, index) =>
                        project.time + project.subProjectsTime > 0 && (
                            <ProjectStatsRow
                                project={project}
                                totalTime={totalTime}
                                key={index}
                                maxTime={maxTime}
                            />
                        )
                )}
        </>
    )
}

export default ProjectStatsRow
