import { CircularProgress } from '@material-ui/core'
import React from 'react'

const LoadingLayout = () => {
    return (
        <CircularProgress
            style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
            }}
        />
    )
}

export default LoadingLayout
