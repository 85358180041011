import { useContext } from 'react'
import { apiFetchUserSelf } from 'src/api/users'
import {
    LogInUserStoreActionPayload,
    UpdateUserStoreActionPayload,
    UserContext,
    UserStoreActionType,
} from './userStore'

const useUser = () => {
    const { dispatch, state } = useContext(UserContext)

    const logIn = (userData: LogInUserStoreActionPayload) => {
        dispatch({ type: UserStoreActionType.LOG_IN, payload: userData })
    }

    const updateUser = (userData: UpdateUserStoreActionPayload) => {
        dispatch({ type: UserStoreActionType.UPDATE_USER, payload: userData })
    }

    // const triggerReload = () => {
    //     dispatch({ type: TRIGGER_RELOAD })
    // }

    const loadUser = async () => {
        const response = await apiFetchUserSelf()
        if (response.status === 200) {
            const update = {
                language: ['en', 'ru'].includes(response.data.data.language)
                    ? response.data.data.language
                    : 'en',
                email: response.data.data.email,
                emailConfirmedAt: response.data.data.email_confirmed_at,
                confirmationEmailSentAt:
                    response.data.data.confirmation_email_sent_at,
                isMondayFirstDayOfWeek:
                    response.data.data.start_week_with_monday,
                shortDateFormat: response.data.data.short_date_format,
            }
            updateUser(update)
        }
    }

    return {
        logIn,
        updateUser,
        loadUser,
        // triggerReload,
        userState: state,
    }
}

export default useUser
