import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { format, parseISO } from 'date-fns'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import useTasks from 'src/stores/useTasks'
import { formatDurationFromSeconds } from 'src/utils'

const TaskTimeRecordsModal = (props) => {
    const { handleClose, task } = props
    const { deleteTaskTimeRecord, createTaskTimeRecord } = useTasks()
    const { openTimeRecordModal } = useModals()

    const { t, shortDateFormat } = useLanguage()

    const handleDelete = async (recordId) => {
        if (window.confirm(t('alertConfirmTimeEntryDelete'))) {
            await deleteTaskTimeRecord(task._id, recordId)
        }
    }

    const sortedRecords = task.time_records.sort((a, b) =>
        a.start_dt < b.start_dt ? 1 : -1
    )

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth='xs'
            data-test='modal-task-time'
            transitionDuration={0}
        >
            <div
                disableTypography
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    padding: '16px 10px 10px 24px',
                }}
            >
                <Typography
                    variant='h6'
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >
                    {task.text}
                    {/* {t('timeEntriesForTask', { text: task.text })} */}
                </Typography>
                <IconButton
                    onClick={handleClose}
                    size='small'
                    style={{ marginLeft: '15px' }}
                    data-test='close-btn'
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent>
                {sortedRecords.length > 0 ? null : (
                    <Typography>{t('noTimeRecorded')}</Typography>
                )}
                <List>
                    {sortedRecords.map((record) => (
                        <ListItem
                            button
                            key={record._id}
                            dense
                            ContainerProps={{ 'data-test': 'time-record' }}
                        >
                            <ListItemText
                                secondary={format(
                                    parseISO(record.start_dt),
                                    shortDateFormat + ' HH:mm:ss'
                                )}
                                primary={formatDurationFromSeconds(
                                    record.duration
                                )}
                            />

                            <ListItemSecondaryAction>
                                {/* <IconButton
                                    // edge='end'
                                    onClick={() => handleDelete(record._id)}
                                    // color='secondary'
                                    // size='small'
                                    data-test='delete-btn'
                                >
                                    <DeleteIcon />
                                </IconButton> */}
                                <IconButton
                                    edge='end'
                                    // size='small'
                                    onClick={() => handleDelete(record._id)}
                                    color='secondary'
                                    data-test='delete-btn'
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='outlined'
                    data-test='add-time-btn'
                    onClick={() =>
                        openTimeRecordModal(task, {}, async (record) => {
                            createTaskTimeRecord(task._id, record)
                        })
                    }
                >
                    {t('addTime')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TaskTimeRecordsModal
