import { format, parse } from 'date-fns'

const tokenFormat = 'yyyyMMdd'

const tokenToDate = (token) => {
    return parse(token.toString(), tokenFormat, new Date())
}

const dateToToken = (date) => {
    return parseInt(format(date, tokenFormat))
}

const formatDurationFromSeconds = (duration, withSeconds = true) => {
    const normalize = (value) => {
        return value < 10 ? '0' + value : value
    }
    let hours = Math.floor(duration / 3600)
    duration -= hours * 3600
    let minutes = Math.floor(duration / 60)
    let seconds = duration % 60

    if (withSeconds) {
        return (
            normalize(hours) +
            ':' +
            normalize(minutes) +
            ':' +
            normalize(seconds)
        )
    }

    if (seconds > 0) minutes += 1
    if (minutes === 60) {
        minutes = 0
        hours += 1
    }

    return normalize(hours) + ':' + normalize(minutes)
}

const getRunningTimer = (task) => {
    let runningTimer = null
    task.time_records.forEach(
        (timer) => timer.running && (runningTimer = timer)
    )
    return runningTimer
}

const changeFavicon = (href) => {
    var link = document.createElement('link')
    const oldLink = document.querySelector("link[rel='icon']")
    link.type = 'image/x-icon'
    link.rel = 'icon'
    link.href = href
    document.getElementsByTagName('head')[0].removeChild(oldLink)
    document.getElementsByTagName('head')[0].appendChild(link)
}

const loadFromLocalStorage = (name, defaultValue) => {
    const value = localStorage.getItem(name)
    if (value === null) {
        return defaultValue
    }
    return JSON.parse(value)
}

export {
    tokenToDate,
    dateToToken,
    formatDurationFromSeconds,
    getRunningTimer,
    changeFavicon,
    loadFromLocalStorage,
}
