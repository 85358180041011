import {
    Breadcrumbs,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArchiveIcon from '@material-ui/icons/Archive'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import EditIcon from '@material-ui/icons/Edit'
import { Link, useParams } from 'react-router-dom'
import AppBarInput from 'src/components/AppBarInput'
import TasksList from 'src/components/TasksList'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'
import useProjects from 'src/stores/useProjects'

const ProjectTasksListView = () => {
    const { getProjectById, projects } = useProjects()
    const { openEditProjectModal, openCreateProjectModal } = useModals()

    const { projectId } = useParams()

    const currentProject = getProjectById(projectId)

    const { t } = useLanguage()

    const query = {
        project_id: projectId,
    }

    let project = currentProject
    const projectsLine = []
    if (project !== null) {
        projectsLine[0] = project
        while (project.parent_id !== null) {
            project = getProjectById(project.parent_id)
            projectsLine.unshift(project)
        }
    }

    const subProjects = projects.filter(
        (project) => project.parent_id === projectId
    )

    if (currentProject === null) return null

    return (
        <>
            <Grid container spacing={1}>
                <Grid item>
                    <Typography
                        variant='h5'
                        color={
                            currentProject.status === 'active'
                                ? 'primary'
                                : 'textSecondary'
                        }
                        data-test='project-name'
                    >
                        {currentProject.favorite ? (
                            <BookmarkIcon
                                style={{ marginRight: 5, marginBottom: -4 }}
                            />
                        ) : null}
                        {currentProject.status !== 'active' ? (
                            <ArchiveIcon
                                style={{
                                    marginRight: 10,
                                    marginBottom: -4,
                                }}
                            />
                        ) : null}
                        {currentProject.name}
                    </Typography>
                </Grid>
                <Grid item style={{ paddingTop: 7 }}>
                    <IconButton
                        onClick={() => openEditProjectModal(currentProject)}
                        size='small'
                    >
                        <EditIcon fontSize='small' />
                    </IconButton>
                </Grid>
            </Grid>

            <Breadcrumbs style={{ marginBottom: 10 }}>
                <Link
                    to='/app/projects'
                    style={{
                        textDecoration: 'none',
                        fontSize: '14px',
                        color: 'gray',
                    }}
                >
                    {t('projects')}
                </Link>
                {projectsLine.map((project) => (
                    <Link
                        style={{
                            textDecoration: 'none',
                            fontSize: '14px',
                            color: 'gray',
                        }}
                        key={project?._id}
                        to={'/app/projects/' + project?._id + '/tasks'}
                    >
                        {project?.name}
                    </Link>
                ))}
            </Breadcrumbs>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={7} lg={6}>
                    <AppBarInput defaults={{ project_id: projectId }} />
                    <TasksList query={query} listName={project._id} />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <Typography variant='h6' noWrap>
                        {t('subprojects')}{' '}
                        <IconButton
                            size='small'
                            style={{ marginTop: '-5px' }}
                            onClick={() =>
                                openCreateProjectModal({
                                    parent_id: currentProject._id,
                                })
                            }
                            data-test='create-subproject-btn'
                        >
                            <AddIcon />
                        </IconButton>
                    </Typography>

                    <MenuList data-test='subprojects-list'>
                        {subProjects.length == 0 && (
                            <Typography>{t('noSubprojects')}</Typography>
                        )}
                        {subProjects
                            .filter((item) => item.status === 'active')
                            .map((project) => (
                                <Link
                                    to={`/app/projects/${project._id}/tasks`}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#000',
                                    }}
                                    key={project._id}
                                >
                                    <MenuItem>
                                        <ListItemText>
                                            {project.name}
                                        </ListItemText>
                                    </MenuItem>
                                </Link>
                            ))}
                        {subProjects.filter(
                            (item) => item.status === 'archived'
                        ).length > 0 && (
                            <MenuItem disabled disableGutters>
                                <Typography variant='h6' color='textPrimary'>
                                    {t('archived')}
                                </Typography>
                                <ListItemIcon style={{ marginLeft: '10px' }}>
                                    <ArchiveIcon />
                                </ListItemIcon>
                            </MenuItem>
                        )}
                        {subProjects
                            .filter((item) => item.status === 'archived')
                            .map((project) => (
                                <Link
                                    to={
                                        '/app/projects/' +
                                        project._id +
                                        '/tasks'
                                    }
                                    style={{
                                        textDecoration: 'none',
                                        color: '#000',
                                    }}
                                    key={project._id}
                                >
                                    <MenuItem>
                                        <ListItemText>
                                            {project.name}
                                        </ListItemText>
                                    </MenuItem>
                                </Link>
                            ))}
                    </MenuList>
                </Grid>
            </Grid>
        </>
    )
}

export default ProjectTasksListView
