import { PriorityMatchingRules, PriorityParseResult } from "./typing"

const longFirstSort = (a: string, b: string): number => b.length - a.length

    const getMatchKeysString = (obj: Object): string => {
        let keys = Object.keys(obj)
        return keys.sort(longFirstSort).join('|')
    }

const parsePriority = (text: string, rules: PriorityMatchingRules) => {
    var result:PriorityParseResult = { priority: null, match: '' }
        let matchString = `(?<priority>${getMatchKeysString(rules)})$`
        let found = text.match(new RegExp(matchString))
        if (found?.groups !== undefined) {
            let priority = rules[found.groups.priority]
                result = {
                    priority,
                    match: text.slice(found.index),
                }
        }
        return result

}

export default parsePriority