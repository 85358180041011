import { Button, Grid, TextareaAutosize } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useState } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'

const TaskNotes = (props) => {
    const { t } = useLanguage()
    const { openFormattingHelpModal } = useModals()

    const { task, saveNotes, onCancel } = props
    const [notes, setNotes] = useState(task.notes)

    const handleSave = () => {
        if (task.notes !== notes) {
            saveNotes(notes.trim())
        } else {
            onCancel()
        }
    }

    const handleCancel = () => {
        if (task.notes !== notes) {
            if (window.confirm(t('confirmNotSavingNotes'))) {
                onCancel()
            }
        } else {
            onCancel()
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && e.ctrlKey) {
            handleSave()
        }

        if (e.keyCode === 27) {
            handleCancel()
        }
    }

    const handleClickAway = (e) => {
        let popup = document.querySelector('.MuiDialog-container')
        // console.log(popup)
        try {
            if (popup && popup.contains(e.target)) return
        } catch {}
        handleSave()
    }

    return (
        <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent='onMouseDown'
            touchEvent='onTouchStart'
        >
            <Grid container spacing={1} data-test='editor'>
                <Grid item xs={12}>
                    <div
                        style={{
                            backgroundColor: 'white',
                            width: '100%',
                            border: '1px solid #DDD',
                            borderRadius: '5px',
                            padding: '10px 10px',
                        }}
                    >
                        <TextareaAutosize
                            // ref={textareaRef}
                            autoFocus
                            data-test='editor-textarea'
                            value={notes}
                            onKeyDown={handleKeyDown}
                            style={{
                                border: 'none',
                                outline: 'none',
                                resize: 'none',
                                width: '100%',
                                fontFamily:
                                    'Roboto, Helvetica, Arial, sans-serif',
                                fontSize: '14px',
                            }}
                            onFocus={(e) => {
                                e.target.setSelectionRange(
                                    e.target.value.length,
                                    e.target.value.length
                                )
                            }}
                            placeholder={t('notesEditorPlaceholder')}
                            onChange={(e) => setNotes(e.target.value)}
                            minRows={5}
                            // maxRows={30}
                            // onBlur={handleSave}
                        />
                    </div>
                </Grid>
                {/* <Grid item xs={12}> */}
                <Grid item container xs={12} justifyContent='space-between'>
                    <Grid item>
                        <Button
                            color='primary'
                            variant='contained'
                            data-test='save-btn'
                            size='small'
                            onClick={(e) => {
                                e.stopPropagation()
                                handleSave()
                            }}
                        >
                            {t('save')}
                        </Button>
                        <Button
                            color='disabled'
                            variant='text'
                            data-test='cancel-btn'
                            size='small'
                            style={{ marginLeft: '10px' }}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleCancel()
                            }}
                        >
                            {t('cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                openFormattingHelpModal()
                            }}
                        >
                            {t('formattingHelp')}
                        </Button>
                    </Grid>
                </Grid>
                {/* </Grid> */}
            </Grid>
        </ClickAwayListener>
    )
}

export default TaskNotes
