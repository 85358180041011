import DateFnsUtils from '@date-io/date-fns'
import {
    Button,
    Dialog,
    DialogActions,
    Grid,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useLanguage } from 'src/stores/useLanguage'
import useUser from 'src/stores/useUser'

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline',
    },
})

const SelectDateModal = (props) => {
    const { value, handleClose, handleSave } = props
    const { userState } = useUser()

    const { t, getDateLocale } = useLanguage()

    const classes = useStyles()

    const locale = getDateLocale()

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            // fullWidth
            maxWidth='xs'
            classes={isSmall ? { scrollPaper: classes.scrollPaper } : {}}
            data-test='modal-choose-date'
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <DatePicker
                    clearable
                    okLabel=''
                    cancelLabel=''
                    variant='static'
                    autoOk
                    format={userState.shortDateFormat}
                    value={value}
                    onChange={(value) => {
                        handleSave(value)
                        handleClose()
                    }}
                />
            </MuiPickersUtilsProvider>
            <DialogActions>
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <Button
                            onClick={() => {
                                handleSave(null)
                                handleClose()
                            }}
                            color='primary'
                            data-test='no-date-btn'
                        >
                            {t('noDate')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => {
                                handleSave(new Date())
                                handleClose()
                            }}
                            color='primary'
                            data-test='today-btn'
                        >
                            {t('today')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default SelectDateModal
