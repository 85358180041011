import { apiCall } from './base'

const BASE_URL = '/api/v1/projects/'

const apiFetchProjects = async () => {
    return await apiCall({
        url: BASE_URL,
        method: 'GET',
    })
}

const apiUpdateProject = async (projectId, changes) => {
    return await apiCall({
        url: BASE_URL + projectId + '/',
        method: 'PUT',
        data: changes,
    })
}

const apiCreateProject = async (project) => {
    return await apiCall({
        url: BASE_URL,
        method: 'POST',
        data: project,
    })
}

const apiDeleteProject = async (projectId) => {
    return await apiCall({
        url: BASE_URL + projectId + '/',
        method: 'DELETE',
    })
}

export {
    apiFetchProjects,
    apiUpdateProject,
    apiCreateProject,
    apiDeleteProject,
}
