import { addDays } from 'date-fns'
import { useContext, useEffect } from 'react'
import { apiFetchRunningTask } from 'src/api/timer'
import { apiFetchUserSelf } from 'src/api/users'
import { dateToToken } from 'src/utils'
import { TasksContext, TasksStoreActionType } from './tasksStore'
import useLogOut from './useLogOut'
import useProjects from './useProjects'
import useTasks from './useTasks'
import useUser from './useUser'

const Preloader = () => {
    const { fetchTasks } = useTasks()
    const { fetchProjects } = useProjects()
    const { userState, logIn } = useUser()
    const { logOut } = useLogOut()
    const { dispatch: tasksDispatch, state: tasksState } =
        useContext(TasksContext)

    useEffect(() => {
        const fetchUserSelf = async () => {
            const response = await apiFetchUserSelf()
            if (response.status === 200) {
                logIn(response.data.data)
            } else {
                logOut()
            }
        }
        fetchUserSelf()

        if (!userState.isLoggedIn) return

        const fetchRunningTask = async () => {
            const response = await apiFetchRunningTask()
            if (response.status === 200 && response.data.data) {
                tasksDispatch({
                    type: TasksStoreActionType.SET_RUNNING_TASK,
                    payload: [response.data.data],
                })
            }
        }

        Promise.all([
            fetchRunningTask(),
            fetchProjects(),
            fetchTasks({
                date_to: dateToToken(addDays(new Date(), 1)),
                status: 'active',
                // limit: 100,
            }),
            fetchTasks({ project_id: -1, status: 'active' }),
        ])
    }, [
        userState.isLoggedIn,
        tasksState.todayToken,
        userState.emailConfirmedAt,
    ])

    // Automatically update state on date change
    useEffect(() => {
        const updateTodayToken = () => {
            if (tasksState.todayToken !== dateToToken(new Date())) {
                console.log('Setting today token')
                tasksDispatch({ type: TasksStoreActionType.SET_TODAY_TOKEN })
            }
        }
        const interval = setInterval(updateTodayToken, 2000)
        return () => clearInterval(interval)
    }, [tasksState.todayToken])

    return null
}

export default Preloader
