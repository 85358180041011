import { Button, Grid, Menu, MenuItem } from '@material-ui/core'
import { useEffect, useState } from 'react'
import BaseWidget from 'src/components/Widgets/BaseWidget'
import { useLanguage } from 'src/stores/useLanguage'
import useWidgets from 'src/stores/useWidgets'
import type { WidgetInCreate, WidgetInUpdate, WidgetType } from 'src/typing'

interface WidgetsPanelProps {
    group: string
}

const widgetTypes: WidgetType[] = [
    'notes',
    'time_by_tasks',
    'time_by_projects',
    'general_stats',
    // 'latest_time_entries'
]

const WidgetsPanel = (props: WidgetsPanelProps) => {
    const { group = 'today_widgets' } = props
    const { fetchWidgetsGroup, getWidgetsGroup, createWidget, updateWidget } =
        useWidgets()
    const { t } = useLanguage()
    const [createWidgetMenuEl, setCreateWidgetMenuEl] =
        useState<null | HTMLElement>(null)

    useEffect(() => {
        fetchWidgetsGroup(group)
    }, [group])

    const widgets = getWidgetsGroup(group).sort((a, b) => a.order - b.order)

    var maxOrder = -1
    if (widgets.length > 0) {
        maxOrder = widgets[widgets.length - 1].order
    }

    const handleCreateWidget = (type: WidgetType) => {
        let widget: WidgetInCreate = {
            title: t(type),
            type,
            group,
            order: maxOrder + 1,
            content: null,
            time_preset: type === 'notes' ? null : 'today',
        }
        createWidget(widget)
        setCreateWidgetMenuEl(null)
    }

    return (
        <Grid container justifyContent='flex-end'>
            <Grid item style={{ marginBottom: 12 }}>
                <Button
                    size='small'
                    onClick={(e) => setCreateWidgetMenuEl(e.currentTarget)}
                >
                    {t('addWidget')}
                </Button>
                <Menu
                    open={Boolean(createWidgetMenuEl)}
                    anchorEl={createWidgetMenuEl}
                    onClose={() => setCreateWidgetMenuEl(null)}
                    variant='menu'
                    // Positioning of menu from here:
                    // https://stackoverflow.com/questions/48157863/how-to-make-a-dropdown-menu-open-below-the-appbar-using-material-ui
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                >
                    {widgetTypes.map((type) => (
                        <MenuItem
                            dense
                            key={type}
                            onClick={() => {
                                handleCreateWidget(type)
                            }}
                        >
                            {t(type)}
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
            {widgets.map((widget, index) => (
                <Grid
                    item
                    xs={12}
                    style={{ marginBottom: 12 }}
                    key={widget._id}
                >
                    <BaseWidget
                        {...widget}
                        position={index}
                        widgetsCount={widgets.length}
                        onChange={(changes: WidgetInUpdate) => {
                            updateWidget(widget._id, changes)
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default WidgetsPanel
