import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { apiStartTimer, apiStopTimer } from 'src/api/timer'
import useStableNavigate from 'src/hooks/useStableNavigate'
import { useLanguage } from 'src/stores/useLanguage'
import { changeFavicon } from 'src/utils'
import { TasksContext, TasksStoreActionType } from './tasksStore'

const useTimer = () => {
    const { state: tasksState, dispatch: tasksDispatch } =
        useContext(TasksContext)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useStableNavigate()
    const { t } = useLanguage()

    const snackbarDefaultStyle = { cursor: 'pointer' }

    const startTimer = async (taskId: string) => {
        const response = await apiStartTimer(taskId)
        if (response.status === 201) {
            tasksDispatch({
                type: TasksStoreActionType.SET_RUNNING_TASK,
                payload: response.data.data,
            })
            enqueueSnackbar(t('nftTimerStarted'), {
                onClick: () => navigate('/app/tasks/' + taskId + '/notes'),
                style: snackbarDefaultStyle,
            })
        }
    }

    const stopTimer = async (taskId: string) => {
        const response = await apiStopTimer()
        if (response.status === 200) {
            tasksDispatch({
                type: TasksStoreActionType.CLEAR_RUNNING_TASK,
                payload: response.data.data,
            })
            enqueueSnackbar(t('nftTimerStopped'), {
                onClick: () => navigate('/app/tasks/' + taskId + '/notes'),
                style: snackbarDefaultStyle,
            })
            changeFavicon('/favicon.ico')
        }
    }

    return { runningTask: tasksState.runningTask, startTimer, stopTimer }
}

export default useTimer
