import {
    Button,
    ClickAwayListener,
    Grid,
    TextareaAutosize,
} from '@material-ui/core'
import type { KeyboardEvent, MouseEvent } from 'react'
import { useState } from 'react'
import { useLanguage } from 'src/stores/useLanguage'
import useModals from 'src/stores/useModals'

interface TextEditorProps {
    content: string
    onSave: (content: string) => void
    onCancel: () => void
    maxRows: number | undefined
    minRows: number | undefined
}

const TextEditor = (props: TextEditorProps) => {
    const { content: contentProp, onSave, onCancel, maxRows, minRows } = props

    const { t } = useLanguage()
    const { openFormattingHelpModal } = useModals()

    const [internalContent, setInternalContent] = useState(contentProp)

    const handleSave = () => {
        if (contentProp !== internalContent) {
            onSave(internalContent.trim())
        } else {
            onCancel()
        }
    }

    const handleCancel = () => {
        if (contentProp !== internalContent) {
            if (window.confirm(t('confirmNotSavingNotes'))) {
                onCancel()
            }
        } else {
            onCancel()
        }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.ctrlKey) {
            handleSave()
        }

        if (e.keyCode === 27) {
            handleCancel()
        }
    }

    const handleClickAway = (e: MouseEvent<Document>) => {
        let popup = document.querySelector('.MuiDialog-container')
        try {
            if (popup && popup.contains(e.target as Node)) return
        } catch {}
        handleSave()
    }

    return (
        <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent='onMouseDown'
            touchEvent='onTouchStart'
        >
            <Grid container spacing={1} data-test='editor'>
                <Grid item xs={12}>
                    <div
                        style={{
                            backgroundColor: 'white',
                            width: '100%',
                            border: '1px solid #DDD',
                            borderRadius: '5px',
                            padding: '10px 10px',
                        }}
                    >
                        <TextareaAutosize
                            autoFocus
                            maxRows={maxRows}
                            data-test='editor-textarea'
                            value={internalContent}
                            onKeyDown={handleKeyDown}
                            style={{
                                border: 'none',
                                outline: 'none',
                                resize: 'none',
                                width: '100%',
                                fontFamily:
                                    'Roboto, Helvetica, Arial, sans-serif',
                                fontSize: '14px',
                            }}
                            onFocus={(e) => {
                                e.target.setSelectionRange(
                                    e.target.value.length,
                                    e.target.value.length
                                )
                            }}
                            placeholder={t('notesEditorPlaceholder')}
                            onChange={(e) => setInternalContent(e.target.value)}
                            minRows={minRows}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    onClick={handleSave}
                    justifyContent='space-between'
                >
                    <Grid item>
                        <Button
                            color='primary'
                            variant='contained'
                            data-test='save-btn'
                            size='small'
                            onClick={(e) => {
                                e.stopPropagation()
                                handleSave()
                            }}
                        >
                            {t('save')}
                        </Button>
                        <Button
                            variant='text'
                            data-test='cancel-btn'
                            size='small'
                            style={{ marginLeft: '10px' }}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleCancel()
                            }}
                        >
                            {t('cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                openFormattingHelpModal()
                            }}
                        >
                            {t('formattingHelp')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </ClickAwayListener>
    )
}

export default TextEditor
