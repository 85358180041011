import { Grid } from '@material-ui/core'
import { endOfDay, startOfDay } from 'date-fns'
import { useEffect, useState } from 'react'
import PeriodPicker from 'src/components/Common/PeriodPicker'
import TimeStatsByProject from 'src/components/Common/TimeStatsByProject'
import useTasks from 'src/stores/useTasks'

// import { getProjectsStats } from 'src/components/TimePieChart'

const ProjectsTimeStatsView = () => {
    const [tasks, setTasks] = useState([])
    const [dateFrom, setDateFrom] = useState(startOfDay(new Date()))
    const [dateTo, setDateTo] = useState(endOfDay(new Date()))
    const { fetchTasks } = useTasks()

    const [isLoading, setIsLoading] = useState(false)

    // const timeFrom = startOfDay(dateFrom)
    // const timeTo = endOfDay(dateTo)

    useEffect(() => {
        const fetchTasksRequest = async () => {
            setIsLoading(true)
            const query = {
                time_from: dateFrom.toISOString(),
                time_to: dateTo.toISOString(),
            }
            const response = await fetchTasks(query)
            if (response.status === 200) {
                setTasks(response.data.data)
                setIsLoading(false)
            }
        }
        fetchTasksRequest()
    }, [dateTo, dateFrom])

    return (
        <Grid container spacing={1} style={{ maxWidth: '870px' }}>
            <Grid item xs={12}>
                <PeriodPicker
                    onChangeDateFrom={(date) => setDateFrom(startOfDay(date))}
                    onChangeDateTo={(date) => setDateTo(endOfDay(date))}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                />
            </Grid>

            <Grid item xs={12}>
                <TimeStatsByProject
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    tasks={tasks}
                />
            </Grid>
        </Grid>
    )
}

export default ProjectsTimeStatsView
