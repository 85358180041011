import {
    Drawer,
    Hidden,
    makeStyles,
    Toolbar,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import GlobalTimer from 'src/components/GlobalTimer'
import BottomMenu from './BottomMenu'
import MainMenu from './MainMenu'
import './styles.css'
import TopBar from './TopBar'

const MainLayout = () => {
    const theme = useTheme()
    const narrowMenu = 60,
        wideMenu = 240
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const [isNarrow, setIsNarrow] = useState(isSmallScreen)
    const menuWidth = isNarrow ? narrowMenu : wideMenu
    const drawerPosition = isSmallScreen ? 'absolute' : 'block'

    useEffect(() => {
        setIsNarrow(isSmallScreen)
    }, [isSmallScreen])

    let paddingSize = 3
    let leftPadding = theme.spacing(paddingSize)

    if (isSmallScreen) {
        paddingSize = 2
        leftPadding = theme.spacing(paddingSize) + 60
    }
    if (isXsScreen) {
        paddingSize = 2
        leftPadding = theme.spacing(paddingSize)
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        content: {
            padding: theme.spacing(paddingSize),
            paddingTop: theme.spacing(2),
            paddingLeft: leftPadding,
            flexGrow: 1,
        },
        drawer: {
            position: drawerPosition,
            width: menuWidth,
            flexShrink: 0,
            transition: '0.2s',
        },
        drawerPaper: {
            width: menuWidth,
            transition: '0.2s',
        },
        drawerContainer: {
            overflow: 'auto',
        },
    }))
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <TopBar isXS={useMediaQuery(theme.breakpoints.down('xs'))} />
            <Hidden xsDown>
                <Drawer
                    id='main-menu'
                    variant='permanent'
                    anchor='left'
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onMouseOver={() => {
                        if (isSmallScreen) {
                            setIsNarrow(false)
                        }
                    }}
                    onMouseLeave={() => {
                        if (isSmallScreen) {
                            setIsNarrow(true)
                        }
                    }}
                    PaperProps={{
                        'data-test': 'main-menu-base',
                        className: 'main-menu-base',
                    }}
                    onClick={() => setIsNarrow(false)}
                    style={{ zIndex: '197', paddingTop: '100px' }}
                >
                    <Toolbar style={{ height: '56px', minHeight: '56px' }} />
                    <MainMenu isNarrow={isNarrow} />
                </Drawer>
            </Hidden>
            <div className={classes.content}>
                <Toolbar />
                <Outlet />
                <Toolbar />
                <Hidden smUp>
                    <Toolbar />
                </Hidden>
            </div>
            <GlobalTimer />
            <Hidden smUp>
                <BottomMenu />
            </Hidden>
        </div>
    )
}

export default MainLayout
